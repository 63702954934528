
<template>
  <div class="article-page" :class="{ 'loading': isLoading }">
    <v-loading :is-loading="isLoading" :circle-position="'top'"></v-loading>
    <custom-header class="title" :title="header"></custom-header>
    <p class="article-page__date">{{ date }}</p>
    <div class="article-page__container">
      <article>
        <p>{{ text }}</p>
      </article>
    </div>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader.vue';
import SideMenu from '../components/SideMenu.vue';
import CustomHeader from '../components/CustomHeader.vue';
import AppBreadcrumb from '../components/AppBreadcrumb.vue';
import VLoading from '../components/v-loading.vue';

import { serverRequest } from '../api/request';

import { mapWritableState } from 'pinia'
import { useBreadcrumbs } from '../stores/breadcrumbs';

export default {
  name: 'MeetingView',
  components: {
    AppHeader,
    SideMenu,
    CustomHeader,
    AppBreadcrumb,
    VLoading
  },

  props: {
    id: {
      type: String,
      default() {
        return '0'
      }
    },
  },

  data() {
    return {
      isLoading: false,

      header: '',
      date: '',
      text: '',
    }
  },

  created() {
    this.$watch(
      () => this.id,
      (toParams, previousParams) => {
        this.getInfo()
      }
    )
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'analitics',
        name: 'Аналитика'
      },
      {
        path: 'questions',
        name: 'Встреча'
      },
    ]
    this.getInfo()
  },

  computed: {
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),
  },

  methods: {
    async getInfo() {
      this.isLoading = true

      let formData = {
        id: this.id,
      }

      let result = await serverRequest('POST', 'json', 'get_meeting_info', formData)

      if (result != 'error' && result != 'unathorized') {
        this.header = result.header
        this.date = result.date
        this.text = result.text
      }
      else {
        //alert('Ошибка запроса')
      }

      this.isLoading = false
    }
  }
}
</script>

<style lang="scss"></style>