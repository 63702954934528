<template>
    <section class="question-item" :class="{ 'show': isShow }">
        <div class="question-item__header" @click="isShow = !isShow">
            <p class="question-item__title">{{ content.question }}</p>
            <svg class="question-item__icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M3 12H21" stroke="#035AA6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16 7L21 12L16 17" stroke="#035AA6" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </div>
        <Transition>
            <p v-show="isShow" class="question-item__text">
                {{ content.answer }}
            </p>
        </Transition>
    </section>
</template>

<script>
export default {
    name: 'QuestionItem',

    props: {
        content: {
            type: Object,
            default() {
                return {
                    question: 'Вопрос',
                    answer: 'Ответ',
                }
            }
        },
    },

    data() {
        return {
            isShow: false
        }
    },

    computed: {
        isImage() {
            if (this.content.image != '') {
                return true
            }
            else {
                return false
            }
        }
    }
}
</script>

<style lang="scss">
.question-item {
    padding-bottom: 12px;
    border-bottom: 1px solid $bw3;

    width: 100%;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        cursor: pointer;
    }

    &__title {
        @extend %text-medium;
        font-size: 20px;
        line-height: 24px
    }

    &__icon {
        transition: transform .5s;
    }

    &__text {
        margin-top: 24px;

        @extend %text-regular;
        color: $bw0;
        padding-bottom: 12px;
    }

    &.show {
        .question-item__icon {
            transform: rotate(90deg);
        }
    }
}
</style>