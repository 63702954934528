<template>
  <div class="article-page" :class="{ 'loading': isLoading }">
    <v-loading :is-loading="isLoading" :circle-position="'top'"></v-loading>
    <custom-header class="title" :title="header"></custom-header>
    <p class="article-page__date">{{ date }}</p>
    <a v-if="isFile" :href="file" target="_blank" class="article-page__download" download>
      <span>Скачать pdf</span>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 21C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14L19 8V19C19 20.1046 18.1046 21 17 21H7Z"
          stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M13 3V9H19" stroke="black" stroke-width="1.5" stroke-linejoin="round" />
      </svg>
    </a>
    <div class="article-page__container">
      <article>
        <img class="image" v-if="isImage" :src="image" alt="фотография новости">
        <div v-html="text">
        </div>
      </article>
      <div class="other-articles-block" v-if="otherNews.length > 0">
        <custom-header :title="'Другие материалы'"></custom-header>
        <ul>
          <li v-for="news in otherNews">
            <router-link class="link" :to="{ name: 'article', params: { id: news.id } }">{{ news.header
              }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CustomHeader from '../components/CustomHeader.vue';
import VLoading from '../components/v-loading.vue';

import { serverRequest } from '../api/request';

import { mapWritableState } from 'pinia'
import { useBreadcrumbs } from '../stores/breadcrumbs';

export default {
  name: 'ArticleView',
  components: {
    CustomHeader,
    VLoading
  },

  props: {
    id: {
      type: String,
      default() {
        return '0'
      }
    },
  },

  data() {
    return {
      isLoading: false,

      header: '',
      file: '',
      date: '',
      image: '',
      text: '',
      otherNews: []
    }
  },

  created() {
    this.$watch(
      () => this.id,
      (toParams, previousParams) => {
        this.getSingleNewsInfo()
      }
    )
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'knowledge',
        name: 'База знаний'
      },
      {
        path: 'questions',
        name: 'Статья'
      },
    ]
    this.getSingleNewsInfo()
  },

  computed: {
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),

    isFile() {
      if (this.file == null || this.file == '') {
        return false
      }
      else {
        return true
      }
    },
    isImage() {
      if (this.image == null || this.image == '') {
        return false
      }
      else {
        return true
      }
    },
  },

  methods: {
    async getSingleNewsInfo() {
      this.isLoading = true

      let formData = {
        id: this.id,
      }

      let result = await serverRequest('POST', 'json', 'get_news', formData)

      if (result != 'error' && result != 'unathorized') {
        this.header = result.header
        this.date = result.date
        this.file = result.download_link
        this.image = result.image
        this.text = result.text
        this.otherNews = result.other_news
      }
      else {
        //alert('Ошибка запроса')
      }

      this.isLoading = false
    }
  }


}
</script>

<style lang="scss">
.article-page {
  @extend %white-bg-block;
  width: 100%;

  position: relative;

  &.loading {
    pointer-events: none;
  }

  .title {
    max-width: 700px;
  }

  &__date {
    margin-top: 12px;

    @extend %text-regular;
    color: $bw1;
  }

  &__download {
    margin-top: 24px;

    display: flex;
    align-items: center;
    column-gap: 4px;

    padding: 6px 12px;

    border-radius: 6px;

    background-color: $bw4;

    width: fit-content;

    span {
      @extend %text-regular;
      color: $bw0;
    }
  }

  &__container {
    margin-top: 32px;

    display: flex;
    column-gap: 32px;
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;

    article {
      max-width: 700px;


      .image {
        width: 100%;
        height: auto;
        border-radius: 4px;
        max-height: 320px;
        object-fit: cover;
      }


      >div {
        margin-top: 24px;

        @extend %text-regular;
        color: $bw0;
        text-align: justify;

        img {
          width: 100%;
          height: auto;
          border-radius: 4px;
          object-fit: cover;
        }

        strong,
        b {
          font-weight: 700;
        }

        i,
        em {
          font-style: italic;
        }

        p {
          margin-top: 24px;

          @extend %text-regular;
          color: $bw0;
          text-align: justify;
        }
      }


    }
  }

  .other-articles-block {
    max-width: 48%;

    width: 100%;

    padding: 24px;

    border-radius: 6px;

    background-color: $bw4;

    min-width: 320px;

    ul {
      margin-top: 32px;

      li {
        &:not(:first-of-type) {
          margin-top: 12px;
        }

        .link {
          @extend %text-medium;
          font-size: 20px;
          line-height: 24px;
          color: $bw1;

          &.active {
            color: var(--primary);
            pointer-events: none;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 1300px) {
  .article-page {
    &__container {
      flex-direction: column;
      row-gap: 48px;
    }

    .other-articles-block {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 800px) {
  .article-page {
    &__container {
      article {
        max-width: 100%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          max-height: 470px;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}
</style>