import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useMarketingRequests = defineStore('marketingRequests', () => {
  const id = ref('')
  const name = ref('')

  return {
    id,
    name
  }
})
