<template>
    <div class="info-tooltip">
        <svg class="info-tooltip__icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                stroke="#B6BEC9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M13.5 8C13.5 8.82843 12.8284 9.5 12 9.5C11.1716 9.5 10.5 8.82843 10.5 8C10.5 7.17157 11.1716 6.5 12 6.5C12.8284 6.5 13.5 7.17157 13.5 8Z"
                fill="#B6BEC9" />
            <path d="M12 12V16" stroke="#B6BEC9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <p class="info-tooltip__text">{{ text }}</p>
    </div>
</template>

<script>
export default {
    name: 'CustomHeader',

    props: {
        text: {
            type: String,
            default() {
                return "Подсказка"
            }
        }
    },
}
</script>

<style lang="scss">
.info-tooltip {
    position: relative;
    height: 24px;

    &__icon {
        cursor: pointer;
    }

    &__text {
        position: absolute;

        width: 365px;
        max-width: 365px;
        height: auto;

        white-space: wrap;

        top: 50%;
        transform: translateY(-50%);

        left: 32px;

        @extend %text-small;
        color: var(--bw0);

        padding: 12px;

        border-radius: 6px;
        border: 1px solid var(--bw3);

        background-color: var(--bw6);

        visibility: collapse;
        opacity: 0;

        transition: visibility .3s, opacity .3s;

        z-index: 2;
    }

    &:hover {
        .info-tooltip__text {
            visibility: visible;
            opacity: 1;
        }
    }
}

@media screen and (max-width: 600px) {
    .info-tooltip {
        display: none;
    }
}
</style>