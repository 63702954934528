import { defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'

export const useDeliveryTypes = defineStore('deliveryTypes', () => {
  const deliveryTypes = ref([])

  deliveryTypes.value = [
    {
      code: 0,
      text: 'Не определён'
    },
    {
      code: 1,
      text: 'Самовывоз'
    },
    {
      code: 2,
      text: 'Доставка по Москве'
    },
    {
      code: 3,
      text: 'Доставка в другой регион'
    }
  ]

  const getDeliveryText = (code) => {
    if (typeof code !== 'string') {
      if (code == null) {
        return 'Не определён'
      } else {
        let elem = deliveryTypes.value.find((el) => el.code === code)
        return elem.text
      }
    } else {
      return 'Не определён'
    }
  }

  return {
    deliveryTypes,
    getDeliveryText
  }
})
