<template>
  <div class="question-page">
    <custom-header class="title" :title="'вопросы и предложения'"></custom-header>
    <div class="question-page__wrapper" :class="{ 'loading': isLoading }">
      <v-loading :is-loading="isLoading" :color="'white'"></v-loading>
      <button class="create-question" v-if="!isNewQuestionShow" @click="isNewQuestionShow = true">
        <span>Написать</span>
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.5 12H5.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12.5 5V19" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
      <template v-else>
        <div class="input-container" :class="{ 'error': v$.topic.$errors.length }">
          <label for="complaints-number">Тема</label>
          <input id="complaints-number" type="text" v-model="topic">
        </div>
        <div class="question-page__container">
          <div class="text-area" :class="{ 'error': v$.text.$errors.length }">
            <label for="comment">Содержание обращения</label>
            <textarea id="comment" type="text" placeholder="Введите текст" v-model="text"></textarea>
          </div>
          <file-line-upload class="file-upload" @fileUploaded="onFileUploaded" @file-deleted="onFileDeleted"
            :formats="'.doc,.docx,.png,.jpg,.jpeg,.pdf'" ref="fileUpload"></file-line-upload>
        </div>
        <div class="buttons">
          <button @click="createRequest">Отправить</button>
          <button class="gray" @click="isNewQuestionShow = false">Отменить</button>
        </div>
      </template>
      <section class="appeal-history">
        <h3>История обращений</h3>
        <div class="appeal-history__container">
          <div class="appeal-item" v-for="appeal in history">
            <div class="appeal-item__top">
              <div>
                <p class="appeal-item__label">Тема обращения</p>
                <p class="appeal-item__value">{{ appeal.title }}</p>
              </div>
              <div>
                <p class="appeal-item__label">Дата загрузки</p>
                <p class="appeal-item__date">{{ appeal.date }}</p>
              </div>
              <div>
                <p class="appeal-item__label">Статус</p>
                <status-block :text="getStatusText(appeal.status)" :code="appeal.status"
                  :type="'questions'"></status-block>
              </div>
              <button class="appeal-item__button" :class="{ 'disabled': appeal.status == 0 || appeal.status == 2 }"
                @click="orderCall(appeal.id)">
                <template v-if="appeal.status == 2">Звонок руководителя заказан</template>
                <template v-else>Заказать звонок руководителя</template>
              </button>
            </div>
            <div v-if="appeal.response[0] != null" class="appeal-item__answer">
              <button @click="showAnswer">
                <span>Ответ менеджера</span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 15L12 10L7 15" stroke="#424F60" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </button>
              <p v-for="response in appeal.response" class="appeal-item__answer-text">
                {{ response }}
              </p>
            </div>
          </div>
        </div>
        <v-pagination class="catalog-page__pagination" v-show="isPagination" :totalPages="getTotalPages"
          :currentPage="currentPage" @page-change="pageChange"></v-pagination>
      </section>
    </div>
  </div>
</template>

<script>
import CustomHeader from '../components/CustomHeader.vue';
import AlertBlock from '../components/AlertBlock.vue';
import vSwitch from '../components/v-switch.vue';
import FileLineUpload from '../components/FileLineUpload.vue';
import StatusBlock from '../components/StatusBlock.vue';
import vLoading from '../components/v-loading.vue';
import vPagination from '../components/v-pagination.vue';

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import { mapWritableState } from 'pinia'
import { useBreadcrumbs } from '../stores/breadcrumbs';

import { serverRequest } from '../api/request';

export default {
  name: 'QuestionsView',
  components: {
    CustomHeader,
    AlertBlock,
    vSwitch,
    FileLineUpload,
    StatusBlock,
    vLoading,
    vPagination
  },

  data() {
    return {
      v$: useVuelidate(),

      currentPage: 1,
      requestOnPage: 10,

      allItems: 0,

      isLoading: false,
      isNewQuestionShow: false,
      file: null,
      history: [],
      topic: '',
      text: ''
    }
  },

  validations() {
    return {
      text: { required },
      topic: { required },
    }
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'questions',
        name: 'Вопросы и предложения'
      },
    ]
    this.getData()
  },

  computed: {
    isPagination() {
      if (this.allItems > this.requestOnPage) {
        return true
      }
      else {
        return false
      }
    },

    getTotalPages() {
      if (this.isPagination) {
        return Math.ceil(this.allItems / this.requestOnPage)
      }
      else {
        return 1
      }
    }
  },

  methods: {
    showAnswer(event) {
      let item = event.target.closest(".appeal-item");
      if (item.classList.contains("show")) {
        item.classList.remove("show")
      }
      else {
        item.classList.add("show")
      }
    },

    getStatusText(status) {
      switch (status) {
        case 0:
          return 'На рассмотрении'
        case 1:
          return 'Получен ответ'
        case 2:
          return 'Заказан звонок'
        default:
          return 'Статус'
      }
    },

    onFileUploaded(file) {
      this.file = file
    },

    onFileDeleted() {
      this.file = null
    },

    async orderCall(id) {
      this.isLoading = true;

      let formData = {
        id: id,
      }

      let result = await serverRequest('POST', 'json', 'marketing/order_call', formData)

      if (result != 'error' && result != 'unathorized') {
        this.getData()
      }
      else {
        //alert('Ошибка запроса')
      }

      this.isLoading = false;
    },

    pageChange(page) {
      this.currentPage = page

      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.getData()
    },

    async getData() {
      this.isLoading = true;

      let formData = {
        type: 2,
        amount: this.requestOnPage,
        start_pos: this.requestOnPage * (this.currentPage - 1)
      }

      let result = await serverRequest('POST', 'json', 'marketing/get_requests', formData)

      if (result != 'error' && result != 'unathorized') {
        this.allItems = result.all_count
        this.history = result.requests
      }
      else {
        //alert('Ошибка запроса')
      }

      this.isLoading = false;
    },

    async createRequest() {
      this.v$.$validate();

      if (!this.v$.$error) {
        this.isLoading = true

        let formData = new FormData();

        formData.append('type', 2)
        formData.append('title', this.topic)
        formData.append('description', this.text)
        formData.append('file', this.file)

        for (let [name, value] of formData) {
          console.log(`${name} = ${value}`);
        }

        let result = await serverRequest('POST', 'formData', 'marketing/requests', formData)

        if (result != 'error' && result != 'unathorized') {
          this.topic = ''
          this.text = ''
          this.file = null
          this.$refs.fileUpload.files = []
          this.isNewQuestionShow = false
          this.v$.$reset()
          this.history = result.requests
        }
        else {
          //alert('Ошибка запроса')
        }

        this.isLoading = false

        this.getData()
      }
    }
  }


}
</script>

<style lang="scss">
.question-page {
  @extend %white-bg-block;

  width: 100%;

  &__container {
    max-width: 800px;
  }

  &__wrapper {
    position: relative;

    &.loading {
      pointer-events: none;
    }
  }

  .create-question {
    margin-top: 32px;

    @extend %button;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;

    span {
      @extend %text-regular;
    }
  }

  .input-container {
    margin-top: 32px;

    max-width: 380px;

    @extend %input-container;

    label {
      white-space: nowrap;
    }
  }

  .text-area {
    margin-top: 24px;

    @extend %text-area;
  }

  .file-upload {
    margin-top: 12px;
  }

  .buttons {
    margin-top: 32px;

    display: flex;
    column-gap: 24px;

    button {
      @extend %button;
      max-width: 200px;

      &.gray {
        background-color: $bw4;
        color: $bw0;
      }
    }
  }

  .appeal-history {
    margin-top: 48px;

    h3 {
      @extend %text-medium;
      color: $bw0;
    }

    &__container {
      margin-top: 24px;

      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }


  }
}

.appeal-item {
  padding: 16px 24px;

  border-radius: 6px;
  border: 1px solid $bw3;

  &__top {
    display: flex;
    align-items: center;
    column-gap: 24px;

    >div {
      width: 100%;

      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
  }

  &__label,
  &__date {
    @extend %text-regular;
    color: $bw1;
  }

  &__value {
    @extend %text-regular;
    color: $bw0;
  }

  &__button {
    white-space: nowrap;

    padding: 12px 16px;

    border-radius: 4px;

    background-color: $bw4;

    @extend %text-regular;
    color: $bw0;

    &.disabled {
      opacity: 1;
      color: $bw3;
    }
  }

  &__answer {
    margin-top: 24px;

    padding-top: 24px;

    border-top: 1px solid $bw3;

    button {
      display: flex;
      column-gap: 8px;
      align-items: center;

      background-color: transparent;

      span {
        @extend %text-regular;
        color: $bw1;
      }

      svg {
        transform: rotate(180deg);

        transition: transform .3s;
      }
    }

    &.show {
      button {
        svg {
          transform: rotate(0);
        }
      }

      .appeal-item__answer-text {
        display: block;
      }
    }
  }

  &__answer-text {
    margin-top: 12px;

    padding: 16px;

    border-radius: 6px;
    background: $bw4;

    @extend %text-regular;
    color: $bw0;

    max-width: 600px;

    display: none;
  }

  &.show {
    .appeal-item__answer {
      button {
        svg {
          transform: rotate(0);
        }
      }
    }

    .appeal-item__answer-text {
      display: block;
    }
  }
}

@media screen and (max-width: 800px) {
  .question-page {
    .buttons {
      flex-direction: column;
      row-gap: 24px;

      button {
        max-width: 100%;
      }
    }
  }

  .appeal-item {
    &__top {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      row-gap: 24px;
    }
  }
}

@media screen and (max-width: 600px) {
  .question-page {
    .buttons {
      flex-direction: column;
      row-gap: 12px;

      button {
        max-width: 100%;
      }
    }
  }

  .appeal-item {
    &__button {
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>