import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useSearchValue = defineStore('searchValue', () => {
  const searchValue = ref('')

  return {
    searchValue
  }
})
