import { defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'

export const useBreadcrumbs = defineStore('breadcrumbs', () => {
  const breadcrumbs = ref([])

  return {
    breadcrumbs
  }
})
