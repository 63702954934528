<template>
    <h3 class="custom-header" :class="line">{{ title }}</h3>
</template>

<script>
export default {
    name: 'CustomHeader',

    props: {
        title: {
            type: String,
            default() {
                return "Заголовок"
            }
        },
        line: {
            type: String,
            default() {
                return "long"
            }
        },
    },
}
</script>

<style lang="scss">
.custom-header {
    position: relative;

    color: var(--bw0);
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 1.3px;
    text-transform: uppercase;

    padding-bottom: 8px;

    &::after {
        position: absolute;

        display: block;

        content: "";

        left: 0;
        bottom: 0;

        width: 180px;
        height: 4px;

        background-color: var(--primary);
    }

    &.fit {
        width: fit-content;

        &::after {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1600px) {
    .custom-header {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
    }
}

@media screen and (max-width: 800px) {
    .custom-header {
        font-size: 20px;
        line-height: 28px;

        width: fit-content;

        &::after {
            width: 100%;
        }

        &.short {
            &::after {
                width: 180px;
            }
        }
    }
}
</style>