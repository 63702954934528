<template >
    <Transition>
        <div class="v-popup specification-popop" v-show="isOpen" @click.self="onWrapperClose">
            <section class="v-popup__block">
                <svg class="cross" @click="closePopup" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 18L6 6" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M18 6L6 18" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div class="v-popup__container">
                    <file-upload :text="'Загрузите шаблон спецификации в формате .xsxl'" :formats="'.xlsx'"
                        :isFileList="false" @file-uploaded="onFileUploaded"></file-upload>
                </div>
            </section>
        </div>
    </Transition>
</template>

<script>
import FileUpload from './FileUpload.vue';

export default {
    name: 'SpecificationPopup',

    components: {
        FileUpload
    },

    props: {
        isOpen: {
            type: Boolean,
            default() {
                return false;
            }
        },
    },

    emits: ['close', 'fileUploaded'],

    data() {
        return {

        };
    },

    created() {

    },
    unmounted() {

    },

    methods: {
        closePopup() {
            this.$emit('close')
        },

        onWrapperClose(event) {
            this.$emit('close')
        },

        onFileUploaded(file) {
            this.$emit('fileUploaded', file)
            this.$emit('close')
        }
    },
}
</script>

<style lang="scss">
.v-popup {
    $p: &;

    $padding: 40px;

    position: fixed;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
    z-index: 5;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.40);

    .cross {
        cursor: pointer;


        position: absolute;
        top: 23px;
        right: 23px;

        z-index: 2;

        path {
            transition: stroke .3s;
        }


        &:hover {
            path {
                stroke: var(--primary)
            }
        }
    }

    &__block {
        z-index: 10;

        border-radius: 6px;
        background: $bw6;

        width: 100%;
        max-width: 1000px;

        padding: $padding 0 $padding $padding;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding-right: $padding;

        p {
            @extend %H2;
            color: $bw0;
        }
    }

    &__container {
        display: flex;
        column-gap: 40px;

        margin-top: 18px;

        >div {
            width: 100%;

            min-width: 0;
        }
    }

    &.specification-popop {
        #{$p}__container {
            margin-top: 0;
        }

        #{$p}__block {
            position: relative;

            padding: 0;

            max-width: 800px;
        }

        .file-upload-block__label {
            padding: 80px 70px 80px;
        }

        .file-upload-block__label__text {
            text-align: center;
        }
    }
}
</style>