<template>
    <section class="catalog-request-block">
        <div v-if="!requestSended">
            <div class="catalog-request-block__top">
                <v-select class="select" :select-id="1" :select-name="'Выбрать каталог'" :options="catalogOptions"
                    :selected="catalogValue.name" @select="catalogSelectHandler"></v-select>
                <div class="change-amount-block">
                    <p>Количество</p>
                    <amount-block :amount="amount" @amount-change="onAmountChange"></amount-block>
                </div>
            </div>
            <a :href="activeCatalog.file_link" class="line-file-item" download target="_blank">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7 21C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14L19 8V19C19 20.1046 18.1046 21 17 21H7Z"
                        stroke="#035AA6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M13 3V9H19" stroke="#035AA6" stroke-width="1.5" stroke-linejoin="round" />
                </svg>
                <span>{{ activeCatalog.file_name }}</span>
            </a>
            <button class="catalog-request-block__btn" @click="sendRequest">Отправить заявку</button>
        </div>
        <template v-else>
            <p class="special-order-page__text">Запрос каталога отправлен</p>
            <button class="special-order-page__btn" @click="requestSended = false">Создать новый</button>
        </template>
        <div class="request-history">
            <request-item v-for="appeal in history" :content="appeal" @feedback-send="getData"></request-item>
        </div>
        <v-pagination class="catalog-page__pagination" v-show="isPagination" :totalPages="getTotalPages"
            :currentPage="currentPage" @page-change="pageChange"></v-pagination>
    </section>
</template>

<script>
import StatusBlock from '../StatusBlock.vue';
import AmountBlock from '../AmountBlock.vue';
import vSelect from '../v-select.vue';
import vPagination from '../v-pagination.vue';
import RequestItem from '../RequestItem.vue';

import { serverRequest } from '../../api/request';

export default {
    name: 'SampleRequest',

    components: {
        StatusBlock,
        AmountBlock,
        vSelect,
        vPagination,
        RequestItem
    },

    emits: ['setLoading'],

    props: {
        userToken: {
            type: String,
            default() {
                return ''
            }
        },
    },

    data() {
        return {
            itemAmount: 10,
            allItems: 0,
            currentPage: 1,
            amount: 1,
            requestSended: false,
            history: [],
            catalogValue: {
                name: '',
                value: ''
            },
            catalogs: [],
            activeCatalog: {
                name: '',
                link: ''
            },
            catalogOptions: [
                {
                    name: 'каталог',
                    value: 'каталог'
                },
                {
                    name: 'каталог',
                    value: 'каталог'
                },
                {
                    name: 'каталог',
                    value: 'каталог'
                },
            ],
        };
    },

    beforeMount() {
        this.getCatalogs()
        this.getData();
    },

    computed: {
        isPagination() {
            if (this.allItems > this.itemAmount) {
                return true
            }
            else {
                return false
            }
        },

        getTotalPages() {
            if (this.isPagination) {
                return Math.ceil(this.allItems / this.itemAmount)
            }
            else {
                return 1
            }
        }
    },

    methods: {
        onAmountChange(amount) {
            this.amount = amount
        },

        catalogSelectHandler(option) {
            this.catalogValue = option
            this.activeCatalog = this.catalogs.find(item => item.id == this.catalogValue.value)
        },

        async getCatalogs() {
            let result = await serverRequest('GET', 'json', 'marketing/catalogs')

            if (result != 'error' && result != 'unathorized') {
                this.catalogs = result

                this.catalogOptions = [];

                for (let item of this.catalogs) {
                    let newItem = {
                        name: item.title,
                        value: item.id
                    }

                    this.catalogOptions.push(newItem)
                }

                this.catalogValue = this.catalogOptions[0]
                this.activeCatalog = this.catalogs.find(item => item.id == this.catalogValue.value)
            }
            else {
                //alert('Ошибка запроса')
            }
        },

        pageChange(page) {
            this.currentPage = page

            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.getData()
        },


        async getData() {
            this.$emit("setLoading", true);

            let formData = {
                type: 1,
                start_pos: this.itemAmount * (this.currentPage - 1),
                amount: this.itemAmount,
            }

            let result = await serverRequest('POST', 'json', 'marketing/get_requests', formData)

            if (result != 'error' && result != 'unathorized') {
                this.history = result.requests
                this.allItems = result.all_count

                this.$emit("setLoading", false)
            }
            else {
                //alert('Ошибка запроса')
            }
        },

        async sendRequest() {
            this.$emit("setLoading", true);

            console.log(this.activeCatalog);


            let formData = {
                type: 1,
                title: this.activeCatalog.title,
                quantity: this.amount
            }

            let result = await serverRequest('POST', 'json', 'marketing/requests', formData)

            if (result != 'error' && result != 'unathorized') {
                this.amount = 1
                this.$emit("setLoading", false)

                this.requestSended = true

                this.getData()
            }
            else {
                //alert('Ошибка запроса')
            }
        }
    }
}
</script>

<style lang="scss">
.catalog-request-block {
    &__top {
        display: flex;
        align-items: center;
        column-gap: 40px;

        max-width: 510px;
    }

    .line-file-item {
        margin-top: 12px;
    }

    &__btn {
        margin-top: 24px;

        @extend %button;
        width: 200px;
    }

    .input-container {
        @extend %input-container;
    }

    .change-amount-block {
        p {
            @extend %text-regular;
            color: $bw1;
        }

        .amount-block {
            margin-top: 14px;

            margin-bottom: 6px;
        }
    }

    .request-history {
        margin-top: 32px;

        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }
}

.line-file-item {
    display: flex;
    align-items: center;
    column-gap: 4px;

    width: fit-content;

    span {
        @extend %text-regular;
        color: var(--blue);
    }
}
</style>