<template>
    <div class="v-radio">
        <input type="radio" :id="id" :name="name" :value="value" :checked="checked" @change="onCheckboxChange">
        <label :for="id">{{ label }}</label>
    </div>
</template>

<script>
export default {
    name: 'v-radio',
    props: {
        name: {
            type: String,
            default() {
                return 'name'
            }
        },
        id: {
            type: String,
            default() {
                return 'checkbox'
            }
        },
        label: {
            type: String,
            default() {
                return 'Чекбокс'
            }
        },
        value: {
            type: String,
            default() {
                return "Чекбокс"
            }
        },
        checked: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    emits: ['changed'],
    data() {
        return {

        }
    },
    methods: {
        onCheckboxChange() {
            this.$emit('changed', this.value)
        }
    }

}
</script>

<style lang="scss">
.v-radio {
    position: relative;

    input {
        position: absolute;
        width: 1px;
        height: 1px;
        z-index: -1;
        opacity: 0;
    }

    input:checked+label::after {
        background-color: var(--primary);
    }


    label {
        display: flex;
        gap: 12px;
        align-items: center;

        cursor: pointer;

        @extend %text-regular;
        color: var(--bw0);

        user-select: none;

        &::before {
            content: "";
            width: 16px;
            height: 16px;
            display: block;
            border: 1px solid var(--primary);
            border-radius: 50%;

            flex-shrink: 0;

            transition: background-color 0.3s, border 0.3s;
        }

        &::after {
            content: "";
            width: 8px;
            height: 8px;
            display: block;

            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 4px;

            border: 4px solid transparent;

            border-radius: 50%;

            background-color: transparent;

            transition: background-color .3s;
        }
    }
}
</style>