
<template>
  <div class="documents-page">
    <custom-header :title="'документы'" :line="'fit'"></custom-header>
    <div class="documents-page__subview" :class="{ 'loading': isLoading }">
      <v-loading :is-loading="isLoading" :circle-position="'top'" :color="'white'"></v-loading>
      <div class="documents-filter-block">
        <swiper class="category-slider" :slides-per-view="'auto'" :space-between="16" :allow-touch-move="true"
          :grab-cursor="true" @reach-end="onReachEnd" @slider-move="onSliderMove" :class="{ 'gradient': !isSliderEnd }">
          <swiper-slide v-for="category, number in documentsCategories">
            <filter-category :id="category.id" :label="category.name" :name="'order-category'" :value="category.value"
              :checked="category.checked" @changed="onCategoryChange"></filter-category>
          </swiper-slide>
        </swiper>
      </div>
      <transition name="component-fade" mode="out-in">
        <component v-bind:is="activePage" :user-token="userToken" @set-loading="onSetLoading"></component>
      </transition>
    </div>
  </div>
</template>

<script>
import CustomHeader from '../components/CustomHeader.vue';
import FilterCategory from '../components/FilterCategory.vue';
import AlertBlock from '../components/AlertBlock.vue';
import vCheckbox from '../components/v-checkbox.vue';
import SuppliesBlock from '../components/documents/SuppliesBlock.vue'
import BillsBlock from '../components/documents/BillsBlock.vue'
import ComplaintsBlock from '../components/documents/ComplaintsBlock.vue'
import ReportsBlock from '../components/documents/ReportsBlock.vue'
import vLoading from '../components/v-loading.vue';

import { Swiper, SwiperSlide } from 'swiper/vue';

import { mapState, mapWritableState } from 'pinia'
import { useUserToken } from '@/stores/userToken'
import { useBreadcrumbs } from '../stores/breadcrumbs';

export default {
  name: 'DocumentsView',
  components: {
    CustomHeader,
    FilterCategory,
    AlertBlock,
    vCheckbox,
    SuppliesBlock,
    BillsBlock,
    ComplaintsBlock,
    ReportsBlock,
    vLoading,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      activePage: 'SuppliesBlock',
      isLoading: false,
      isSliderEnd: false,
      documentsCategories: [
        {
          id: 'cat-supply',
          name: 'Договоры поставок',
          value: 'SuppliesBlock',
          checked: true
        },
        {
          id: 'cat-bills',
          name: 'Счета',
          value: 'BillsBlock',
          checked: false
        },
        {
          id: 'cat-complaints',
          name: 'Рекламации',
          value: 'ComplaintsBlock',
          checked: false
        },
        {
          id: 'cat-reports',
          name: 'Акты сверки',
          value: 'ReportsBlock',
          checked: false
        },
      ],
    }
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'documents',
        name: 'Документы'
      },
    ]
  },

  computed: {
    ...mapState(useUserToken, { userToken: 'token' }),
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),

  },

  methods: {
    onCategoryChange(id, value) {
      this.activePage = value
    },

    onSetLoading(status) {
      this.isLoading = status
    },

    onReachEnd() {
      this.isSliderEnd = true
    },

    onSliderMove(slider) {
      if (slider.isEnd == false) {
        this.isSliderEnd = false
      }
    }
  }
}
</script>

<style lang="scss">
.documents-page {
  @extend %white-bg-block;

  width: 100%;

  .documents-filter-block {
    margin-top: 32px;

    display: flex;
    align-items: center;
    column-gap: 16px;
  }

  &__subview {
    position: relative;

    &.loading {
      pointer-events: none;
    }
  }
}
</style>
