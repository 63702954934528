<template>
    <div class="v-switch">
        <template v-for="option in options">
            <input type="radio" :id="option.id" :name="option.name" :checked="option.checked"
                @change="$emit('switched', option.value)">
            <label :for="option.id">{{ option.label }}</label>
        </template>
    </div>
</template>

<script>
export default {
    name: 'AmountBlock',
    props: {
        options: {
            type: Array,
            default() {
                return []
            }
        },
    },

    emits: ['switched'],

    data() {
        return {

        }
    },

    computed: {

    },

    methods: {

    }

}
</script>

<style lang="scss">
.v-switch {
    display: flex;
    column-gap: 12px;

    @extend %white-bg-block;

    padding: 6px;

    input {
        position: absolute;
        z-index: -99;
        width: 1px;
        height: 1px;
        opacity: 0;
    }

    label {
        padding: 6px 8px;

        @extend %text-regular;
        color: var(--bw0);

        background-color: transparent;

        transition: background-color .3s, color .3s;

        border-radius: 4px;

        cursor: pointer;
    }

    input:checked+label {
        color: var(--bw6);
        background-color: var(--primary);
    }
}
</style>