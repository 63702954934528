<template>
  <div class="special-order-page">
    <custom-header :title="'специальный заказ'"></custom-header>
    <div class="special-order-page__wrapper" :class="{ 'loading': isLoading }">
      <v-loading :is-loading="isLoading" :color="'white'"></v-loading>
      <template v-if="!specialOrderSended">
        <div class="special-order-page__container">
          <div class="text-area" :class="{ 'error': v$.text.$errors.length }">
            <label for="comment">Комментарий</label>
            <textarea id="comment" type="text" placeholder="Введите текст" v-model="text"></textarea>
          </div>
          <file-line-upload class="file-upload" @fileUploaded="onFileUploaded" @file-deleted="onFileDeleted"
            :formats="'.doc,.docx,.png,.jpg,.jpeg,.pdf'"></file-line-upload>
        </div>
        <div class="input-container" :class="{ 'error': v$.phone.$errors.length }">
          <label for="complaints-number">Контактный номер</label>
          <input id="complaints-number" v-maska="phoneBindedObject" data-maska="+7 (###) ###-##-##" type="text"
            v-model="phone" placeholder="+7 (___) ___ - __ - __">
        </div>
        <div class="input-container" :class="{ 'error': v$.email.$errors.length }">
          <label for="complaints-number">e-mail</label>
          <input id="complaints-number" type="text" v-model="email">
        </div>
        <button class="special-order-page__btn" @click="createSpecialOrder">Отправить</button>
      </template>
      <template v-else>
        <p class="special-order-page__text">Заявка на специальный заказ отправлена</p>
        <button class="special-order-page__btn" @click="specialOrderSended = false">Создать новый</button>
      </template>
    </div>
  </div>
</template>

<script>
import CustomHeader from '../components/CustomHeader.vue';
import FileLineUpload from '../components/FileLineUpload.vue';
import vLoading from '../components/v-loading.vue';

import { vMaska } from "maska"

import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

import { mapWritableState, mapState } from 'pinia';
import { useGeneralInfo } from '../stores/generalInfo';
import { useBreadcrumbs } from '../stores/breadcrumbs';

import { serverRequest } from '../api/request';

export default {
  name: 'SpecialOrderView',
  components: {
    CustomHeader,
    FileLineUpload,
    vLoading
  },

  directives: { maska: vMaska },

  data() {
    return {
      v$: useVuelidate(),

      phoneBindedObject: {
        masked: "",
        unmasked: "",
        completed: false
      },
      text: null,
      phone: null,
      email: null,
      file: null,
      isLoading: false
    }
  },

  validations() {
    return {
      text: { required },
      phone: { required },
      email: { required, email }
    }
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'special-order',
        name: 'Специальный заказ'
      },
    ]
  },

  computed: {
    ...mapWritableState(useGeneralInfo, { specialOrderSended: 'specialOrderSended' }),
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),
  },

  methods: {
    onFileUploaded(file) {
      this.file = file
    },

    onFileDeleted() {
      this.file = null
    },

    async createSpecialOrder() {
      this.v$.$validate();

      if (!this.v$.$error && this.phoneBindedObject.completed == true) {
        this.isLoading = true

        let formData = new FormData();

        formData.append('type', 5)
        formData.append('comment', this.text)
        formData.append('phone', this.phone)
        formData.append('email', this.email)
        formData.append('file', this.file)

        for (let [name, value] of formData) {
          console.log(`${name} = ${value}`);
        }

        let result = await serverRequest('POST', 'formData', 'marketing/requests', formData)

        if (result != 'error' && result != 'unathorized') {
          this.specialOrderSended = true
        }
        else {
          //alert('Ошибка запроса')
        }

        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
.special-order-page {
  @extend %white-bg-block;
  width: 100%;

  &__wrapper {
    position: relative;

    &.loading {
      pointer-events: none;
    }
  }

  &__container {
    max-width: 800px;
  }

  &__text {
    margin-top: 24px;

    @extend %text-medium;
    color: $bw0;
  }

  .text-area {
    margin-top: 32px;

    @extend %text-area;
  }

  .file-upload {
    margin-top: 12px;

  }

  .input-container {
    margin-top: 24px;

    max-width: 380px;

    @extend %input-container;

    label {
      white-space: nowrap;
    }
  }

  &__btn {
    margin-top: 32px;

    @extend %button;

    max-width: 200px;
  }
}
</style>
