<template>
    <div class="filter-category">
        <input type="radio" :id="id" :name="name" :value="value" @change="onCheckboxChange" :checked="checked">
        <label :for="id">{{ label }}</label>
    </div>
</template>

<script>
export default {
    name: 'FilterCategory',
    props: {
        name: {
            type: String,
            default() {
                return 'name'
            }
        },
        id: {
            type: String,
            default() {
                return 'checkbox'
            }
        },
        label: {
            type: String,
            default() {
                return 'Чекбокс'
            }
        },
        value: {
            type: String,
            default() {
                return "Чекбокс"
            }
        },
        checked: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    emits: ['changed'],
    data() {
        return {

        }
    },
    methods: {
        onCheckboxChange() {
            this.$emit('changed', this.id, this.value)
        }
    }

}
</script>

<style lang="scss">
.filter-category {
    position: relative;

    input {
        position: absolute;
        width: 1px;
        height: 1px;
        z-index: -1;
        opacity: 0;
    }

    input:checked+label {
        background-color: var(--bw1);
        color: var(--bw6);
    }


    label {
        display: block;
        padding: 8px 16px;

        border-radius: 51px;

        background-color: var(--bw4);

        cursor: pointer;

        @extend %text-medium;
        color: var(--bw1);

        user-select: none;

        transition: background-color .3s, color .3s;
    }
}

@media screen and (max-width: 800px) {
    .filter-category {
        label {
            padding: 4px 12px;
        }
    }
}
</style>