<template>
    <section class="cart-item">
        <div class="cart-item__img-container">
            <img v-show="hasImage" class="cart-item__img" :src="images[0]" alt="изображение товара"
                @error="imgHandler(false)" @load="imgHandler(true)">
            <img v-show="!hasImage" class="cart-item__img img-placeholder" src="@/assets/icons/img-placeholder.svg"
                alt="изображение">
        </div>
        <div class="cart-item__info">
            <div class="cart-item__name-container">
                <router-link :to="{ name: 'product', params: { id: id } }" class="cart-item__name">
                    {{ name }}
                </router-link>
                <p class="cart-item__articul">{{ articul }}</p>
            </div>
            <p class="cart-item__multiplicity">Кратность отгрузки: <br>
                {{ multiplicity }}.</p>
            <div class="cart-item__amount-price">
                <p class="cart-item__price">{{ parseInt(price).toLocaleString('ru-RU') }} ₽</p>
                <amount-block class="cart-item__amount" :amount="amount" @amountChange="amountChange"></amount-block>
                <p class="cart-item__full-price">{{ parseInt(getFullPrice).toLocaleString('ru-RU') }} ₽</p>
                <button class="cart-item__remove-btn" @click="itemRemove()">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                            stroke="#D93030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15 9L9 15" stroke="#D93030" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M9 9L15 15" stroke="#D93030" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </button>
            </div>
        </div>
    </section>
</template>

<script>
import AmountBlock from './AmountBlock.vue'

import { mapWritableState, mapActions } from 'pinia'
import { useAddedGoods } from '@/stores/addedGoods'

export default {
    name: 'CartItem',

    components: {
        AmountBlock
    },

    emits: ['itemRemoved', 'amountChange'],

    props: {
        images: {
            type: Array,
            default() {
                return []
            }
        },
        id: {
            type: Number,
            default() {
                return 0
            }
        },
        articul: {
            type: String,
            default() {
                return "Артикул"
            }
        },
        name: {
            type: String,
            default() {
                return "Название"
            }
        },
        price: {
            type: Number,
            default() {
                return 0
            }
        },
        multiplicity: {
            type: String,
            default() {
                return '1/1'
            }
        },
        amount: {
            type: Number,
            default() {
                return 1
            }
        },
    },

    data() {
        return {
            hasImage: false
        }
    },

    computed: {
        ...mapWritableState(useAddedGoods, ['cartGoods']),

        getFullPrice() {
            return this.price * this.amount
        }
    },

    methods: {
        ...mapActions(useAddedGoods, { storeSetAmount: 'setAmount' }),
        ...mapActions(useAddedGoods, { storeDeleteGood: 'deleteGood' }),

        amountChange(amount) {
            this.$emit('amountChange', this.id, amount)
            this.storeSetAmount(this.id, amount)
        },
        itemRemove() {
            this.$emit('itemRemoved', this.id)

            this.storeDeleteGood(this.id)
        },
        imgHandler(status) {
            this.hasImage = status
        }
    }
}
</script>

<style lang="scss">
.cart-item {
    display: flex;
    align-items: center;

    padding-top: 12px;
    border-top: 1px solid var(--bw3);

    &__info {
        width: 100%;
        display: flex;
        align-items: center;
    }

    &__amount-price {
        display: flex;
        align-items: center;
    }

    &__img-container {
        width: 120px;
        height: 120px;

        flex-shrink: 0;
    }

    &__img {
        width: 100%;
        height: 100%;

        object-fit: contain;

        border-radius: 6px;

        user-select: none;
    }

    .img-placeholder {
        background-color: $bw4;
    }

    &__name-container {
        margin-left: 24px;

        max-width: 400px;
    }

    &__name {
        @extend %text-regular;
        color: var(--bw1);
    }

    &__articul {
        @extend %text-regular;
        color: var(--bw1);
    }

    &__multiplicity {
        margin-left: auto;

        @extend %text-regular;
        color: var(--blue);
    }

    &__price,
    &__full-price {
        min-width: 100px;
        margin-left: 60px;

        @extend %text-medium;
        color: var(--bw1);
        text-align: center;

        user-select: none;
    }

    &__amount {
        margin-left: 60px;
    }

    &__remove-btn {
        margin-left: 60px;

        background-color: transparent;
    }
}

@media screen and (max-width: 1600px) {
    .cart-item {
        justify-content: space-between;

        &__info {
            justify-content: space-between;
        }

        &__name-container {
            max-width: 240px;
            min-width: 240px;
        }

        &__multiplicity {
            margin-left: 10px;
        }

        &__price,
        &__full-price {
            min-width: 100px;
            margin-left: 30px;

            @extend %text-medium;
            color: var(--bw1);
            text-align: center;

            user-select: none;
        }

        &__amount {
            margin-left: 30px;
        }

        &__remove-btn {
            margin-left: 30px;

            background-color: transparent;
        }
    }
}

@media screen and (max-width: 800px) {
    .cart-item {
        column-gap: 12px;

        &__info {
            width: 100%;
            align-items: flex-start;
            flex-direction: column;
        }

        &__name-container {
            max-width: 100%;
            margin-left: 0;
        }

        &__articul {
            display: none;
        }

        &__multiplicity {
            margin-top: 4px;
            margin-left: 0;
        }

        &__price {
            margin-left: 0;
            min-width: 0;
        }

        &__full-price {
            display: none;
        }

        &__amount-price {
            margin-top: 12px;

            width: 100%;
        }

        &__remove-btn {
            margin-left: auto;
        }
    }
}

@media screen and (max-width: 600px) {
    .cart-item {
        width: 100%;
        align-items: flex-start;

        &__info {
            width: 100%;
        }

        &__name-container {
            min-width: 0;
        }

        &__name {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -moz-box;
            -moz-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-clamp: 2;
            box-orient: vertical;
        }

        &__amount-price {
            margin-top: 6px;
            max-width: 100%;
        }

        &__amount {
            margin-left: auto;
        }

        &__remove-btn {
            margin-left: 16px;
        }
    }
}
</style>