<template>
  <div class="notification-container">
    <notification-item
      class="notification-container-item"
      v-for="notification in notifications"
      :key="notification.id"
      :title="notification.title"
      :text="notification.text"
      :id="notification.id"
      :type="getType(notification.type)"
      :order-id="notification.orderId"
      :class="{ 'deleting-notification': deletedNoticication == notification.id }"
      @remove="onRemove"
    ></notification-item>
  </div>
</template>

<script>
import NotificationItem from './NotificationItem.vue'

import { mapWritableState, mapActions } from 'pinia'

import { useNotifications } from '@/stores/notifications'

import { serverRequest } from '@/api/request'

export default {
  name: 'NotificationContainer',

  components: {
    NotificationItem
  },

  props: {
    number: {
      type: Number,
      default() {
        return 0
      }
    }
  },

  data() {
    return {
      deletedNoticication: null
    }
  },

  computed: {
    ...mapWritableState(useNotifications, { notifications: 'notifications' })
  },

  beforeMount() {
    this.getNotifications()
  },

  methods: {
    ...mapActions(useNotifications, { addNotification: 'addNotification' }),
    ...mapActions(useNotifications, { removeNotification: 'removeNotification' }),

    getType(type) {
      switch (type) {
        case 0: {
          return 0
        }
        case 1: {
          return 2
        }
        case 2: {
          return 2
        }
        case 3: {
          return 3
        }
      }
    },

    async onRemove(id, type) {
      this.deletedNoticication = id

      let formData = {
        id: id
      }

      setTimeout(async () => {
        if (type >= 0 && type < 4) {
          let result = await serverRequest('POST', 'json', 'read_notification', formData)

          if (result.status == 'ok') {
            this.removeNotification(id)
          }
        } else {
          this.removeNotification(id)
        }
      }, 500)
    },

    async getNotifications() {
      let result = await serverRequest('GET', 'json', 'get_notifications')

      if (result != 'error' && result != 'unathorized') {
        for (let notification of result.notifications) {
          if (this.notifications.findIndex((obj) => obj.id === notification.id) === -1) {
            this.addNotification(
              notification.id,
              notification.title,
              notification.text,
              notification.type,
              notification.order_id
            )
          }
        }

        // this.notifications = [
        //     {
        //         text: 'Текст уведомления 123312',
        //         id: 0,
        //         type: 0
        //     },
        //     {
        //         text: 'Текст уведомления 5646456',
        //         id: 1,
        //         type: 0
        //     },
        //     {
        //         text: 'Текст уведомления 909889989',
        //         id: 2,
        //         type: 0
        //     },
        // ]
      }

      // this.addNotification(1, 'Заголовок уведомления', 'Это обычное текстовое уведомление', 0)
      // this.addNotification(2, 'Заголовок уведомления', 'Это уведомление-предупреждение', 1)
      // this.addNotification(3, 'Заголовок уведомления', 'Это с кнопкой', 2)
      // this.addNotification(4, 'Заголовок уведомления', 'Это со списком и кнопкой', 3, 3)
    }
  }
}
</script>

<style lang="scss">
.notification-container {
  position: fixed;
  right: 70px;
  bottom: 40px;

  display: flex;
  flex-direction: column;
  row-gap: 16px;

  width: 100%;
  max-width: 400px;

  z-index: 5;

  .notification-container-item {
    transform: translateX(0);

    transition: transform 0.5s ease;
  }

  .deleting-notification {
    transform: translateX(120%);
  }
}

@media screen and (max-width: 1600px) {
  .notification-container {
    right: 32px;
  }
}
</style>
