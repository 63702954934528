<template>
  <div class="catalog-page">
    <v-popup :is-open="isPopupOpen" :product="activePopupProduct" @close="onCloseProduct"></v-popup>
    <transition name="fade">
      <div class="catalog-page__search" v-if="$route.name != 'catalog-goods' && !isTablet">
        <main-search @search="onSearch" v-if="$route.name != 'catalog-goods' && !isTablet"></main-search>
        <div class="catalog-switch" v-if="$route.name != 'search-goods'">
          <router-link class="link"
            :class="{ 'active': $route.name == 'categories' || $route.name == 'search-goods' || $route.name == 'goods' }"
            :to="{ name: 'categories' }">Каталог</router-link>
          <router-link class="link" :to="{ name: 'specifications' }">Спецификация</router-link>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <router-view v-slot="{ Component }" :categories="categories" :userToken="userToken"
        :isCategoryLoading="isCategoryLoading" :activeCategory="activeCategory" :searchValue="searchValue"
        @categoryChoosen="onCategoryChoosen" @specificationShow="onSpecificationShow" @categoryShown="onCategoryShown"
        @showProduct="onShowProduct" @closeProduct="onCloseProduct" @breadcrumbChange="onBreadcrumbChange"
        ref="catalogContent">
        <component :is="Component" />
      </router-view>
    </transition>
  </div>
</template>

<script>
import MainSearch from '../../components/MainSearch.vue';
import vPopup from '../../components/v-popup.vue';

import { mapState, mapWritableState } from 'pinia'
import { useUserToken } from '@/stores/userToken'
import { useCatalogCategories } from '../../stores/catalogCategories';
import { useBreadcrumbs } from '@/stores/breadcrumbs';
import { useSearchValue } from '../../stores/searchValue';

import { serverRequest } from '../../api/request';

export default {
  name: 'NewCatalogView',
  components: {
    MainSearch,
    vPopup
  },

  data() {
    return {
      activeBlock: 'category',
      lineShow: false,
      isPopupOpen: false,
      isCategoryLoading: false,
      activeCategory: null,
      activePopupProduct: {
        articul: 'артикул',
        name: 'название',
        brand: 'brand',
        multiplicity: '1/1',
        images: [],
        unit: 'шт',
        price: 1000
      },
      searchValue: '',
      isTablet: false,

      categories: [
        // {
        //   id: '1',
        //   header: 'СВАРОЧНОЕ ОБОРУДОВАНИЕ И АКСЕССУАРЫ',
        //   list: [
        //     {
        //       id: 'cat1',
        //       name: 'Сварочные инвенторы MMA'
        //     },
        //     {
        //       id: 'cat2',
        //       name: 'Сварочные полуавтоматы MIG/MAG'
        //     },
        //   ]
        // },
        // {
        //   id: '2',
        //   header: 'ЗАРЯДНЫЕ / ПУСКО-ЗАРЯДНЫЕ УСТРОЙСТВА',
        //   list: [
        //     {
        //       id: 'cat1',
        //       name: 'Сварочные инвенторы MMA'
        //     },
        //     {
        //       id: 'cat2',
        //       name: 'Сварочные полуавтоматы MIG/MAG'
        //     },
        //   ]
        // },
        // {
        //   id: '3',
        //   header: 'МЕТАЛЛОРЕЖУЩИЙ ИНСТРУМЕНТ',
        //   list: [
        //     {
        //       id: 'cat1',
        //       name: 'Фрезы концевые твердосплавные'
        //     },
        //     {
        //       id: 'cat2',
        //       name: 'Корончатые сверла (фрезы кольцевые)'
        //     },
        //     {
        //       id: 'cat3',
        //       name: 'Пильные диски по металлам (Алюминий/Сталь)'
        //     },
        //     {
        //       id: 'cat4',
        //       name: 'Борфрезы'
        //     },
        //     {
        //       id: 'cat5',
        //       name: 'Сверла по металлу'
        //     },
        //     {
        //       id: 'cat6',
        //       name: 'Биметаллические коронки'
        //     },
        //   ]
        // },
        // {
        //   id: '4',
        //   header: 'РЕЗЬБОНАРЕЗНОЙ ИНСТРУМЕНТ',
        //   list: [
        //     {
        //       id: 'cat1',
        //       name: 'Плашки'
        //     },
        //     {
        //       id: 'cat2',
        //       name: 'Наборы резьбонарезные'
        //     },
        //     {
        //       id: 'cat3',
        //       name: 'Метчики'
        //     },
        //   ]
        // },
        // {
        //   id: '5',
        //   header: 'ДЕРЕВООБРАБАТЫВАЮЩИЕ ИНСТРУМЕНТЫ',
        //   list: [
        //     {
        //       id: 'cat1',
        //       name: 'Пильные диски'
        //     },
        //   ]
        // },
        // {
        //   id: '6',
        //   header: 'ОСНАСТКА ДЛЯ МЕТАЛЛООБРАБОТКИ',
        //   list: [
        //     {
        //       id: 'cat1',
        //       name: 'Бруски - заготовки для резцов'
        //     },
        //     {
        //       id: 'cat2',
        //       name: 'Патроны для инструмента и станков'
        //     },
        //     {
        //       id: 'cat3',
        //       name: 'Переходники, дорны, оправки, ключи, штифты'
        //     },
        //   ]
        // },
        // {
        //   id: '16',
        //   header: 'АБРАЗИВНЫЙ И ЗАЧИСТНОЙ ИНСТРУМЕНТ ',
        //   list: [
        //     {
        //       id: 'cat1',
        //       name: 'Круги отрезные и зачистные (абразивные)'
        //     },
        //     {
        //       id: 'cat2',
        //       name: 'Щетки для обработки поверхностей'
        //     },
        //   ]
        // },
        // {
        //   id: '12',
        //   header: 'ИЗМЕРИТЕЛЬНЫЙ ИНСТРУМЕНТ',
        //   list: [
        //     {
        //       id: 'cat1',
        //       name: 'Рулетки'
        //     },
        //   ]
        // },
        // {
        //   id: '7',
        //   header: 'ИНСТРУМЕНТ ПОД ЗАКАЗ',
        //   list: [
        //   ]
        // },
        // {
        //   id: '8',
        //   header: 'ХИТЫ ПРОДАЖ',
        //   list: [
        //   ]
        // },
        // {
        //   id: '9',
        //   header: 'НОВИНКИ ТОВАРА',
        //   list: [
        //   ]
        // },
        // {
        //   id: '10',
        //   header: 'РАСПРОДАЖА',
        //   list: [
        //   ]
        // },
      ],
    }
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'categories',
        name: 'Каталог'
      }
    ]
    this.getCategories()

    if (document.documentElement.clientWidth <= 800) {
      this.isTablet = true
    }

    if (this.$route.name == 'search-goods') {
      this.searchValue = this.storeSearchValue
    }
  },

  mounted() {
    if (this.$route.name == 'goods' || this.$route.name == 'specifications') {
      this.lineShow = true
    }
    else {
      this.lineShow = false
    }
  },

  beforeUnmount() {
    this.storeSearchValue = ''
  },

  computed: {
    ...mapState(useUserToken, { userToken: 'token' }),
    ...mapWritableState(useCatalogCategories, { storeCategories: 'categories' }),
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),
    ...mapWritableState(useSearchValue, { storeSearchValue: 'searchValue' }),

  },

  methods: {
    onCategoryChoosen(header, name, id) {
      this.lineShow = true;
      this.activeCategory = id
    },

    onCategoryShown() {
      this.lineShow = false;
      if (this.storeBreadcrumbs.length > 2) {
        this.storeBreadcrumbs.pop()
        this.storeBreadcrumbs.pop()
      }
    },

    onSpecificationShow() {
      this.lineShow = true
    },

    onShowProduct(product) {
      this.activePopupProduct = product;
      this.isPopupOpen = true
      console.log(product);

    },

    onCloseProduct() {
      this.isPopupOpen = false
    },

    onSearch() {
      this.searchValue = this.storeSearchValue
    },

    onBreadcrumbChange(breadcrumbs) {
      console.log(this.storeBreadcrumbs.length);

      if (this.storeBreadcrumbs.length < 4) {
        if (this.storeBreadcrumbs.length == 2) {
          this.storeBreadcrumbs.pop()
        }
        for (let breadcrumb of breadcrumbs) {
          this.storeBreadcrumbs.push(breadcrumb)
        }
      }
    },

    async getCategories() {
      this.isCategoryLoading = true

      if (this.storeCategories == null) {
        let result = await serverRequest('GET', 'json', 'get_categories')

        if (result != 'error' && result != 'unathorized') {
          this.categories = result.data;
          this.storeCategories = result.data;
        }
        else {
          //alert('Ошибка запроса')
        }
      }
      else {
        this.categories = this.storeCategories
      }

      this.isCategoryLoading = false;
    }

  }
}
</script>


<style lang="scss">
.catalog-page {
  width: 100%;

  &__search {
    display: flex;
    column-gap: 32px;

    margin-bottom: 32px;
  }

  .catalog-switch {
    display: flex;
    column-gap: 12px;

    @extend %white-bg-block;

    padding: 6px;

    .link {
      padding: 6px 8px;

      @extend %text-regular;
      color: var(--bw0);

      background-color: transparent;

      transition: background-color .3s, color .3s;

      border-radius: 4px;

      cursor: pointer;
    }

    .link.router-link-exact-active,
    .link.active {
      color: var(--bw6);
      background-color: var(--primary);
      pointer-events: none;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .3s;
  }

  .fade-enter-to,
  .fade-leave-from {
    opacity: 1;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}

@media screen and (max-width: 800px) {
  .catalog-page {
    .catalog-switch {
      display: none;
    }
  }
}
</style>