<template>
    <div class="file-line-upload-block">
        <label class="file-line-upload-block__label" for="upload-file">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21 10.9696L12.9628 18.5497C11.9782 19.4783 10.6427 20 9.25028 20C7.85782 20 6.52239 19.4783 5.53777 18.5497C4.55315 17.6211 4 16.3616 4 15.0483C4 13.7351 4.55315 12.4756 5.53777 11.547L13.575 3.96687C14.2314 3.34779 15.1217 3 16.05 3C16.9783 3 17.8686 3.34779 18.525 3.96687C19.1814 4.58595 19.5502 5.4256 19.5502 6.30111C19.5502 7.17662 19.1814 8.01628 18.525 8.63535L10.479 16.2154C10.1508 16.525 9.70569 16.6989 9.24154 16.6989C8.77738 16.6989 8.33224 16.525 8.00403 16.2154C7.67583 15.9059 7.49144 15.4861 7.49144 15.0483C7.49144 14.6106 7.67583 14.1907 8.00403 13.8812L15.429 6.88674"
                    stroke="#035AA6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span>Прикрепить документ</span>
        </label>
        <input class="file-line-upload-block__input" type="file" id="upload-file" name="file" :accept="formats"
            @input="onLoad" ref="fileInput">
        <div class="upload-file-item" v-for="file in files">
            <svg class="upload-file-item__icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21 10.9696L12.9628 18.5497C11.9782 19.4783 10.6427 20 9.25028 20C7.85782 20 6.52239 19.4783 5.53777 18.5497C4.55315 17.6211 4 16.3616 4 15.0483C4 13.7351 4.55315 12.4756 5.53777 11.547L13.575 3.96687C14.2314 3.34779 15.1217 3 16.05 3C16.9783 3 17.8686 3.34779 18.525 3.96687C19.1814 4.58595 19.5502 5.4256 19.5502 6.30111C19.5502 7.17662 19.1814 8.01628 18.525 8.63535L10.479 16.2154C10.1508 16.525 9.70569 16.6989 9.24154 16.6989C8.77738 16.6989 8.33224 16.525 8.00403 16.2154C7.67583 15.9059 7.49144 15.4861 7.49144 15.0483C7.49144 14.6106 7.67583 14.1907 8.00403 13.8812L15.429 6.88674"
                    stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <p class="upload-file-item__name">{{ file.name }}</p>
            <p class="upload-file-item__size">{{ sizeHandler(file.size) }}</p>
            <button class="upload-file-item__button" type="button" @click="deleteFile(file.name)">Удалить</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FileLineUpload',

    emits: ['fileUploaded', 'fileDeleted'],

    props: {
        formats: {
            type: String,
            default() {
                return '.doc,.docx,.xlsx';
            }
        },
    },

    data() {
        return {
            files: [],
            events: ['dragenter', 'dragover', 'dragleave', 'drop'],
            isDrag: false,
            inActiveTimeout: null
        }
    },


    methods: {
        onLoad(e) {
            this.files = []
            this.files = [...e.target.files, ...this.files]
            this.$emit('fileUploaded', this.files[0])
        },
        preventDefaults(e) {
            e.preventDefault()
        },
        sizeHandler(size) {
            if ((parseInt(size) / 1024 / 1024) < 1) {
                return Math.ceil(parseInt(size) / 1024) + " Кб"
            }
            else {
                return Math.ceil(parseInt(size) / 1024 / 1024) + " Мб"
            }
        },
        deleteFile(name) {
            this.files = this.files.filter((file) => {
                return file.name !== name;
            })
            this.$refs.fileInput.value = ''
            this.$emit('fileDeleted')
        }
    }
}
</script>

<style lang="scss">
.file-line-upload-block {
    &__label {
        display: flex;
        column-gap: 8px;

        span {
            @extend %text-regular;
            color: var(--blue);
        }

        cursor: pointer;
    }

    &__input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        position: absolute;
        z-index: -10;
    }

    .upload-file-item {
        margin-top: 12px;

        display: flex;
        align-items: center;

        border-radius: 6px;
        border: 1px solid var(--bw3);

        padding: 12px 16px;

        &__name {
            margin-left: 8px;

            @extend %text-regular;
            color: var(--bw0);
        }

        &__size {
            margin-left: 8px;

            @extend %text-small;
            color: var(--bw3);
        }

        &__button {
            margin-left: auto;

            padding: 6px 12px;

            border-radius: 6px;
            background-color: var(--bw4);

            @extend %text-regular;
            color: var(--bw0);
        }
    }
}
</style>