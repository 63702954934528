<template>
  <div class="support-page">
    <div>
      <custom-header :title="'вопросы и предложения'"></custom-header>
      <section class="popular-questions-block">
        <h2>Популярные вопросы</h2>
        <div class="popular-questions-block__container">
          <question-item v-for="question in questions" :content="question"></question-item>
        </div>
      </section>
      <section class="manager-info">
        <h2>Ваш менеджер</h2>
        <p class="manager-info__name">{{ manager.name }}</p>
        <p class="manager-info__contacts">e-mail<a :href="'mailto:' + manager.email">{{ manager.email }}</a></p>
        <p class="manager-info__contacts">Контактный номер<a :href="'tel:' + manager.tel">{{ manager.tel }}</a></p>
      </section>
    </div>
    <div class="chat-block">
      <custom-header class="title" :title="'чат'"></custom-header>
      <section class="chat" :class="{ 'loading': chatLoading }">
        <v-loading :isLoading="chatLoading"></v-loading>
        <div class="chat__history-wrapper" ref="historyWrapper">
          <div class="chat__history">
            <div v-for="message in messages" :class="{ 'outcome': message.author == 1 }" v-html="message.text"></div>
          </div>
        </div>
        <!-- <div class="end-line">
          <span></span>
          <p>Конец переписки</p>
          <span></span>
        </div> -->
        <!-- <div class="feedback-block">
          <div class="feedback-block__text">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                stroke="#035AA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M13.5 16C13.5 16.8284 12.8284 17.5 12 17.5C11.1716 17.5 10.5 16.8284 10.5 16C10.5 15.1716 11.1716 14.5 12 14.5C12.8284 14.5 13.5 15.1716 13.5 16Z"
                fill="#035AA6" />
              <path
                d="M10.5858 8.11441C10.9754 7.72478 11.4858 7.52952 11.9965 7.52863C12.5095 7.52773 13.0228 7.72299 13.4142 8.11441C13.8047 8.50493 14 9.01678 14 9.52863C14 10.0405 13.8047 10.5523 13.4142 10.9428C13.0228 11.3343 12.5095 11.5295 11.9965 11.5286L12 12.5286"
                stroke="#035AA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <p>Помогло ли обращение в поддержку решить Вашу проблему?</p>
          </div>
          <div class="feedback-block__buttons">
            <button>Да</button>
            <button>Нет</button>
          </div>
        </div> -->
        <div class="chat__new-message">
          <div class="input-container">
            <input type="text" v-model="newMessage" @keypress.enter="sendMessage" placeholder="Ваше сообщение">
            <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21 10.9696L12.9628 18.5497C11.9782 19.4783 10.6427 20 9.25028 20C7.85782 20 6.52239 19.4783 5.53777 18.5497C4.55315 17.6211 4 16.3616 4 15.0483C4 13.7351 4.55315 12.4756 5.53777 11.547L13.575 3.96687C14.2314 3.34779 15.1217 3 16.05 3C16.9783 3 17.8686 3.34779 18.525 3.96687C19.1814 4.58595 19.5502 5.4256 19.5502 6.30111C19.5502 7.17662 19.1814 8.01628 18.525 8.63535L10.479 16.2154C10.1508 16.525 9.70569 16.6989 9.24154 16.6989C8.77738 16.6989 8.33224 16.525 8.00403 16.2154C7.67583 15.9059 7.49144 15.4861 7.49144 15.0483C7.49144 14.6106 7.67583 14.1907 8.00403 13.8812L15.429 6.88674"
                stroke="#D93030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg> -->
          </div>
          <button @click="sendMessage">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 21L12 3M12 3L7 8M12 3L17 8" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';

import CustomHeader from '../components/CustomHeader.vue';
import QuestionItem from '../components/QuestionItem.vue';
import vLoading from '../components/v-loading.vue';

import { useFaq } from '../stores/faq';
import { mapWritableState } from 'pinia';

import { useBreadcrumbs } from '../stores/breadcrumbs';

import { serverRequest } from '../api/request';

export default {
  name: 'SupportView',
  components: {
    CustomHeader,
    QuestionItem,
    vLoading
  },

  data() {
    return {
      isLoading: false,

      chatLoading: false,
      chatEnd: false,

      newMessage: '',

      updateChat: null,

      messages: [],

      manager: {
        email: "",
        name: "",
        tel: ""
      },

      questions: [
      ]
    }
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'support',
        name: 'Поддержка'
      },
    ]
    this.getManagerInfo()

    this.getChatHistory()

    this.updateChat = setInterval(() => {
      this.getChat()
    }, 10000)

    if (this.storeFaq == null) {
      this.getData()
    }
    else {
      this.questions = this.storeFaq
    }
  },

  beforeUnmount() {
    clearInterval(this.updateChat)
  },

  // watch: {
  //   messages() {
  //     this.chatScrollBottom()
  //   }
  // },

  computed: {
    ...mapWritableState(useFaq, { storeFaq: 'faq' }),
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),
  },

  methods: {
    chatScrollHandler() {
      if (this.$refs.historyWrapper.scrollTop < 50 && !this.chatLoading && !this.chatEnd) {
        this.chatLoading = true

        this.getChatHistory()
      }
    },

    async chatScrollBottom() {
      await nextTick()
      this.$refs.historyWrapper.scrollTop = this.$refs.historyWrapper.scrollHeight;
    },

    async getData() {
      this.isLoading = true

      let result = await serverRequest('GET', 'json', 'get_faq_questions')

      if (result != 'error' && result != 'unathorized') {
        this.storeFaq = result.questions
        this.questions = result.questions
      }
      else {
        //alert('Ошибка запроса')
      }

      this.isLoading = false
    },

    async sendMessage() {
      if (this.newMessage != '') {
        this.chatLoading = true

        let formData = {
          message: this.newMessage,
        }

        let result = await serverRequest('POST', 'json', 'chat/message', formData)
        if (result.status == 'ok') {
          let message = {
            author: 1,
            text: this.newMessage,
            id: result.result
          }
          this.messages.push(message)
          this.chatScrollBottom()
          this.newMessage = ''
        }

        this.chatLoading = false
      }
    },

    async getChat() {
      let result = await serverRequest('POST', 'json', 'chat/history')

      if (result != 'error' && result != 'unathorized') {
        this.messages = result.history.reverse();
        this.chatScrollBottom()
      }
    },

    async getChatHistory() {
      this.chatLoading = true

      let result = await serverRequest('POST', 'json', 'chat/history')

      if (result != 'error' && result != 'unathorized') {
        this.messages = result.history.reverse();
        this.chatScrollBottom()
      }

      this.chatLoading = false

      // if (position == 'start') {
      //   let formData = {
      //     last: this.messages[0].id,
      //   }

      //   let result = await serverRequest('POST', 'json', 'chat/history', formData)
      //   if (result.history.length > 0) {
      //     let newMessages = result.history.reverse()
      //     console.log(newMessages);
      //     this.messages = [...newMessages, ...this.messages]
      //     this.chatLoading = false
      //   }
      //   else {
      //     this.chatLoading = false
      //     this.chatEnd = true
      //   }
      // }

      // if (position == 'end') {
      //   let result = await serverRequest('POST', 'json', 'chat/history')

      //   let newMessages = result.history.reverse()

      //   console.log(this.messages[this.messages.length - 1].id);
      //   console.log(newMessages[newMessages.length - 1].id);

      //   if (this.messages[this.messages.length - 1].id != newMessages[newMessages.length - 1].id) {
      //     this.messages = newMessages;
      //     this.chatScrollBottom()
      //   }
      // }
    },

    async getManagerInfo() {
      let result = await serverRequest('POST', 'json', 'get_manager_info')

      if (result != 'error' && result != 'unathorized') {
        this.manager = result
      }
      else {
        //alert('Ошибка запроса')
      }
    }
  }
}
</script>

<style lang="scss">
.support-page {
  @extend %white-bg-block;
  width: 100%;

  display: flex;
  column-gap: 32px;

  .title {
    width: fit-content;

    &::after {
      width: 100%;
    }
  }

  >div {
    width: 100%;

    &.chat-block {
      max-width: 516px;
    }
  }

  .popular-questions-block {
    margin-top: 32px;

    padding: 40px 40px 64px;

    border-radius: 6px;
    border: 1px solid $bw3;

    h2 {
      @extend %H2;
      color: $bw0;
    }

    &__container {
      margin-top: 32px;

      display: flex;
      flex-direction: column;
      row-gap: 12px;

      .question-item:last-of-type {
        border-color: transparent;
      }
    }
  }

  .manager-info {
    margin-top: 24px;

    padding: 16px 24px;

    border: 1px solid $bw3;
    border-radius: 6px;

    h2 {
      @extend %H2;
      color: $bw0;
    }

    &__name {
      margin-top: 24px;

      @extend %text-medium;
      color: $bw0;
      font-size: 20px;
      line-height: 24px;
    }

    &__contacts {
      margin-top: 12px;

      &:nth-child(3) {
        margin-top: 24px;
      }

      @extend %text-regular;
      color: $bw1;

      a {
        margin-left: 12px;

        @extend %text-regular;
        color: $bw0;

        transition: color .3s;

        &:hover {
          color: var(--primary);
        }
      }
    }
  }

  .chat {
    margin-top: 32px;

    padding: 24px;

    border-radius: 6px;
    border: 1px solid $bw3;

    position: relative;

    &.loading {
      pointer-events: none;
    }

    .v-loading {
      // position: unset;
      // background-color: transparent;
    }

    &__history-wrapper {
      height: 380px;
      overflow-y: scroll;

      padding-right: 10px;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: $bw6;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $bw3;
        border-radius: 10px;
        border: none;
      }
    }

    &__history {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      row-gap: 24px;
      min-height: 100%;

      div {
        max-width: 360px;

        padding: 12px 16px;

        border-radius: 6px 6px 6px 0px;

        background-color: $bw4;

        @extend %text-regular;
        color: $bw0;

        &.outcome {
          border-radius: 6px 6px 0px 6px;

          background-color: var(--blue-bg);

          align-self: flex-end;
        }

        strong,
        b {
          font-weight: 700;
        }
      }
    }

    .end-line {
      margin-top: 24px;

      display: flex;
      column-gap: 12px;
      align-items: center;

      p {
        @extend %text-small;
        color: $bw1;
        white-space: nowrap;
      }

      span {
        content: "";

        width: 100%;
        height: 1px;

        background-color: $bw1;
      }
    }

    .feedback-block {
      margin-top: 24px;

      &__text {
        display: flex;
        column-gap: 24px;
        align-items: center;

        padding: 12px 16px;

        background-color: $bw4;

        svg {
          flex-shrink: 0;
        }

        p {
          @extend %text-regular;
          color: $bw0;
        }
      }

      &__buttons {
        margin-top: 12px;

        display: flex;
        column-gap: 12px;

        button {
          @extend %button;
          padding: 6px 12px;
        }
      }
    }

    &__new-message {
      margin-top: 32px;

      display: flex;
      column-gap: 12px;

      width: 100%;

      .input-container {
        position: relative;
        width: 100%;

        input {
          width: 100%;

          padding: 12px 44px 12px 12px;

          background-color: $bw4;

          border-radius: 6px;
          border: 1px solid $bw4;

          transition: border .3s;

          @extend %text-regular;
          color: $bw0;

          &::placeholder {
            @extend %text-regular;
            color: $bw1;
          }

          &:focus {
            border-color: $bw0;
          }
        }

        svg {
          position: absolute;

          cursor: pointer;

          top: 50%;
          transform: translateY(-50%);

          right: 16px;
        }
      }

      button {
        flex-shrink: 0;

        padding: 12px;

        background-color: var(--primary);

        border-radius: 4px;

        height: 50px;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .support-page {
    flex-direction: column;
    row-gap: 48px;

    >div.chat-block {
      max-width: 100%;
    }

    .popular-questions-block {
      padding: 24px;
    }


  }
}

@media screen and (max-width: 800px) {
  .support-page {
    .popular-questions-block {
      margin-top: 24px;

      padding: 0;

      border-radius: 0;
      border: none;
    }

    .manager-info {
      margin-top: 32px;
    }
  }
}
</style>