<template>
    <div class="category-item">
        <!-- <h4 class="category-item__header"
            @click="categoryChoosen(categoryItem.header, categoryItem.header, categoryItem.id)">{{ categoryItem.header
            }}
        </h4> -->
        <h4 class="category-item__header">{{ categoryItem.header
            }}
        </h4>
        <ul class="category-item__list">
            <li v-for="category in categoryItem.list">
                <button class="category-item__btn" type="button"
                    @click="categoryChoosen(categoryItem.header, category.name, category.id)">
                    {{ category.name }}
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'CategoryItem',

    props: {
        categoryItem: {
            type: Object,
            default() {
                return {
                    header: 'Название категории',
                    list: [
                        {
                            id: 'cat1',
                            name: 'Категория 1'
                        },
                        {
                            id: 'cat2',
                            name: 'Категория 2'
                        },
                        {
                            id: 'cat3',
                            name: 'Категория 3'
                        },
                    ]
                }
            }
        },
    },

    emits: ['category-choosen'],

    methods: {
        categoryChoosen(header, name, id) {
            this.$emit('category-choosen', header, name, id)
        }
    }
}
</script>

<style lang="scss">
.category-item {
    &__header {
        @extend %H2;
        color: var(--bw0);
        text-transform: uppercase;

        //cursor: pointer;
    }

    &__list {
        margin-top: 16px;

        li:not(:first-of-type) {
            margin-top: 4px;
        }
    }

    &__btn {
        @extend %text-regular;
        color: var(--bw1);

        display: block;

        background-color: transparent;

        position: relative;

        text-align: left;


        &::after {
            content: "";

            display: block;

            width: 0%;
            height: 1px;

            background-color: var(--bw1);

            transition: width .3s;

            position: absolute;

            left: 0;
            bottom: 2px;
        }

        &:hover::after {
            width: 100%;
        }
    }
}
</style>