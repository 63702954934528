<template>
  <section class="order-history-item">
    <div class="order-history-item__cell">
      <p class="order-history-item__cell__label">Дата</p>
      <p class="order-history-item__cell__value">{{ date }}</p>
    </div>
    <div class="order-history-item__cell">
      <p class="order-history-item__cell__label">Номер заявки</p>
      <p class="order-history-item__cell__value">{{ number }}</p>
    </div>
    <div class="order-history-item__cell">
      <p class="order-history-item__cell__label">Сумма</p>
      <p class="order-history-item__cell__value sum">
        {{ parseInt(sum).toLocaleString('ru-RU') }} ₽
      </p>
    </div>
    <div class="order-history-item__cell status">
      <p class="order-history-item__cell__label">Статус</p>
      <status-container
        class="order-history-item__cell__value"
        :statuses="status"
      ></status-container>
    </div>
    <div class="order-history-item__cell">
      <p class="order-history-item__cell__label">Автор заказа</p>
      <p class="order-history-item__cell__value">{{ author }}</p>
    </div>
    <div class="order-history-item__cell button">
      <router-link class="to-order-btn" :to="{ name: 'order-item', params: { id: number } }">
        <span>{{ getOrderText }}</span>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.5 12H21.5"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.5 7L21.5 12L16.5 17"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </router-link>
    </div>
  </section>
</template>

<script>
import StatusContainer from './StatusContainer.vue'

import { mapState, mapActions } from 'pinia'
import { useOrderStatuses } from '@/stores/orderStatuses'

export default {
  name: 'orderHistoryItem',

  components: {
    StatusContainer
  },

  props: {
    number: {
      type: Number,
      default() {
        return 0
      }
    },
    status: {
      type: Array,
      default() {
        return []
      }
    },
    date: {
      type: String,
      default() {
        return '01.01.2023'
      }
    },
    sum: {
      type: String,
      default() {
        return '0'
      }
    },
    author: {
      type: String,
      default() {
        return 'Автор'
      }
    }
  },
  data() {
    return {}
  },

  created() {},

  beforeUnmount() {},

  computed: {
    positionLenght() {
      return this.positions.length
    },
    getOrderText() {
      if (this.status.includes(0) || this.status.includes(1)) {
        return 'Оформить заказ'
      } else {
        return 'Перейти к заказу'
      }
    }
  },

  methods: {
    ...mapActions(useOrderStatuses, { storeGetStatusText: 'getStatusText' })
  }
}
</script>

<style lang="scss">
.order-history-item {
  display: flex;
  column-gap: 24px;
  align-items: flex-start;

  padding: 16px 24px;

  border-radius: 6px;
  border: 1px solid var(--bw3);

  &__cell {
    width: 16.6%;

    &__label {
      @extend %text-regular;
      color: var(--bw1);
    }

    &__value {
      margin-top: 8px;

      @extend %text-regular;
      color: var(--bw0);

      &.sum {
        font-weight: 600;
      }

      &.status-container {
        flex-direction: column;
        row-gap: 12px;
        align-items: flex-start;
      }
    }

    &.status {
      width: 21%;
    }

    &.button {
      width: 220px;
      margin-left: auto;
    }
  }

  .to-order-btn {
    @extend %button;
    display: flex;
    column-gap: 12px;
    align-items: center;
    justify-content: center;

    white-space: nowrap;

    svg {
      flex-shrink: 0;
    }
  }
}

@media screen and (max-width: 1600px) {
  .order-history-item {
    padding: 16px 20px;

    &__cell {
      &.button {
        width: 44px;
      }
    }
  }

  .to-order-btn {
    span {
      display: none;
    }
  }
}

@media screen and (max-width: 800px) {
  .order-history-item {
    padding: 16px 24px;

    flex-direction: column;
    row-gap: 24px;
    align-items: flex-start;
  }

  .order-history-item {
    &__cell {
      &.button {
        width: fit-content;
      }
    }
  }

  .to-order-btn {
    span {
      display: block;
    }
  }
}

@media screen and (max-width: 600px) {
  .order-history-item {
    &__cell {
      &.button {
        width: 100%;
      }
    }
  }
}
</style>
