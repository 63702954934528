<template>
  <app-header :is-login="false" @show-menu="menuOpen = true"></app-header>
  <div class="menu-bg" :class="{ 'showed': menuOpen, 'not-showed': opened }"></div>
  <div class="wrapper" :class="{ 'gray-bg': $route.name == 'home' }">
    <app-breadcrumb :links="storeBreadcrumbs"></app-breadcrumb>
    <main class="main-page main-container">
      <side-menu :menu-open="menuOpen" @close-menu="menuOpen = false"></side-menu>
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </main>
    <notification-container></notification-container>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader.vue';
import SideMenu from '../components/SideMenu.vue';
import AppBreadcrumb from '../components/AppBreadcrumb.vue';
import NotificationContainer from '../components/notifications/NotificationContainer.vue';

import { serverRequest } from '../api/request';

import { mapWritableState, mapState } from 'pinia'
import { useBreadcrumbs } from '../stores/breadcrumbs';
import { useUserToken } from '@/stores/userToken'
import { useTempStates } from '@/stores/tempStates';

export default {
  name: 'MainView',
  components: {
    AppHeader,
    SideMenu,
    AppBreadcrumb,
    NotificationContainer
  },

  data() {
    return {
      menuOpen: false,
      opened: false
    }
  },

  watch: {
    menuOpen() {
      this.opened = true
    }
  },

  beforeMount() {
    console.log(this.$route.name);
    this.getStatuses()
  },

  computed: {
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),
    ...mapWritableState(useUserToken, { storeFirstProfileEnter: 'firstProfileEnter' }),
    ...mapWritableState(useTempStates, { storeChangeTerms: 'changeTermsRequest' }),
    ...mapWritableState(useTempStates, { storeChangePricelist: 'changePricelistRequest' }),
  },

  methods: {
    async getStatuses() {
      let result = await serverRequest('GET', 'json', 'profile/get_user_request_statuses')

      if (result != 'error' && result != 'unathorized') {
        this.storeChangeTerms = result.change_terms
        this.storeChangePricelist = result.change_pricelist
      }
    },
  }
}
</script>

<style lang="scss">
.main-container {
  margin-top: 16px;

  display: flex;
  column-gap: 24px;

  min-width: 0;

  padding-bottom: 40px;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .3s;
  }

  .fade-enter-to,
  .fade-leave-from {
    opacity: 1;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}

@media screen and (max-width: 800px) {
  .wrapper.gray-bg {
    padding-top: 16px;
    background-color: $bw4;
  }
}

.menu-bg {
  display: block;

  content: "";

  position: fixed;

  width: 100%;
  height: 100%;

  left: 0;
  top: 0;

  visibility: collapse;

  transform: translateX(0);

  background-color: rgba(0, 0, 0, 0.40);

  z-index: 4;

  opacity: 0;

  transition: opacity .4s;

  &.not-showed {
    animation-duration: 0.5s;
    animation-name: fadeIn;
  }

  &.showed {
    animation-duration: 0.5s;
    animation-name: fadeOut;

    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    visibility: collapse;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    visibility: visible;
    opacity: 1;
  }

  to {
    visibility: collapse;
    opacity: 0;

  }
}
</style>
