<template>
    <div class="filter-item lines" :class="[{ 'show': isOpen, 'price': content.type == 1 }]">
        <template
            v-if="content.id != 'price' && content.id != 'vCode' && content.id != 'images' && content.options.length > 0">
            <div class="filter-item__header" @click="isOpen = !isOpen">
                <p class="filter-item__name">{{ content.header }}</p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 11L12 16L17 11" stroke="#D93030" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </div>
            <Transition>
                <ul class="filter-item__list" v-show="isOpen">
                    <template v-if="content.type == 0">
                        <li v-for=" parameter  in  content.options ">
                            <v-checkbox :checkbox-id="content.id + parameter"
                                :checkbox-label="getLabel(parameter, content.type)" :checkbox-name="content.id"
                                :checkbox-value="getLabel(parameter, content.type)" :align="'top'"
                                @changed="(id, status) => onFilterChanged(status, content.id, parameter)"></v-checkbox>
                        </li>
                    </template>
                    <template v-if="content.type == 1">
                        <input type="number" name="price-in" v-model="startValue" :placeholder="getStartLabel"
                            @input="valueInputHandler">
                        <p>-</p>
                        <input type="number" name="price-out" v-model="endValue" @input="valueInputHandler"
                            @blur="endInputHandler" :placeholder="getEndLabel" :class="{ 'error': isValueError }">
                    </template>
                </ul>
            </Transition>
        </template>
    </div>
</template>

<script>
import vCheckbox from './v-checkbox.vue';
import debounce from 'lodash.debounce'

export default {
    name: 'CatalogFilter',

    components: {
        vCheckbox,
    },

    props: {
        content: {
            type: Object,
            default() {
                return {
                    id: 0,
                    header: '',
                    type: 0,
                    options: []
                }
            }
        },
    },
    emits: ['changed'],
    data() {
        return {
            isOpen: false,
            startValue: null,
            endValue: null,
            valueUpdate: debounce(() => {
                if (!this.isValueError) {
                    this.onInputChanged()
                }
            }, 300)
        }
    },

    computed: {
        getStartLabel() {
            if (this.content.options[0] == 0) {
                return "от"
            }
            else {
                return "от " + this.content.options[0]
            }
        },

        getEndLabel() {
            if (this.content.options[1] == 0) {
                return "до"
            }
            else {
                return "до " + this.content.options[1]
            }
        },

        getStartValue() {
            if (this.startValue == null || this.startValue == '') {
                if (this.content.options[0] != null) {
                    return this.content.options[0]
                }
                else {
                    return 0
                }
            }
            else {
                return this.startValue
            }
        },

        getEndValue() {
            if (this.endValue == null || this.endValue == '') {
                return this.content.options[1]
            }
            else {
                return this.endValue
            }
        },

        isValueError() {
            if (this.startValue > this.endValue && this.endValue != null && this.endValue != '') {
                return true
            }
            else {
                return false
            }
        }
    },

    methods: {
        reset() {
            this.isOpen = false
            this.startValue = null
            this.endValue = null
        },
        getLabel(label, type) {
            if (typeof (label) == 'string') {
                return label
            }
            else {
                if (label == true) {
                    return 'Да'
                }
                if (label == false) {
                    return 'Нет'
                }
            }
        },
        valueInputHandler() {
            this.valueUpdate()
        },
        endInputHandler() {
            if (this.isValueError) {
                this.endValue = null
            }
        },
        onInputChanged() {
            let value = [this.getStartValue, this.getEndValue]
            if ((this.startValue == null || this.startValue == '') && (this.endValue == null || this.endValue == '')) {
                this.$emit("changed", false, this.content.id, value, this.content.type)
            }
            else {
                this.$emit("changed", true, this.content.id, value, this.content.type)
            }
        },
        onFilterChanged(status, id, value) {
            this.$emit("changed", status, id, value, this.content.type)
        },
    }

}
</script>

<style lang="scss"></style>