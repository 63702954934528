import { createRouter, createWebHistory } from 'vue-router'
import MainView from '../views/MainView.vue'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import NewCatalogView from '../views/catalog/NewCatalogView.vue'
import CategoriesView from '../views/catalog/CategoriesView.vue'
import GoodsView from '../views/catalog/GoodsView.vue'
import SearchGoodsView from '../views/catalog/SearchGoodsView.vue'
import SpecificationView from '../views/catalog/SpecificationView.vue'
import ProfileView from '../views/ProfileView.vue'
import ProductView from '../views/ProductView.vue'
import CartView from '../views/CartView.vue'
import EmptyView from '../views/EmptyView.vue'
import OrderView from '../views/OrderView.vue'
import OrderHistoryView from '../views/OrderHistoryView.vue'
import OrderItemView from '../views/OrderItemView.vue'
import AnaliticsView from '../views/AnaliticsView.vue'
import DocumentsView from '../views/DocumentsView.vue'
import KnowledgeView from '../views/KnowledgeView.vue'
import ArticleView from '../views/ArticleView.vue'
import MeetingView from '../views/MeetingView.vue'
import MarketingView from '../views/MarketingView.vue'
import MarketingPagesView from '../views/MarketingPagesView.vue'
import PaymentsView from '../views/PaymentsView.vue'
import QuestionsView from '../views/QuestionsView.vue'
import SpecialOrderView from '../views/SpecialOrderView.vue'
import SupportView from '../views/SupportView.vue'

import { useUserToken } from '@/stores/userToken'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      meta: {
        title: 'B2B - Вход',
        metaTags: [
          {
            name: 'description',
            content: 'Вход.'
          },
          {
            property: 'og:description',
            content: 'Вход.'
          }
        ]
      }
    },
    {
      path: '/',
      name: 'main',
      component: MainView,
      meta: {
        title: 'B2B - Личный кабинет',
        metaTags: [
          {
            name: 'description',
            content: 'Личный кабинет.'
          },
          {
            property: 'og:description',
            content: 'Личный кабинет.'
          }
        ]
      },
      children: [
        {
          path: '',
          component: HomeView,
          name: 'home'
        },
        {
          path: '/knowledge',
          name: 'knowledge',
          component: KnowledgeView,
          meta: {
            title: 'B2B - База знаний',
            metaTags: [
              {
                name: 'description',
                content: 'База знаний.'
              },
              {
                property: 'og:description',
                content: 'База знаний.'
              }
            ]
          }
        },
        {
          path: '/catalog',
          name: 'catalog',
          component: NewCatalogView,
          children: [
            {
              path: '',
              component: CategoriesView,
              name: 'categories'
            },
            {
              path: 'goods/:categoryId',
              component: GoodsView,
              name: 'goods',
              props: true
            },
            {
              path: 'specifications',
              component: SpecificationView,
              name: 'specifications'
            },
            {
              path: 'search_goods',
              name: 'search-goods',
              component: SearchGoodsView,
              meta: {
                title: 'B2B - Поиск',
                metaTags: [
                  {
                    name: 'description',
                    content: 'Поиск.'
                  },
                  {
                    property: 'og:description',
                    content: 'Поиск.'
                  }
                ]
              }
            }
          ],
          meta: {
            title: 'B2B - Каталог',
            metaTags: [
              {
                name: 'description',
                content: 'Каталог.'
              },
              {
                property: 'og:description',
                content: 'Каталог.'
              }
            ]
          }
        },
        {
          path: '/product/:id',
          name: 'product',
          component: ProductView,
          props: true,
          meta: {
            title: 'B2B - Страница товара',
            metaTags: [
              {
                name: 'description',
                content: 'Страница товара.'
              },
              {
                property: 'og:description',
                content: 'Страница товара.'
              }
            ]
          }
        },
        {
          path: '/cart',
          name: 'cart',
          component: CartView,
          meta: {
            title: 'B2B - Корзина',
            metaTags: [
              {
                name: 'description',
                content: 'Корзина.'
              },
              {
                property: 'og:description',
                content: 'Корзина.'
              }
            ]
          }
        },
        {
          path: '/order',
          name: 'order',
          component: OrderView,
          meta: {
            title: 'B2B - Оформление заказа',
            metaTags: [
              {
                name: 'description',
                content: 'Оформление заказа.'
              },
              {
                property: 'og:description',
                content: 'Оформление заказа.'
              }
            ]
          }
        },
        {
          path: '/order-history',
          name: 'order-history',
          component: OrderHistoryView,
          meta: {
            title: 'B2B - Заказы',
            metaTags: [
              {
                name: 'description',
                content: 'Заказы.'
              },
              {
                property: 'og:description',
                content: 'Заказы.'
              }
            ]
          }
        },
        {
          path: '/order-item/:id',
          name: 'order-item',
          component: OrderItemView,
          props: true,
          meta: {
            title: 'B2B - Заказ',
            metaTags: [
              {
                name: 'description',
                content: 'Заказ.'
              },
              {
                property: 'og:description',
                content: 'Заказ.'
              }
            ]
          }
        },
        {
          path: '/profile',
          name: 'profile',
          component: ProfileView,
          meta: {
            title: 'B2B - Профиль',
            metaTags: [
              {
                name: 'description',
                content: 'Профиль.'
              },
              {
                property: 'og:description',
                content: 'Профиль.'
              }
            ]
          }
        },
        {
          path: '/analitics',
          name: 'analitics',
          component: AnaliticsView,
          meta: {
            title: 'B2B - Аналитика',
            metaTags: [
              {
                name: 'description',
                content: 'Аналитика.'
              },
              {
                property: 'og:description',
                content: 'Аналитика.'
              }
            ]
          }
        },
        {
          path: '/meeting/:id',
          name: 'meeting',
          component: MeetingView,
          props: true,
          meta: {
            title: 'B2B - Отчёт о встрече',
            metaTags: [
              {
                name: 'description',
                content: 'Отчёт о встрече.'
              },
              {
                property: 'og:description',
                content: 'Отчёт о встрече.'
              }
            ]
          }
        },
        {
          path: '/documents',
          name: 'documents',
          component: DocumentsView,
          meta: {
            title: 'B2B - Документы',
            metaTags: [
              {
                name: 'description',
                content: 'Документы.'
              },
              {
                property: 'og:description',
                content: 'Документы.'
              }
            ]
          }
        },
        {
          path: '/article/:id',
          name: 'article',
          component: ArticleView,
          props: true,
          meta: {
            title: 'B2B - Статья',
            metaTags: [
              {
                name: 'description',
                content: 'Статья.'
              },
              {
                property: 'og:description',
                content: 'Статья.'
              }
            ]
          }
        },
        {
          path: '/marketing',
          name: 'marketing',
          component: MarketingView,
          children: [
            {
              path: ':page?',
              component: MarketingPagesView,
              name: 'marketingPages',
              props: true
            }
          ],
          meta: {
            title: 'B2B - Маркетинг',
            metaTags: [
              {
                name: 'description',
                content: 'Маркетинг.'
              },
              {
                property: 'og:description',
                content: 'Маркетинг.'
              }
            ]
          }
        },
        {
          path: '/payments',
          name: 'payments',
          component: PaymentsView,
          meta: {
            title: 'B2B - Платежи',
            metaTags: [
              {
                name: 'description',
                content: 'Платежи.'
              },
              {
                property: 'og:description',
                content: 'Платежи.'
              }
            ]
          }
        },
        {
          path: '/questions',
          name: 'questions',
          component: QuestionsView,
          meta: {
            title: 'B2B - Вопросы и предложения',
            metaTags: [
              {
                name: 'description',
                content: 'Вопросы и предложения.'
              },
              {
                property: 'og:description',
                content: 'Вопросы и предложения.'
              }
            ]
          }
        },
        {
          path: '/special-order',
          name: 'special-order',
          component: SpecialOrderView,
          meta: {
            title: 'B2B - Специальный заказ',
            metaTags: [
              {
                name: 'description',
                content: 'Специальный заказ.'
              },
              {
                property: 'og:description',
                content: 'Специальный заказ.'
              }
            ]
          }
        },
        {
          path: '/support',
          name: 'support',
          component: SupportView,
          meta: {
            title: 'B2B - Поддержка',
            metaTags: [
              {
                name: 'description',
                content: 'Поддержка.'
              },
              {
                property: 'og:description',
                content: 'Поддержка.'
              }
            ]
          }
        },
        {
          path: '/empty',
          name: 'empty',
          component: EmptyView,
          meta: {
            title: 'B2B - Пустая страница',
            metaTags: [
              {
                name: 'description',
                content: 'Пустая страница.'
              },
              {
                property: 'og:description',
                content: 'Пустая страница.'
              }
            ]
          }
        },
        {
          path: '/:pathMatch(.*)*',
          name: 'NotFound',
          component: EmptyView,
          meta: {
            title: 'B2B — Страница не найдена',
            metaTags: [
              {
                name: 'description',
                content: 'Страница не найдена.'
              },
              {
                property: 'og:title',
                content: 'Страница не найдена'
              },
              {
                property: 'og:description',
                content: 'Страница не найдена.'
              }
            ]
          }
        }
      ]
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    // return { top: 0 }
    return {
      top: 0,
      left: 0,
      behavior: 'smooth'
    }
  }
})

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) =>
    el.parentNode.removeChild(el)
  )

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag))

  next()
})

router.beforeEach((to) => {
  const storeToken = useUserToken()

  if ((storeToken.token === '' || storeToken.token === undefined) && to.name != 'login')
    return '/login'
})

export default router
