<template>
    <tr class="order-row" :class="{ deleted: deleted, deficit: !isAvailable }">
        <td class="name">{{ name }}</td>
        <td class="articul">{{ articul }}</td>
        <td class="amount">
            <amount-block :amount="amount" @amountChange="amountChange"></amount-block>
        </td>
        <td class="unit">{{ unit }}</td>
        <td class="price">{{ parseInt(getPrice).toLocaleString('ru-RU') }} ₽</td>
        <td class="full-price">{{ parseInt(getFullPrice).toLocaleString('ru-RU') }} ₽</td>
        <td class="availability">({{ getAviability }}/{{ amount }})</td>
        <td>{{ supply }}</td>
        <td class="delete">
            <button class="order-row__delete-button" @click="deleteRow()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                        stroke="#D93030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M15 9L9 15" stroke="#D93030" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M9 9L15 15" stroke="#D93030" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </button>
        </td>
        <td class="delete-status" colspan="9">
            <p>Вы удалили товар из заказа. <button class="delete-status__btn"
                    @click="recoveryRow">Восстановить.</button>
            </p>
        </td>
    </tr>
</template>

<script>
import AmountBlock from './AmountBlock.vue'
import { nextTick } from 'vue';

export default {
    name: 'OrderRow',

    components: {
        AmountBlock
    },

    emits: ['orderItemDeleted', 'amountChange', 'orderItemRecovery', 'orderItemDeficit', 'deficitAmountChange'],

    props: {
        id: {
            type: Number,
            default() {
                return 0
            }
        },
        name: {
            type: String,
            default() {
                return "Номенклатура"
            }
        },
        articul: {
            type: String,
            default() {
                return 'Артикул'
            }
        },
        price: {
            type: String,
            default() {
                return '0'
            }
        },
        availability: {
            type: Number,
            default() {
                return 1
            }
        },
        amount: {
            type: Number,
            default() {
                return 0
            }
        },
        unit: {
            type: String,
            default() {
                return 'шт'
            }
        },
        supply: {
            type: String,
            default() {
                return '20/01/2020'
            }
        },
        deleted: {
            type: Boolean,
            default() {
                return false
            }
        }
    },

    data() {
        return {
            deficit: false
        }
    },

    mounted() {
        this.checkAvailable(this.amount)
    },

    computed: {
        getPrice() {
            return Math.round(parseInt(this.price))
        },

        getFullPrice() {
            return this.getPrice * this.amount
        },

        getAviability() {
            if (this.amount <= this.availability) {
                return this.amount
            }
            else {
                return this.availability
            }
        },

        isAvailable() {
            if (this.amount <= this.availability) {
                return true
            }
            else {
                return false
            }
        }

    },

    methods: {
        amountChange(amount) {
            this.$emit('amountChange', this.id, amount)
            this.checkAvailable(amount)
        },

        checkAvailable(amount) {
            console.log('deficit', this.deficit);
            console.log('deleted', this.deleted);

            if (amount > this.availability && this.deleted != true) {
                if (this.deficit == false) {
                    this.deficit = true
                    console.log('deficit event');

                    this.$emit('orderItemDeficit', this.id, true)
                }
                else {
                    this.$emit('deficitAmountChange', this.id, amount)
                }
            }
            else {
                if (this.deficit) {
                    this.deficit = false
                    this.$emit('orderItemDeficit', this.id, false)
                }
            }
        },

        async deleteRow() {
            this.$emit('orderItemDeleted', this.id)

            await nextTick()

            this.checkAvailable(this.amount)
        },

        async recoveryRow() {
            this.$emit('orderItemRecovery', this.id)

            await nextTick()

            this.checkAvailable(this.amount)
        }
    }
}
</script>

<style lang="scss">
.order-row {
    td {
        @extend %text-regular;
        color: var(--bw0);
        padding: 6px 8px;

        border-bottom: 1px solid var(--bw3);

        &.name {
            overflow: hidden;
            text-overflow: ellipsis;
            // display: -moz-box;
            // -moz-box-orient: vertical;
            // display: -webkit-box;
            // -webkit-line-clamp: 1;
            // -webkit-box-orient: vertical;
            // line-clamp: 1;
            // box-orient: vertical;

            max-width: 340px;

            white-space: nowrap;

            padding-left: 8px;
            padding-right: 24px;
        }

        &.articul {
            padding-left: 26px;
        }

        &.amount {
            padding: 6px 6px 6px 32px;
        }

        &.unit {
            padding: 6px;
            text-align: center;
            user-select: none;
        }

        &.price {
            padding: 6px;

            text-align: center;

            user-select: none;
        }

        &.full-price {
            text-align: center;
            user-select: none;
        }

        &.availability {
            text-align: center;
        }

        &.delete {
            padding: 12px;
        }
    }

    .amount-block {
        border: 1px solid $bw3;
    }

    &__delete-button {
        display: block;

        background-color: transparent;
    }

    .delete-status {
        display: none;
    }

    &.deficit {
        td {
            background-color: var(--primary-bg);

            &.availability {
                color: var(--primary);
            }
        }
    }

    &.deleted {
        td {
            display: none;
        }

        .delete-status {
            display: table-cell;

            background-color: var(--bw4);

            text-align: center;
            color: var(--bw1);

            padding: 13.5px;


            &__btn {
                @extend %text-regular;
                background-color: transparent;
                color: var(--blue);
            }
        }
    }
}


@media screen and (max-width: 1600px) {
    .order-row {
        td {
            padding: 6px 8px;


            &.name {
                overflow: hidden;
                text-overflow: ellipsis;

                max-width: 240px;

                white-space: nowrap;

                padding-left: 8px;
                padding-right: 24px;
            }

            &.articul {
                padding-left: 12px;
            }

            &.amount {
                padding: 6px;

                .amount-block {
                    margin: 0 auto;
                }
            }

            &.unit {
                padding: 6px;
                text-align: center;
                user-select: none;
            }

            &.price {
                padding: 6px;

                text-align: center;

                user-select: none;
            }

            &.full-price {
                text-align: center;
                user-select: none;
            }

            &.availability {
                text-align: center;
            }

            &.delete {
                padding: 12px;
            }
        }

        &.deleted {
            .delete-status {
                padding: 13.5px;
            }
        }
    }
}
</style>