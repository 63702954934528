<template>
    <section class="promo-materials-block">
        <div class="grid-images" :class="{ 'loading': isLoading }">
            <v-loading :is-loading="isLoading" :color="'white'"></v-loading>
            <img v-if="firstBanner != null" class="big" :src="firstBanner.banner" :alt="firstBanner.title">
            <div v-else class="big placeholder"></div>
            <img v-if="secondBanner != null" :src="secondBanner.banner" :alt="secondBanner.title">
            <div v-else class="placeholder"></div>
            <img v-if="thirdBanner != null" :src="thirdBanner.banner" :alt="thirdBanner.title">
            <div v-else class="placeholder"></div>
        </div>
        <!-- <button class="promo-materials-block__btn">
            <span>Дополнительное промо</span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 12H21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16 7L21 12L16 17" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </button> -->
        <div class="promo-materials-block__all-promo">
            <h2>Все промо-материалы</h2>
            <div class="promo-materials-block__all-promo__container" :class="{ 'loading': isLoading }">
                <v-loading :is-loading="isLoading" :color="'white'"></v-loading>
                <template v-if="allMaterials.length > 0">
                    <div v-for="material in allMaterials" class="promo-item">
                        <img class="promo-item__img" :src="material.banner" :alt="material.title">
                        <a class="promo-item__name" :href="material.file_link" download target="_blank">{{ material.file_name }}</a>
                    </div>
                </template>
                <p class="promo-materials-block__all-promo__text" v-else>Промо-материалы не найдены</p>
            </div>
        </div>
    </section>
</template>

<script>
import { usePromoMaterials } from '../../stores/promoMaterials';
import { mapWritableState } from 'pinia';

import vLoading from '../v-loading.vue';

import { serverRequest } from '../../api/request';

export default {
    name: 'PromoMaterials',

    components: {
        vLoading,
    },

    props: {
        userToken: {
            type: String,
            default() {
                return ''
            }
        },
    },

    data() {
        return {
            materials: null,
            allMaterials: [],
            isLoading: false,
            firstBanner: null,
            secondBanner: null,
            thirdBanner: null,
        }
    },

    beforeMount() {
        this.getMaterials()
    },

    computed: {
        ...mapWritableState(usePromoMaterials, { storeMaterials: 'materials' }),
    },

    methods: {
        bannerHandler() {
            for (let material of this.materials) {
                if (material.order == 0) {
                    this.allMaterials.push(material)
                    continue;
                }
                if (material.order == 1) {
                    this.firstBanner = material
                    continue;
                }
                if (material.order == 2) {
                    this.secondBanner = material
                    continue;
                }
                if (material.order == 3) {
                    this.thirdBanner = material
                    continue;
                }
            }
        },

        pageChange(page) {
            this.currentPage = page

            this.getMaterials()
        },

        async getMaterials() {
            this.isLoading = true;

            if (this.storeMaterials == null) {
                let result = await serverRequest('GET', 'json', 'marketing/promo')

                if (result != 'error' && result != 'unathorized') {
                    this.storeMaterials = result;
                    this.materials = result;
                }
                else {
                    //alert('Ошибка запроса')
                }
            }
            else {
                this.materials = this.storeMaterials
            }

            this.bannerHandler();

            this.isLoading = false
        }
    }
}
</script>

<style lang="scss">
.promo-materials-block {
    &__btn {
        margin-top: 48px;

        @extend %button;
        display: flex;
        column-gap: 12px;

        width: fit-content;
    }

    &__all-promo {
        margin-top: 48px;

        h2 {
            @extend %text-medium;
            color: $bw0;
        }

        &__container {
            margin-top: 32px;

            display: grid;
            grid-template-columns: repeat(5, 1fr);
            row-gap: 24px;
            column-gap: 12px;

            position: relative;

            &.loading {
                pointer-events: none;
            }
        }

        &__text {
            @extend %text-regular;
        }
    }

    .promo-item {
        display: flex;
        flex-direction: column;

        &__img {
            width: 180px;
            height: 180px;
            border-radius: 4px;
            object-fit: cover;
            align-self: center;
        }

        &__name {
            margin-top: 12px;

            @extend %text-regular;
            color: var(--blue);
            text-align: center;
        }
    }
}

.grid-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    row-gap: 24px;
    grid-auto-rows: 340px;

    position: relative;

    &.loading {
        pointer-events: none;
    }

    img,
    div {
        width: 100%;
        height: 100%;

        border-radius: 6px;

        object-fit: cover;

        &.big {
            grid-column: span 2;
        }

        &.placeholder {
            background-color: $bw4;
        }
    }
}

@media screen and (max-width: 1600px) {
    .grid-images {
        grid-auto-rows: 246px;
        column-gap: 16px;
        row-gap: 16px;
    }
}

@media screen and (max-width: 1300px) {
    .grid-images {
        grid-auto-rows: 220px;
    }

    .promo-materials-block {
        &__all-promo {
            margin-top: 48px;

            &__container {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .grid-images {
        grid-auto-rows: 174px;
    }

    .promo-materials-block {
        &__all-promo {
            &__container {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .grid-images {
        grid-template-columns: 1fr;
        grid-template-rows: 82px 174px 174px;
        row-gap: 12px;

        img {
            &.big {
                grid-column: span 1;
            }
        }
    }

    .promo-materials-block {
        &__all-promo {
            &__container {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        .promo-item {
            &__img {
                width: 120px;
                height: 120px;
            }
        }
    }
}
</style>