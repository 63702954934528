<template>
  <div class="profile-page" :class="{ 'loading': isLoading }">
    <v-loading :is-loading="isLoading" :circle-position="'top'" :color="'white'"></v-loading>
    <component v-bind:is="activeBlock" :user-token="userToken" :isProfileRegistration="isProfileRegistration"
      @changeRegStatus="onRegStatusChange" :reg-status="regStatus" :company-name="storeCompanyName"
      @name-change="onNameChange" @set-loading="onSetLoading">
    </component>
  </div>
</template>

<script>
import vLoading from '../components/v-loading.vue';
import RegisterBlock from '../components/profile/RegisterBlock.vue';
import ProfileBlock from '../components/profile/ProfileBlock.vue';

import { mapState, mapWritableState } from 'pinia'
import { useUserToken } from '@/stores/userToken'
import { useBreadcrumbs } from '../stores/breadcrumbs';

import { serverRequest } from '../api/request';

export default {
  name: 'ProfileView',
  components: {
    RegisterBlock,
    ProfileBlock,
    vLoading
  },

  data() {
    return {
      isProfileRegistration: false,
      activeBlock: 'RegisterBlock',
      isLoading: true
    }
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'profile',
        name: 'Профиль'
      },
    ]

    this.getProfileStatus()

    if (this.storeFirstProfileEnter == true) {
      this.isProfileRegistration = true
      this.storeFirstProfileEnter = false
    }
    else {
      this.isProfileRegistration = false
    }
  },

  computed: {
    ...mapState(useUserToken, { userToken: 'token' }),
    ...mapWritableState(useUserToken, { regStatus: 'regStatus' }),
    ...mapWritableState(useUserToken, { storeFirstProfileEnter: 'firstProfileEnter' }),
    ...mapWritableState(useUserToken, { storeCompanyName: 'companyName' }),
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),
  },

  methods: {
    onSetLoading(status) {
      this.isLoading = status
    },
    onRegStatusChange(status) {
      this.regStatus = status
    },
    onNameChange(name) {
      this.storeCompanyName = name
    },
    async getProfileStatus() {
      let result = await serverRequest('GET', 'json', 'get_company_reg_status')

      if (result != 'error' && result != 'unathorized') {
        this.regStatus = result

        if (this.regStatus == 2 || this.regStatus == 0) {
          this.activeBlock = 'RegisterBlock'
        }
        else {
          this.activeBlock = 'ProfileBlock'
        }
      }
      else {
        //alert('Ошибка запроса')
      }
    }
  }
}
</script>

<style lang="scss">
.profile-page {

  width: 100%;

  @extend %white-bg-block;


  position: relative;
  width: 100%;

  &.loading {
    pointer-events: none;
  }

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__general-information-wrapper {
    width: 100%;
  }

  &__general-information {
    max-width: 520px;
    width: 100%;
  }

  &__other-information {
    margin-top: 48px;

    max-width: 800px;

    h3 {
      margin-top: 48px;

      @extend %H2;
      color: var(--bw0);
    }

    .inputs-container {
      display: flex;
      column-gap: 40px;
    }
  }

  .profile-status-block-wrapper {
    width: 100%;
    max-width: 400px;
    position: relative;
  }

  .profile-status-block {
    position: sticky;
    top: 40px;
  }

  .tooltip-label {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .input-container {
    @extend %input-container;
    margin-top: 24px;

    &.input-360 {
      max-width: 360px;
    }

    &.input-380 {
      max-width: 380px;
    }

    label {
      white-space: nowrap;
    }

    &.error {
      input {
        border-color: var(--error);
      }

      .input-errors {
        bottom: -20px;
        opacity: 1;
      }

    }
  }

  .mt32 {
    margin-top: 32px;
  }

  .mt12 {
    margin-top: 12px;
  }

  .select {
    margin-top: 24px;
  }

  input.disabled-input {
    color: var(--bw1);
  }

  .bank-info {
    margin-top: 48px;
  }
}

.profile-status-block {
  max-width: 400px;
  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 16px;

  &__content {
    width: 100%;

    padding: 16px 24px;

    border-radius: 6px;
    background-color: var(--bw4);
  }

  &__name {
    @extend %H2;
    color: var(--bw0);

    //width: 400px;
  }

  &__status {
    margin-top: 24px;

    @extend %text-regular;
    color: var(--bw0);

    span {
      padding: 1px 8px;

      @extend %text-regular;
      line-height: 24px;

      color: #035AA6;

      border-radius: 2px;
      border: 1px solid #035AA6;
      background-color: #E6F3FF;

      margin-left: 12px;
    }
  }

  &__button {
    @extend %button;

    margin-top: 12px;

    width: 100%;

    &:first-of-type {
      margin-top: 24px;
    }

    &.gray {
      background-color: var(--bw1);
    }
  }

  &__warning {
    margin-top: 16px;

    padding: 12px 16px;

    display: flex;
    align-items: center;
    column-gap: 12px;

    border-radius: 4px;
    background: var(--bw4);

    svg {
      flex-shrink: 0;
    }

    p {
      @extend %text-regular;
      color: var(--bw0);
    }
  }
}

@media screen and (max-width: 800px) {}

@media screen and (max-width: 800px) {
  .profile-page {
    .profile-status-block-wrapper {
      width: 100%;
      max-width: 100%;
      position: relative;
    }

    .profile-status-block {
      position: static;
      top: 0;
      max-width: 100%;
    }
  }
}
</style>
