<template>
    <section class="promo-offer-block">
        <div class="grid-images" :class="{ 'loading': isLoading }">
            <v-loading :is-loading="isLoading" :color="'white'"></v-loading>
            <img v-if="firstBanner != null" class="big" :src="firstBanner.banner" :alt="firstBanner.title">
            <div v-else class="big placeholder"></div>
            <img v-if="secondBanner != null" :src="secondBanner.banner" :alt="secondBanner.title">
            <div v-else class="placeholder"></div>
            <img v-if="thirdBanner != null" :src="thirdBanner.banner" :alt="thirdBanner.title">
            <div v-else class="placeholder"></div>
        </div>
        <template v-if="popularGoods.length > 0">
            <custom-header class="goods-title" :title="'Популярые позиции'"></custom-header>
            <div class="good-container">
                <goods-slider :goods="popularGoods" @addedToCart="(id) => onAddedToCart(popularGoods, id)"
                    @amount-change="(id, amount) => onAmountChange(popularGoods, id, amount)"></goods-slider>
            </div>
        </template>
        <template v-if="onsaleGoods.length > 0">
            <custom-header class="goods-title" :title="'Акционные товары'"></custom-header>
            <div class="good-container">
                <goods-slider :goods="onsaleGoods" @addedToCart="(id) => onAddedToCart(onsaleGoods, id)"
                    @amount-change="(id, amount) => onAmountChange(onsaleGoods, id, amount)"></goods-slider>
            </div>
        </template>
    </section>
</template>

<script>
import ProductCard from '../ProductCard.vue';
import CustomHeader from '../CustomHeader.vue';
import vLoading from '../v-loading.vue';
import GoodsSlider from '../GoodsSlider.vue';

import { mapState, mapWritableState } from 'pinia'
import { useAddedGoods } from '@/stores/addedGoods'
import { usePromoMaterials } from '../../stores/promoMaterials';
import { useUserToken } from '@/stores/userToken'

import { serverRequest } from '../../api/request';

export default {
    name: 'PromoOffer',

    components: {
        ProductCard,
        CustomHeader,
        vLoading,
        GoodsSlider
    },

    data() {
        return {
            isLoading: false,
            popularGoods: [],
            onsaleGoods: [],
            offers: [],
            firstBanner: null,
            secondBanner: null,
            thirdBanner: null
        }
    },

    beforeMount() {
        this.getPopularGoods()
        this.getOnsaleGoods()
        this.getOffers()
    },

    computed: {
        ...mapState(useUserToken, { userToken: 'token' }),
        ...mapWritableState(usePromoMaterials, { storeOffers: 'offers' }),
        ...mapWritableState(useAddedGoods, ['cartGoods']),
    },

    methods: {
        onAddedToCart(goods, id) {
            let good = goods.find((item) => item.id === id)
            good.added = !good.added

            this.checkAllProducts()
        },
        onAmountChange(goods, id, amount) {
            let good = goods.find((item) => item.id === id)
            good.amount = amount

            this.checkAllProducts()
        },

        checkAllProducts() {
            for (let good of this.onsaleGoods) {
                let cartGood = this.cartGoods.find((item) => item.id == good.id)

                if (cartGood != undefined) {
                    good.added = true
                    good.amount = cartGood.amount
                }
                else {
                    good.added = false
                }
            }

            for (let good of this.popularGoods) {
                let cartGood = this.cartGoods.find((item) => item.id == good.id)

                if (cartGood != undefined) {
                    good.added = true
                    good.amount = cartGood.amount
                }
                else {
                    good.added = false
                }
            }
        },

        checkProducts(array) {
            for (let good of array) {
                let cartGood = this.cartGoods.find((item) => item.id == good.id)

                if (cartGood != undefined) {
                    good.added = true
                    good.amount = cartGood.amount
                }
                else {
                    good.added = false
                }
            }
        },

        async getPopularGoods() {
            let formData = {
                type: 'popular',
                sort: '',
                amount: 10
            }

            let result = await serverRequest('POST', 'json', 'get_goods', formData)

            if (result != 'error' && result != 'unathorized') {
                this.popularGoods = result.goods

                this.checkProducts(this.popularGoods)
            }
            else {
                //alert('Ошибка запроса')
            }
        },

        async getOnsaleGoods() {
            let formData = {
                type: 'onsale',
                sort: '',
                amount: 10
            }

            let result = await serverRequest('POST', 'json', 'get_goods', formData)

            if (result != 'error' && result != 'unathorized') {
                this.onsaleGoods = result.goods

                this.checkProducts(this.onsaleGoods)
            }
            else {
                //alert('Ошибка запроса')
            }
        },

        bannerHandler() {
            if (this.offers[0]) {
                this.firstBanner = this.offers[0]
            }
            if (this.offers[1]) {
                this.secondBanner = this.offers[1]
            }
            if (this.offers[2]) {
                this.thirdBanner = this.offers[2]
            }

        },

        async getOffers() {
            this.isLoading = true;

            if (this.storeMaterials == null) {
                let result = await serverRequest('GET', 'json', 'marketing/promotions')

                if (result != 'error' && result != 'unathorized') {
                    this.offers = result
                }
                else {
                    //alert('Ошибка запроса')
                }
            }
            else {
                this.offers = this.storeOffers
            }

            this.bannerHandler();

            this.isLoading = false
        }

    }
}
</script>

<style lang="scss">
.promo-offer-block {
    .goods-title {
        margin-top: 48px;
    }
}
</style>