<template>
    <section class="marketing-learning-block">
        <img v-if="banner != null && banner != ''" :src="this.banner" class="marketing-learning-block__img" />
        <div v-else class="marketing-learning-block__img">
        </div>
        <div v-if="!requestSended" class="marketing-learning-block__form" :class="{ 'loading': isLoading }">
            <v-loading :is-loading="isLoading" :color="'white'"></v-loading>
            <div class="inputs">
                <v-select class="select" :select-id="1" :select-name="'Выбрать категорию'" :options="categoryOptions"
                    :selected="categoryValue.name" @select="categorySelectHandler" :scroll="true"></v-select>
                <div class="input-container" :class="{ 'error': v$.phone.$errors.length }">
                    <label for="telephone">Контактный телефон</label>
                    <input id="telephone" v-maska="phoneBindedObject" data-maska="+7 (###) ###-##-##" type="text"
                        v-model="phone" placeholder="+7 (___) ___ - __ - __">
                </div>
                <div class="input-container" :class="{ 'error': v$.email.$errors.length }">
                    <label for="e-mail">e-mail</label>
                    <input type="text" id="e-mail" v-model="email">
                </div>
            </div>
            <div class="marketing-learning-block__container">
                <div class="text-area" :class="{ 'error': v$.text.$errors.length }">
                    <label for="comment">Комментарий</label>
                    <textarea id="comment" type="text" placeholder="Введите текст" v-model="text"></textarea>
                </div>
                <file-line-upload class="file-upload" @fileUploaded="onFileUploaded" @file-deleted="onFileDeleted"
                    :formats="'.doc,.docx,.png,.jpg,.jpeg,.pdf'" ref="fileUpload"></file-line-upload>
            </div>
            <button class="form-button" @click="createRequest">
                Отправить заявку
            </button>
        </div>
        <template v-else>
            <p class="special-order-page__text">Заявка на обучение отправлена</p>
            <button class="special-order-page__btn" @click="requestSended = false">Создать новую</button>
        </template>
    </section>
</template>

<script>
import vSelect from '../v-select.vue';
import FileLineUpload from '../FileLineUpload.vue';
import vLoading from '../v-loading.vue';

import { mapWritableState } from 'pinia';
import { useCatalogCategories } from '../../stores/catalogCategories';
import { useGeneralInfo } from '../../stores/generalInfo';

import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

import { vMaska } from "maska"

import { serverRequest } from '../../api/request';

export default {
    name: 'MarketingLearning',

    components: {
        vSelect,
        FileLineUpload,
        vLoading
    },

    directives: { maska: vMaska },

    props: {
        userToken: {
            type: String,
            default() {
                return ''
            }
        },
    },

    data() {
        return {
            v$: useVuelidate(),

            banner: null,
            isLoading: false,
            categoryValue: {
                name: 'Категория 1',
                value: 'Категория 1'
            },
            categoryOptions: [
                {
                    name: 'Категория 1',
                    value: 'Категория 1'
                },
                {
                    name: 'Категория 2',
                    value: 'Категория 2'
                },
                {
                    name: 'Категория 3',
                    value: 'Категория 3'
                },
            ],
            phoneBindedObject: {
                masked: "",
                unmasked: "",
                completed: false
            },
            phone: '',
            text: '',
            email: '',
            file: null,
            requestSended: false
        }
    },

    validations() {
        return {
            text: { required },
            phone: { required },
            email: { required, email }
        }
    },

    beforeMount() {
        this.getCategories()
        this.getBanner()
    },

    computed: {
        ...mapWritableState(useCatalogCategories, { storeCategories: 'categories' }),
    },

    methods: {
        categorySelectHandler(option) {
            this.categoryValue = option
        },

        onFileUploaded(file) {
            this.file = file
        },

        onFileDeleted() {
            this.file = null
        },

        categoriesHandler(array) {
            let options = []

            for (let item of array) {
                for (let category of item.list) {
                    let newOption = {
                        name: category.name,
                        value: category.id
                    }

                    options.push(newOption)
                }
            }

            return options;
        },

        async getCategories() {
            if (this.storeCategories == null) {
                let result = await serverRequest('GET', 'json', 'get_categories')

                if (result != 'error' && result != 'unathorized') {
                    this.storeCategories = result.data;
                }
                else {
                    //alert('Ошибка запроса')
                }
            }

            this.categoryOptions = this.categoriesHandler(this.storeCategories)
            this.categoryValue = this.categoryOptions[0]
        },

        async getBanner() {
            let result = await serverRequest('GET', 'json', 'get_learning_banner')

            if (result != 'error' && result != 'unathorized') {
                this.banner = result.banner
            }
            else {
                //alert('Ошибка запроса')
            }
        },

        async createRequest() {
            this.v$.$validate();

            if (!this.v$.$error && this.phoneBindedObject.completed == true) {
                this.isLoading = true

                let formData = new FormData();

                formData.append('type', 3)
                formData.append('comment', this.text)
                formData.append('category', this.categoryValue.value)
                formData.append('phone', this.phone)
                formData.append('email', this.email)
                formData.append('file', this.file)

                for (let [name, value] of formData) {
                    console.log(`${name} = ${value}`);
                }

                let result = await serverRequest('POST', 'formData', 'marketing/requests', formData)

                if (result != 'error' && result != 'unathorized') {
                    this.phone = ''
                    this.email = ''
                    this.text = ''
                    this.file = null
                    this.$refs.fileUpload.files = []
                    this.v$.$reset()
                    this.isLoading = false
                    this.requestSended = true
                }
                else {
                    //alert('Ошибка запроса')
                }

                this.isLoading = false
            }
        }
    }
}
</script>

<style lang="scss">
.marketing-learning-block {
    &__gradient {
        width: 100%;
        height: 360px;

        content: "";

        border-radius: 6px;
        background: linear-gradient(93deg, #9E8EFF 0%, #D1DBFF 100%);
    }

    &__img {
        width: 100%;
        height: 360px;

        border-radius: 6px;

        background-color: $bw4;
    }

    &__container {
        max-width: 800px;
    }

    &__form {
        margin-top: 24px;
        position: relative;

        &.loading {
            pointer-events: none;
        }

        .inputs {
            max-width: 380px;
        }

        .input-container {
            @extend %input-container;
            margin-top: 24px;
        }

        .text-area {
            @extend %text-area;
            margin-top: 24px;
        }

        .file-upload {
            margin-top: 12px;
        }
    }

    .form-button {
        margin-top: 32px;
        @extend %button;
        width: 200px;
    }
}

@media screen and (max-width: 1600px) {
    .marketing-learning-block {
        &__gradient {
            height: 246px;
        }

        &__img {
            height: 246px;
        }
    }
}

@media screen and (max-width: 1300px) {
    .marketing-learning-block {
        &__gradient {
            height: 220px;
        }

        &__img {
            height: 220px;
        }
    }
}

@media screen and (max-width: 800px) {
    .marketing-learning-block {
        &__gradient {
            height: 174px;
        }

        &__img {
            height: 174px;
        }

        &__form {
            .inputs {
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .marketing-learning-block {
        &__gradient {
            height: 82px;
        }

        &__img {
            height: 82px;
        }

        &__form {
            .inputs {
                max-width: 100%;
            }
        }
    }
}
</style>