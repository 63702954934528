<template>
    <swiper class="goods-slider" :slides-per-view="slidesPerView" :space-between="spaceBetween" :allow-touch-move="true"
        :grab-cursor="true">
        <swiper-slide v-for="good in goods">
            <product-card :key="good.id" :id="good.id" :amount="good.amount" :name="good.name" :articul="good.articul"
                :brand="good.brand" :multiplicity="good.multiplicity" :unit="good.unit" :price="good.price"
                :images="good.images" :attribute="good.attribute" :added="good.added" @addedToCart="onAddedToCart"
                @amount-change="onAmountChange"></product-card>
        </swiper-slide>
    </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';

import ProductCard from './ProductCard.vue';

export default {
    name: 'GoodsSlider',

    components: {
        Swiper,
        SwiperSlide,
        ProductCard
    },

    props: {
        goods: {
            type: Array,
            default() {
                return []
            }
        },
    },

    emits: ['addedToCart', 'amountChange'],

    data() {
        return {
            slidesPerView: 5,
            spaceBetween: 32
        }
    },

    beforeMount() {
        if (document.documentElement.clientWidth <= 1600) {
            this.slidesPerView = 4
        }
        if (document.documentElement.clientWidth <= 1300) {
            this.spaceBetween = 40
        }
        if (document.documentElement.clientWidth <= 800) {
            this.slidesPerView = 4
            this.spaceBetween = 20
        }
        if (document.documentElement.clientWidth <= 600) {
            this.slidesPerView = 2
            this.spaceBetween = 9
        }
    },

    methods: {
        onAddedToCart(id) {
            this.$emit('addedToCart', id)
        },
        onAmountChange(id, amount) {
            this.$emit('amountChange', id, amount)
        }

    }

}
</script>

<style lang="scss">
.goods-slider {
    width: 100%;
    min-width: 0;
}
</style>