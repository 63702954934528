<template>
    <section v-if="!requestSended" class="retail-outlet-block" :class="{ 'loading': isLoading }">
        <v-loading :is-loading="isLoading" :color="'white'"></v-loading>
        <div class="inputs-grid">
            <div class="input-container" :class="{ 'error': v$.organizationName.$errors.length }">
                <label for="name-organization">Название организации</label>
                <input type="text" id="name-organization" v-model="organizationName" disabled>
            </div>
            <div class="input-container" :class="{ 'error': v$.inn.$errors.length }">
                <label for="inn">ИНН</label>
                <input type="text" id="inn" v-model="inn" disabled>
            </div>
            <div class="input-container" :class="{ 'error': v$.retailName.$errors.length }">
                <label for="name-retail">Название точки</label>
                <input id="name-retail" type="text" v-model="retailName">
            </div>
        </div>
        <div class="input-container big" :class="{ 'error': v$.address.$errors.length }">
            <label for="address">Адрес</label>
            <input type="text" id="address" v-model="address">
        </div>
        <div class="input-container" :class="{ 'error': v$.phone.$errors.length }">
            <label for="telephone">Контактный телефон</label>
            <input id="telephone" v-maska="phoneBindedObject" data-maska="+7 (###) ###-##-##" type="text"
                v-model="phone" placeholder="+7 (___) ___ - __ - __">
        </div>
        <div class="input-container" :class="{ 'error': v$.email.$errors.length }">
            <label for="email">e-mail</label>
            <input type="text" id="email" v-model="email">
        </div>
        <div class="textarea-container">
            <div class="text-area" :class="{ 'error': v$.text.$errors.length }">
                <label for="comment">Комментарий</label>
                <textarea id="comment" type="text" placeholder="Введите текст" v-model='text'></textarea>
            </div>
            <file-line-upload class="file-upload" @fileUploaded="onFileUploaded" @file-deleted="onFileDeleted"
                :formats="'.doc,.docx,.png,.jpg,.jpeg,.pdf'" ref="fileUpload"></file-line-upload>
        </div>
        <button class="button" @click="createRequest">
            Отправить заявку
        </button>
    </section>
    <template v-else>
        <p class="special-order-page__text">Заявка на оформление розничной точки отправлена</p>
        <button class="special-order-page__btn" @click="requestSended = false">Создать новую</button>
    </template>
</template>

<script>
import { serverRequest } from '../../api/request';

import FileLineUpload from '../FileLineUpload.vue';
import vLoading from '../v-loading.vue';

import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

import { mapState } from 'pinia'
import { useUserToken } from '@/stores/userToken'

import { vMaska } from "maska"

export default {
    name: 'RetailOutlet',

    components: {
        FileLineUpload,
        vLoading
    },

    directives: { maska: vMaska },

    props: {
        userToken: {
            type: String,
            default() {
                return ''
            }
        },
    },

    data() {
        return {
            v$: useVuelidate(),

            isLoading: false,
            requestSended: false,
            organizationName: '',
            inn: null,
            retailName: '',
            phoneBindedObject: {
                masked: "",
                unmasked: "",
                completed: false
            },
            address: '',
            phone: '',
            text: '',
            email: '',
            file: null
        }
    },

    validations() {
        return {
            text: { required },
            phone: { required },
            email: { required, email },
            organizationName: { required },
            address: { required },
            inn: { required },
            retailName: { required },
        }
    },

    beforeMount() {
        this.organizationName = this.storeCompanyName
        this.inn = this.storeCompanyInn
    },

    computed: {
        ...mapState(useUserToken, { storeCompanyInn: 'companyInn' }),
        ...mapState(useUserToken, { storeCompanyName: 'companyName' }),
    },

    methods: {
        onFileUploaded(file) {
            this.file = file
        },

        onFileDeleted() {
            this.file = null
        },

        async createRequest() {
            this.v$.$validate();

            if (!this.v$.$error && this.phoneBindedObject.completed == true) {
                this.isLoading = true

                let formData = new FormData();

                formData.append('type', 4)
                formData.append('comment', this.text)
                formData.append('org', this.organizationName)
                formData.append('point', this.retailName)
                formData.append('inn', this.inn)
                formData.append('address', this.address)
                formData.append('phone', this.phone)
                formData.append('email', this.email)
                formData.append('file', this.file)

                for (let [name, value] of formData) {
                    console.log(`${name} = ${value}`);
                }

                let result = await serverRequest('POST', 'formData', 'marketing/requests', formData)

                if (result != 'error' && result != 'unathorized') {
                    this.phone = ''
                    this.email = ''
                    this.text = ''
                    this.retailName = ''
                    this.address = ''
                    this.$refs.fileUpload.files = []
                    this.file = null
                    this.v$.$reset()
                    this.isLoading = false
                    this.requestSended = true
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
                else {
                    //alert('Ошибка запроса')
                }

                this.isLoading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.retail-outlet-block {
    position: relative;

    &.loading {
        pointer-events: none;
    }

    .inputs-grid {
        display: grid;
        grid-template-columns: 380px 240px;
        column-gap: 40px;
        row-gap: 24px;
    }

    .input-container {
        @extend %input-container;
        margin-top: 24px;

        max-width: 380px;

        &.big {
            max-width: 800px;
        }
    }

    .textarea-container {
        margin-top: 24px;

        max-width: 800px;
    }

    .text-area {
        @extend %text-area;
    }

    .file-upload {
        margin-top: 12px;
    }

    .button {
        margin-top: 32px;
        @extend %button;
        width: 200px;
    }
}

@media screen and (max-width: 600px) {
    .retail-outlet-block {
        .inputs-grid {
            grid-template-columns: 1fr;
            row-gap: 0;
        }

        .input-container {
            max-width: 100%;
        }
    }
}
</style>