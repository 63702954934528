import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

export const usePromoMaterials = defineStore('promoMaterials', () => {
  const materials = ref(null)
  const offers = ref(null)

  return {
    materials,
    offers
  }
})
