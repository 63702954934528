import { useUserToken } from '@/stores/userToken'
import router from '@/router'

export async function serverRequest(method, type, path, formData = null, token = null) {
  let response = null
  const store = useUserToken()

  if (type == 'json') {
    if (method == 'POST') {
      response = await fetch(import.meta.env.VITE_API_URL + path, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + store.token
        },
        body: JSON.stringify(formData)
      })
    } else if (method == 'GET') {
      response = await fetch(import.meta.env.VITE_API_URL + path, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + store.token
        }
      })
    }
  } else if (type == 'formData') {
    response = await fetch(import.meta.env.VITE_API_URL + path, {
      method: method,
      headers: {
        Authorization: 'Bearer ' + store.token
      },
      body: formData
    })
  }

  if (response.ok) {
    let result = await response.json()

    console.log(`успешно - ${path}`)
    console.log(result)

    return result
  } else if (response.status == 401) {
    store.token = ''
    router.push({ name: 'login' })

    return 'unathorized'
  } else {
    return 'error'
  }
}
