<template>
    <section class="complaints-block">
        <button class="create-complaint" v-if="!isNewComplaintShow" @click="isNewComplaintShow = true">Подать
            рекламацию</button>
        <template v-else>
            <h2>Подать рекламацию</h2>
            <div class="inputs-grid">
                <div class="input-container" :class="{ error: v$.orderNumber.$errors.length }">
                    <label for="order-number">Номер заказа</label>
                    <input id="order-number" type="text" v-maska:[maskOptions]="numberBindedObject"
                        v-model="orderNumber" placeholder="№0000">
                </div>
                <!-- <div class="input-container">
                <label for="complaints-number">Номер рекламации</label>
                <input id="complaints-number" type="text" v-model="complaintNumber">
            </div> -->
                <v-select class="select" :select-id="1" :select-name="'Тип рекламации'" :options="typeOptions"
                    :selected="typeValue.name" @select="TypeSelectHandler"></v-select>
                <div class="text-area" :class="{ error: getCommentErrors }">
                    <label for="comment">Текст рекламации</label>
                    <textarea id="comment" type="text" placeholder="Введите текст" v-model="comment"></textarea>
                </div>
            </div>
            <file-line-upload class="file-upload" @fileUploaded="onFileUploaded" @file-deleted="onFileDeleted"
                :formats="'.doc,.docx,.png,.jpg,.jpeg,.pdf'" ref="fileUpload"></file-line-upload>
            <transition>
                <alert-block v-show="wrongOrder" :text="'Номера заказа не существует'" :color="'red'"></alert-block>
            </transition>
            <transition>
                <alert-block v-show="notCompleteOrder" :text="'Для данного заказа рекламация ещё не доступна'"
                    :color="'red'"></alert-block>
            </transition>
            <div class="buttons-container">
                <button @click="createComplaint">Сохранить</button>
                <button class="gray" @click="isNewComplaintShow = false">Отменить</button>
            </div>
        </template>
        <section class="complaints-history">
            <h2>История рекламаций</h2>
            <div class="complaints-history__container">
                <div v-for="complain in history" class="bill-item">
                    <div class="bill-item__top">
                        <div>
                            <p class="bill-item__label">Имя</p>
                            <a class="bill-item__file" :href="complain.link" download target="_blank">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7 21C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14L19 8V19C19 20.1046 18.1046 21 17 21H7Z"
                                        stroke="#035AA6" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M13 3V9H19" stroke="#035AA6" stroke-width="1.5" stroke-linejoin="round" />
                                </svg>
                                <span>{{ complain.name }}</span>
                            </a>
                        </div>
                        <div>
                            <p class="bill-item__label">Номер заказа</p>
                            <p class="bill-item__date">{{ complain.order }}</p>
                        </div>
                        <div>
                            <p class="bill-item__label">Статус</p>
                            <status-block :text="getTextStatus(complain.status)" :code="complain.status"
                                :type="'complaints'"></status-block>
                        </div>
                        <router-link :to="{ name: 'order-item', params: { id: complain.order } }"
                            class="bill-item__button">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 12H21" stroke="black" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path d="M16 7L21 12L16 17" stroke="black" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </router-link>
                    </div>
                    <div class="bill-item__answer" v-if="complain.answer != null">
                        <button @click="showAnswer">
                            <span>Ответ менеджера</span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M17 15L12 10L7 15" stroke="#424F60" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </button>
                        <p class="bill-item__answer-text">
                            {{ complain.answer }}
                        </p>
                    </div>
                </div>
            </div>
            <v-pagination class="catalog-page__pagination" v-show="isPagination" :totalPages="getTotalPages"
                :currentPage="currentPage" @page-change="pageChange"></v-pagination>
        </section>
    </section>
</template>

<script>
import AlertBlock from '../AlertBlock.vue';
import vCheckbox from '../v-checkbox.vue';
import vSelect from '../v-select.vue';
import FileLineUpload from '../FileLineUpload.vue';
import StatusBlock from '../StatusBlock.vue';
import vPagination from '../v-pagination.vue';

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import { serverRequest } from '../../api/request';

import { vMaska } from "maska"

export default {
    name: 'ComplaintsBlock',

    components: {
        AlertBlock,
        vCheckbox,
        vSelect,
        FileLineUpload,
        StatusBlock,
        vPagination
    },

    directives: { maska: vMaska },

    props: {
        userToken: {
            type: String,
            default() {
                return ''
            }
        },
    },

    emits: ['setLoading'],

    data() {
        return {
            v$: useVuelidate(),

            numberBindedObject: {
                masked: "",
                unmasked: "",
                completed: false
            },

            maskOptions: {
                mask: "№9",
                tokens: {
                    9: { pattern: /[0-9]/, repeated: true },
                }
            },

            wrongOrder: false,
            notCompleteOrder: false,
            isNewComplaintShow: false,
            itemsOnPage: 10,
            currentPage: 1,
            orderNumber: null,
            allComplaints: 0,
            comment: '',
            complaintNumber: null,
            file: null,
            history: [],
            typeValue: {
                name: 'По количеству',
                value: 0
            },
            typeOptions: [
                {
                    name: 'По количеству',
                    value: 0
                },
                {
                    name: 'По качеству',
                    value: 1
                },
            ],
        };
    },

    validations() {
        if (this.commentRequire) {
            return {
                comment: { required },
                orderNumber: { required },
            }
        }
        else {
            return {
                orderNumber: { required }
            }
        }
    },

    beforeMount() {
        this.getData()
    },

    computed: {
        commentRequire() {
            if (this.file == null) {
                return true
            }
            else {
                return false
            }
        },
        getCommentErrors() {
            if (this.commentRequire) {
                if (this.v$.comment.$errors.length) {
                    return true
                }
                else {
                    return false
                }
            }
            else {
                return false
            }
        },
        isPagination() {
            if (this.allComplaints > this.itemsOnPage) {
                return true
            }
            else {
                return false
            }
        },

        getTotalPages() {
            if (this.isPagination) {
                return Math.ceil(this.allComplaints / this.itemsOnPage)
            }
            else {
                return 1
            }
        }
    },

    methods: {
        getTextStatus(status) {
            switch (status) {
                case 0:
                    return 'На рассмотрении'
                case 1:
                    return 'Принята'
                case 2:
                    return 'Отклонена'
                default:
                    return 'Статус'
            }
        },

        TypeSelectHandler(option) {
            this.typeValue = option
        },

        showAnswer(event) {
            let item = event.target.closest(".bill-item");
            if (item.classList.contains("show")) {
                item.classList.remove("show")
            }
            else {
                item.classList.add("show")
            }
        },

        onFileUploaded(file) {
            this.file = file
        },

        onFileDeleted() {
            this.file = null
        },

        pageChange(page) {
            this.currentPage = page

            this.getData()
        },

        async getData() {
            this.$emit('setLoading', true)

            let formData = {
                start_pos: (this.currentPage - 1) * this.itemsOnPage,
                amount: this.itemsOnPage
            }

            let result = await serverRequest('POST', 'json', 'documents/get_complaints', formData)

            if (result != 'error' && result != 'unathorized') {
                this.allComplaints = result.complaints_count
                this.history = result.complaints

                this.$emit('setLoading', false)
            }
            else {
                //alert('Ошибка запроса')
            }
        },

        createComplaint() {
            this.v$.$validate();
            this.wrongOrder = false
            this.notCompleteOrder = false

            if (!this.v$.$error) {
                this.sendComplaint()
            }
        },

        async sendComplaint() {
            this.$emit('setLoading', true)

            let formData = new FormData();

            formData.append('order_number', this.numberBindedObject.unmasked)
            formData.append('type', this.typeValue.value)
            formData.append('comment', this.comment)
            formData.append('file', null)

            if (this.file) {
                formData.set('file', this.file)
            }

            let result = await serverRequest('POST', 'formData', 'documents/add_complaint', formData)

            if (result != 'error' && result != 'unathorized') {
                if (result.status == 'ok') {
                    this.orderNumber = null
                    this.comment = ''
                    this.$refs.fileUpload.files = []
                    this.getData()
                }
                else if (result.status == 'wrong_order') {
                    this.wrongOrder = true
                    this.$emit('setLoading', false)
                }
                else if (result.status == 'not_complete') {
                    this.notCompleteOrder = true
                    this.$emit('setLoading', false)
                }
            }
            else {
                //alert('Ошибка запроса')
            }
        }

    }
}
</script>

<style lang="scss">
.complaints-block {
    margin-top: 24px;

    h2 {
        @extend %text-medium;
        color: $bw0;
    }

    .create-complaint {
        @extend %button;
        width: fit-content;
    }

    .input-container {
        @extend %input-container;


        label {
            white-space: nowrap;
        }
    }

    .error-text {
        margin-top: 16px;

        @extend %text-regular;
        color: red;
    }

    .inputs-grid {
        max-width: 800px;

        margin-top: 24px;

        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 40px;
        row-gap: 24px;
    }

    .text-area {

        grid-column: 1/3;

        @extend %text-area;
    }

    .alert-block {
        margin-top: 16px;
    }

    .file-upload {
        max-width: 800px;

        margin-top: 12px;
    }

    .buttons-container {
        margin-top: 32px;

        display: flex;
        column-gap: 12px;

        button {
            @extend %button;

            width: 200px;

            &.gray {
                background-color: $bw4;
                color: $bw0;
            }
        }
    }

    .complaints-history {
        margin-top: 48px;

        &__container {
            margin-top: 24px;

            display: flex;
            flex-direction: column;
            row-gap: 12px;
        }

    }

    .bill-item {
        padding: 16px 24px;

        border-radius: 6px;
        border: 1px solid $bw3;


        &__top {
            display: flex;
            align-items: center;
            column-gap: 24px;

            >div {
                width: 100%;

                display: flex;
                flex-direction: column;
                row-gap: 8px;
            }
        }



        &__file {
            display: flex;
            align-items: center;
            column-gap: 8px;

            span {
                @extend %text-regular;
                color: var(--blue);
            }

            svg {
                flex-shrink: 0;
            }
        }

        &__button {
            min-width: 48px;
            width: 48px;
            max-width: 48px;

            height: 48px;

            padding: 12px;

            border-radius: 4px;

            background-color: $bw4;
        }

        &__answer {
            margin-top: 24px;

            padding-top: 24px;

            border-top: 1px solid $bw3;

            button {
                display: flex;
                column-gap: 8px;
                align-items: center;

                background-color: transparent;

                span {
                    @extend %text-regular;
                    color: $bw1;
                }

                svg {
                    transform: rotate(180deg);

                    transition: transform .3s;
                }
            }
        }

        &__answer-text {
            margin-top: 12px;

            padding: 16px;

            border-radius: 6px;
            background: $bw4;

            @extend %text-regular;
            color: $bw0;

            max-width: 600px;

            display: none;
        }

        &.show {
            .bill-item__answer {
                button {
                    svg {
                        transform: rotate(0);
                    }
                }
            }

            .bill-item__answer-text {
                display: block;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .complaints-block {
        .bill-item {
            &__top {
                flex-direction: column;
                row-gap: 24px;
                align-items: flex-start;
            }
        }

        .buttons-container {
            flex-direction: column;
            row-gap: 12px;

            button {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .complaints-block {
        .inputs-grid {
            grid-template-columns: 1fr;
        }

        .text-area {
            grid-column: span 1;
        }
    }
}
</style>