<template>
  <section class="catalog-page__categories" :class="{ 'loading': isCategoryLoading }">
    <v-loading :is-loading="isCategoryLoading" :color="'white'"></v-loading>
    <category-item v-for="categoryItem in categories" :categoryItem="categoryItem"
      @category-choosen="categoryHandler"></category-item>
  </section>
</template>

<script>
import CategoryItem from '../../components/CategoryItem.vue';
import vLoading from '../../components/v-loading.vue';

import { mapWritableState } from 'pinia'
import { useBreadcrumbs } from '@/stores/breadcrumbs';

export default {
  name: 'CategoriesView',
  components: {
    CategoryItem,
    vLoading
  },

  emits: ['categoryChoosen', 'specificationShow', 'categoryShown'],

  props: {
    routeParams: {
      type: String,
      default() {
        return 'товары'
      }
    },

    isCategoryLoading: {
      type: Boolean,
      default() {
        return false
      }
    },

    categories: {
      type: Array,
      default() {
        return []
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$emit("categoryShown")
    })
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'categories',
        name: 'Каталог'
      },
      {
        path: 'categories',
        name: 'Категории'
      },
    ]
  },

  data() {
    return {
    }
  },

  computed: {
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),
  },

  methods: {
    categoryHandler(header, name, id) {
      this.$emit("categoryChoosen", header, name, id)

      this.$router.push({ name: 'goods', params: { categoryId: id } })
    },
  }
}
</script>


<style lang="scss">
.catalog-page {
  &__categories {
    margin-top: 0;

    @extend %white-bg-block;

    min-height: 120px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 48px;
    row-gap: 40px;

    position: relative;

    &.loading {
      pointer-events: none;
    }
  }

  &__sort-view-block {
    display: flex;
    column-gap: 32px;
    align-items: center;

    margin-top: 24px;

    .sort-item {
      display: flex;
      align-items: center;
      column-gap: 4px;

      cursor: pointer;

      span {
        @extend %text-small;
        color: var(--bw1);
      }

      svg {
        transition: transform .3s;
      }

      &.top {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .view-block {
      display: flex;
      align-items: center;
      column-gap: 10px;

      margin-left: auto;

      .view-item {
        input {
          position: absolute;
          z-index: -99;
          width: 1px;
          height: 1px;
          opacity: 0;
        }

        label {
          cursor: pointer;

          svg {
            path {
              transition: fill .3s, stroke .3s;
            }
          }

          .gray-fill {
            fill: var(--bw1)
          }

          .white-fill {
            fill: var(--bw6)
          }

          .gray-stroke {
            stroke: var(--bw1)
          }
        }

        input:checked+label {
          .gray-fill {
            fill: var(--bw6)
          }

          .white-fill {
            fill: var(--bw1)
          }

          .gray-stroke {
            stroke: var(--bw6)
          }
        }
      }
    }
  }

  &__pagination {
    margin-top: 32px;
  }

  .functional-block {
    width: 100%;
    max-width: 316px;

    .filter-block {
      &__header {
        padding: 16px 24px;

        @extend %text-medium;
        color: var(--bw6);

        background-color: var(--bw1);
      }

      .filter-item {
        &:not(:first-of-type) {
          margin-top: 1px;
        }

        background-color: var(--bw4);

        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          padding: 16px 24px;

          @extend %text-medium;
          color: var(--bw0);

          cursor: pointer;

          svg {
            flex-shrink: 0;
            transition: transform .3s;
          }
        }

        &__list {
          padding: 20px 24px 16px;

          grid-template-columns: 1fr 1fr;
          column-gap: 28px;
          row-gap: 16px;

          display: grid;
        }

        &.show {
          .filter-item__header {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        &.lines {
          .filter-item__list {
            grid-template-columns: 1fr;
          }
        }

        &.price {
          .filter-item__list {
            display: flex;
            column-gap: 12px;
            align-items: center;

            width: 100%;

            p {
              @extend %text-regular;
              color: $bw0;
            }

            input {
              background-color: $bw6;
              padding: 6px 16px;
              border-radius: 6px;

              max-width: 120px;

              @extend %text-regular;
              color: $bw1;
            }
          }
        }
      }

      &__buttons {
        margin-top: 1px;

        display: flex;
        column-gap: 12px;

        padding: 16px 24px;


        background-color: var(--bw4);
      }

      &__btn {
        padding: 5px 6px;

        display: block;

        width: 100%;
        text-align: center;

        border-radius: 6px;
        border: 1px solid var(--primary);

        background-color: var(--primary);

        @extend %text-regular;
        color: var(--bw6);

        &.gray {
          color: var(--bw1);
          border-color: var(--bw1);
          background-color: transparent;
        }
      }
    }
  }

  .download-button {
    margin-top: 24px;

    @extend %button;

    display: flex;
    column-gap: 12px;
    justify-content: center;

  }

  .information-block {
    margin-top: 24px;

    padding: 12px 16px;

    @extend %text-regular;
    color: var(--bw0);

    border-radius: 4px;
    background-color: var(--bw4);

  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.3s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
}

@media screen and (max-width: 1600px) {
  .catalog-page {
    &__categories {
      margin-top: 0;

      @extend %white-bg-block;

      min-height: 120px;

      display: grid;
      grid-template-columns: 400px 400px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .catalog-page {
    &__categories {
      grid-template-columns: 1fr;
      column-gap: 0;
      row-gap: 40px;
    }
  }
}
</style>