<template>
    <section class="pre-news-item" :class="{ 'with-image': isImage }">
        <img v-if="isImage" class="pre-news-item__img" :src="content.image" alt="фотография новости">
        <h5 class="pre-news-item__title">{{ content.header }}</h5>
        <div class="pre-news-item__text" v-html="content.text"></div>
        <a v-if="isFile" :href="content.file_link" class="pre-news-item__file" download target="_blank">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.66797 21C6.5634 21 5.66797 20.1046 5.66797 19V5C5.66797 3.89543 6.5634 3 7.66797 3H14.668L19.668 8V19C19.668 20.1046 18.7725 21 17.668 21H7.66797Z"
                    stroke="#035AA6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M13.668 3V9H19.668" stroke="#035AA6" stroke-width="1.5" stroke-linejoin="round" />
            </svg>
            <p>{{ content.file_name }}</p>
        </a>
        <router-link class="pre-news-item__link" :to="{ name: 'article', params: { id: content.id } }">
            <span>Читать дальше</span>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.33594 12H21.3359" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M16.3359 7L21.3359 12L16.3359 17" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </router-link>
    </section>
</template>

<script>
export default {
    name: 'PreviewNewsItem',

    props: {
        content: {
            type: Object,
            default() {
                return {
                    id: '0',
                    image: '/src/assets/images/news.jpg',
                    header: 'название',
                    text: 'текст',
                    file_name: '',
                    file_link: ''
                }
            }
        },
    },

    computed: {
        isImage() {
            if (this.content.image != '') {
                return true
            }
            else {
                return false
            }
        },
        isFile() {
            if (this.content.file_link == '' || this.content.file_link == null) {
                return false
            }
            else {
                return true
            }
        }
    }
}
</script>

<style lang="scss">
.pre-news-item {
    padding: 16px;

    border-radius: 6px;
    border: 1px solid $bw3;

    width: 100%;

    overflow: hidden;
    min-width: 0;

    &__img {
        height: 250px;
        width: 100%;
        object-fit: cover;

        border-radius: 4px;

        margin-bottom: 16px;
    }

    &.with-image {
        grid-row: span 2
    }

    &__title {
        @extend %text-medium;
        color: $bw0;
    }

    &__text {
        margin-top: 8px;

        @extend %text-small;
        color: $bw0;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-clamp: 3;
        box-orient: vertical;

        width: 100%;

        strong,
        b {
            font-weight: 700;
        }

        i,
        em {
            font-style: italic;
        }
    }

    &__file {
        margin-top: 16px;

        display: flex;
        align-items: flex-start;
        column-gap: 8px;

        svg {
            margin-top: 3px;
            flex-shrink: 0;
        }

        p {
            @extend %text-regular;
            color: var(--blue);
        }
    }

    &__link {
        margin-top: 16px;

        @extend %link;
    }
}

@media screen and (max-width: 800px) {
    .pre-news-item {
        &__img {
            height: 200px;
        }
    }
}
</style>