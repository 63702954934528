<template>
    <VueDatePicker class="v-calendar" v-model="date" locale="ru" :enable-time-picker="false"
        :highlight-week-days="[0, 6]" :max-date="new Date()" :range="isRange" ref="datepicker">
        <template #trigger>
            <slot></slot>
        </template>
        <template #action-buttons>
            <button class="custom-button" @click="setDate">Применить</button>
            <button class="custom-button gray" @click="clearDate">Очистить выбор</button>
        </template>
    </VueDatePicker>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';

import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: 'vCalendar',
    components: { VueDatePicker },
    props: {
        isRange: {
            type: Boolean,
            default() {
                return false
            }
        },
    },
    emits: ['dateChange', 'dateClear'],
    data() {
        return {
            date: null,
        };
    },

    methods: {
        setDate() {
            this.$refs.datepicker.selectDate()
            this.$emit('dateChange', this.date)
        },
        clearDate() {
            this.$refs.datepicker.clearValue()
            this.$emit('dateClear')
        }
    }
}
</script>

<style lang="scss">
:root {
    --dp-cell-size: 56px 40px;
    --dp-cell-padding: 6px 12px;

    --dp-font-family: "Gilroy", bold, -apple-system, blinkmacsystemfont, "Segoe UI", roboto, oxygen, ubuntu, cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
}

.v-calendar {
    .dp__cell_highlight {
        color: var(--primary);

        &.dp__range_between {
            background-color: var(--dp-hover-color);
        }
    }

    .custom-button {
        @extend %button;

        padding: 8px;

        &.gray {
            background-color: $bw1;
        }
    }
}

.dp {
    font-weight: 500;

    &__main {
        width: fit-content;

        >div {
            width: fit-content;
        }
    }

    &__calendar_header_item {
        font-weight: 600;
    }

    &__overlay_cell {
        font-weight: 600;
    }

    &__btn {
        font-weight: 600;
    }

    &__cell_inner {
        font-weight: 500;
    }

    &__selection_preview {
        display: none;
    }

    &__action_buttons {
        flex: 1;
        column-gap: 12px;
    }

}

.dp__theme_light {
    --dp-background-color: #ffffff;
    --dp-text-color: #000000;
    --dp-hover-color: #fbe9e9;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #D93030;
    --dp-primary-text-color: #ffffff;
    --dp-secondary-color: #B6BEC9;
    --dp-border-color: #B6BEC9;
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: #aaaeb7;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #76d275;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #ff6f60;
    --dp-highlight-color: #ffffff;
}
</style>