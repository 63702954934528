import { defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'

export const useCatalogCategories = defineStore('catalogCategories', () => {
  const categories = ref(null)

  return {
    categories
  }
})
