<template>
    <section class="reports-block">
        <h2>Запросить акт сверки</h2>
        <div class="inputs-grid">
            <div class="input-container" :class="{ error: v$.companyName.$errors.length }">
                <label for="name">Наименование компании</label>
                <input id="name" type="text" v-model="companyName" disabled>
            </div>
            <div class="input-container" :class="{ error: v$.inn.$errors.length }">
                <label for="inn">ИНН</label>
                <input id="inn" type="number" v-model="inn" disabled>
            </div>
        </div>
        <div class="input-container period">
            <label>Период</label>
            <v-calendar :is-range="true" @date-change="onDateChange" @date-clear="onDateClear">
                <div class="date-pick">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 8H20" stroke="black" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M16 3V5" stroke="black" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M8 3V5" stroke="black" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path
                            d="M3 6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V6Z"
                            stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M16 16.5H16.002V16.502H16V16.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M12 16.5H12.002V16.502H12V16.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M8 16.5H8.002V16.502H8V16.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M16 12.5H16.002V12.502H16V12.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M12 12.5H12.002V12.502H12V12.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M8 12.5H8.002V12.502H8V12.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                    <span v-if="showedDate == null">Выбрать период</span>
                    <span v-else>{{ showedDate }}</span>
                </div>
            </v-calendar>
        </div>
        <transition>
            <alert-block v-show="noChoosedDate" :text="'Период не указан'" :color="'red'"></alert-block>
        </transition>
        <div class="buttons-container">
            <button @click="requestData()">Запросить акты сверки</button>
        </div>
        <section class="complaints-history">
            <h2>История документов</h2>
            <div class="complaints-history__container">
                <div v-for="bill in history" class="bill-item">
                    <div class="bill-item__top">
                        <div>
                            <p class="bill-item__label">Имя</p>
                            <a class="bill-item__file"
                                :class="{ 'not-download': bill.request_link == null || bill.request_link == '' }"
                                :href="bill.request_link" download target="_blank">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7 21C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14L19 8V19C19 20.1046 18.1046 21 17 21H7Z"
                                        stroke="#035AA6" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M13 3V9H19" stroke="#035AA6" stroke-width="1.5" stroke-linejoin="round" />
                                </svg>
                                <span>Счет {{ bill.name }}</span>
                            </a>
                        </div>
                        <div>
                            <p class="bill-item__label">Дата загрузки</p>
                            <p class="bill-item__date">
                                {{ bill.date }}
                            </p>
                        </div>
                        <div>
                            <p class="bill-item__label">Статус</p>
                            <status-block :text="getTextStatus(bill.status)" :code="bill.status"></status-block>
                        </div>
                        <a :href="bill.link" class="bill-item__button"
                            :class="{ 'disabled': bill.link == '' || bill.link == null || bill.status == 0 }">
                            <span>Скачать</span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 12L12 17M12 17L17 12M12 17L12 4" stroke="black" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6 20H18" stroke="black" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <v-pagination class="catalog-page__pagination" v-show="isPagination" :totalPages="getTotalPages"
                :currentPage="currentPage" @page-change="pageChange"></v-pagination>
        </section>
    </section>
</template>

<script>
import AlertBlock from '../AlertBlock.vue';
import vCheckbox from '../v-checkbox.vue';
import vSelect from '../v-select.vue';
import FileLineUpload from '../FileLineUpload.vue';
import StatusBlock from '../StatusBlock.vue';
import vPagination from '../v-pagination.vue';

import vCalendar from '../v-calendar.vue';

import { mapState } from 'pinia'
import { useUserToken } from '@/stores/userToken'

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import { serverRequest } from '../../api/request';

export default {
    name: 'ReportsBlock',

    components: {
        AlertBlock,
        vCheckbox,
        vSelect,
        FileLineUpload,
        StatusBlock,
        vCalendar,
        vPagination
    },

    props: {
        userToken: {
            type: String,
            default() {
                return ''
            }
        },
    },

    data() {
        return {
            v$: useVuelidate(),

            history: [],
            itemsOnPage: 10,
            currentPage: 1,
            allItem: 0,
            companyName: '',
            inn: null,
            date: null,
            showedDate: null,
            noChoosedDate: false
        };
    },

    validations() {
        return {
            inn: { required },
            companyName: { required },
        }
    },

    beforeMount() {
        this.getData()

        this.inn = this.storeCompanyInn
        this.companyName = this.storeCompanyName
    },

    computed: {
        ...mapState(useUserToken, { storeCompanyInn: 'companyInn' }),
        ...mapState(useUserToken, { storeCompanyName: 'companyName' }),

        isPagination() {
            if (this.allItem > this.itemsOnPage) {
                return true
            }
            else {
                return false
            }
        },

        getTotalPages() {
            if (this.isPagination) {
                return Math.ceil(this.allItem / this.itemsOnPage)
            }
            else {
                return 1
            }
        }
    },

    methods: {
        TypeSelectHandler(option) {
            this.typeValue = option.value
        },

        onDateChange(date) {
            this.noChoosedDate = false

            this.date = date

            let start_date = (this.date[0].getDate()).toString().padStart(2, '0') + '.' + (parseInt(this.date[0].getMonth() + 1)).toString().padStart(2, '0') + '.' + this.date[0].getFullYear();
            let end_date = (this.date[1].getDate()).toString().padStart(2, '0') + '.' + (parseInt(this.date[1].getMonth() + 1)).toString().padStart(2, '0') + '.' + this.date[1].getFullYear();

            this.showedDate = start_date + ' - ' + end_date
        },

        onDateClear() {
            this.date = null
            this.showedDate = null
        },

        pageChange(page) {
            this.currentPage = page

            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.getData()
        },

        showAnswer(event) {
            let item = event.target.closest(".bill-item");
            if (item.classList.contains("show")) {
                item.classList.remove("show")
            }
            else {
                item.classList.add("show")
            }
        },

        getTextStatus(status) {
            switch (status) {
                case 0:
                    return 'Запрошен'
                case 1:
                    return 'Получен'
                default:
                    return 'Статус не определён'
            }
        },

        requestData() {
            this.v$.$validate();

            if (this.date == null) {
                this.noChoosedDate = true
            }

            if (!this.v$.$error && !this.noChoosedDate) {
                this.getSearchData()
            }
        },

        async getSearchData() {
            this.$emit('setLoading', true)

            let start_date = this.date[0].getDate() + '.' + (parseInt(this.date[0].getMonth() + 1)) + '.' + this.date[0].getFullYear();
            let end_date = this.date[1].getDate() + '.' + (parseInt(this.date[1].getMonth() + 1)) + '.' + this.date[1].getFullYear();

            let formData = {
                company_id: this.companyName,
                inn: this.inn,
                start_date: start_date,
                end_date: end_date
            }

            let result = await serverRequest('POST', 'json', 'documents/request_reconciliation_report', formData)

            if (result != 'error' && result != 'unathorized') {
                this.getData()

                this.$emit('setLoading', false)
            }
            else {
                //alert('Ошибка запроса')
                this.$emit('setLoading', false)
            }
        },

        async getData() {
            this.$emit('setLoading', true)

            let formData = {
                start_pos: (this.currentPage - 1) * this.itemsOnPage,
                amount: this.itemsOnPage
            }

            let result = await serverRequest('POST', 'json', 'documents/get_reconciliation_reports', formData)

            if (result != 'error' && result != 'unathorized') {
                this.allItem = result.reconciliation_reports_count
                this.history = result.reconciliation_reports

                this.$emit('setLoading', false)
            }
            else {
                //alert('Ошибка запроса')
            }
        },
    }
}
</script>

<style lang="scss">
.reports-block {
    margin-top: 24px;

    h2 {
        @extend %text-medium;
        color: $bw0;
    }

    .date-pick {
        cursor: pointer;

        display: flex;
        align-items: center;
        column-gap: 12px;

        width: fit-content;

        padding: 8px 12px;

        background-color: transparent;

        border-radius: 4px;

        transition: background-color .3s;

        span {
            @extend %text-medium;
            color: var(--bw0);
            white-space: nowrap;
        }

        &:hover {
            background-color: var(--primary-bg);
        }
    }

    .alert-block {
        margin-top: 16px;
    }

    .input-container {
        @extend %input-container;

        label {
            white-space: nowrap;
        }

        &.period {
            margin-top: 24px;


            label {
                display: block;
                margin-bottom: 12px;
            }
        }
    }

    .inputs-grid {
        max-width: 800px;

        margin-top: 24px;

        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 40px;
        row-gap: 24px;
    }

    .text-area {

        grid-column: 1/3;

        @extend %text-area;
    }


    .buttons-container {
        margin-top: 32px;

        display: flex;
        column-gap: 12px;

        button {
            @extend %button;

            width: fit-content;

            &.gray {
                background-color: $bw4;
                color: $bw0;
            }
        }
    }

    .complaints-history {
        margin-top: 48px;

        &__container {
            margin-top: 24px;

            display: flex;
            flex-direction: column;
            row-gap: 12px;
        }

    }

    .bill-item {
        padding: 16px 24px;

        border-radius: 6px;
        border: 1px solid $bw3;


        &__top {
            display: flex;
            align-items: flex-start;
            column-gap: 24px;

            >div {
                width: 100%;

                display: flex;
                flex-direction: column;
                row-gap: 8px;
            }
        }



        &__file {
            display: flex;
            align-items: center;
            column-gap: 8px;

            span {
                @extend %text-regular;
                color: var(--blue);
            }

            svg {
                flex-shrink: 0;
            }


        }

        &__date {
            @extend %text-regular;
            color: $bw1;
        }

        &__button {
            width: 100%;
            max-width: 132px;

            display: flex;
            column-gap: 12px;

            height: 48px;

            padding: 12px 16px;

            border-radius: 4px;

            background-color: $bw4;

            span {
                @extend %text-regular;
                color: $bw0;
            }

            svg {
                flex-shrink: 0;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .reports-block {
        .bill-item {
            &__top {
                flex-direction: column;
                row-gap: 24px;
                align-items: flex-start;
            }
        }

        .buttons-container {
            button {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .reports-block {
        .inputs-grid {
            grid-template-columns: 1fr;
        }
    }
}
</style>