
<template>
  <div class="empty-page">
    <custom-header :title="'Страницы не существует'"></custom-header>
    <router-link :to="{ name: 'home' }" class="button">На главную</router-link>
  </div>
</template>

<script>
import CustomHeader from '../components/CustomHeader.vue';

export default {
  name: 'EmptyView',
  components: {
    CustomHeader
  },
}
</script>

<style lang="scss">
.empty-page {
  .button {
    margin-top: 32px;
    @extend %button;

    width: 200px;
  }
}
</style>
