<template>
  <section class="profile-page__content profile-block">
    <div class="profile-block__header mobile-header">
      <custom-header :title="'Об организации'"></custom-header>
      <span
        v-if="regStatus == 1 && organizationInfo.status.length > 0"
        class="profile-block__header__status"
        >{{ organizationInfo.status }}</span
      >
    </div>
    <div class="profile-page__container">
      <div class="profile-page__general-information-wrapper">
        <div class="profile-page__general-information">
          <div class="profile-block__header desc-header">
            <custom-header :title="'Об организации'"></custom-header>
            <span
              v-if="regStatus == 1 && organizationInfo.status.length > 0"
              class="profile-block__header__status"
            >
              {{ organizationInfo.status }}
            </span>
          </div>
          <div
            class="input-container mt32"
            :class="{ error: v$.organizationInfo.name.$errors.length }"
          >
            <label for="name">Название организации</label>
            <input id="name" type="text" v-model="organizationInfo.name" />
          </div>
          <div class="input-container">
            <label for="website">Веб-сайт (Дополнительно)</label>
            <input id="website" type="text" v-model="organizationInfo.website" />
          </div>
          <div class="input-container">
            <label for="name" class="tooltip-label"
              >Тип организации
              <info-tooltip
                :text="'Корпоративный клиент - закупка без дальнейшей перепродажи.\nОптовый клиент - дальнейшая перепродажа'"
              ></info-tooltip
            ></label>
          </div>
          <v-select
            :select-id="2"
            :select-name="'Тип компании'"
            :options="typeOptions"
            :selected="typeValue.name"
            :show-label="false"
            @select="TypeSelectChange"
          ></v-select>
          <v-select
            class="select"
            :select-id="1"
            :select-name="'Сфера деятельности'"
            :options="sphereOptions"
            :selected="sphereValue.name"
            @select="SphereSelectHandler"
            :scroll="true"
          ></v-select>
          <div class="input-button">
            <div class="input-container">
              <label for="pricelist" class="tooltip-label"> Условия поставки </label>
              <input
                class="disabled-input"
                id="pricelist"
                type="text"
                disabled
                :value="getConditions"
              />
            </div>
            <button
              class="input-button__button"
              type="button"
              @click="changeTerms"
              :class="{ disabled: storeChangeTerms }"
            >
              <template v-if="!storeChangeTerms">Запросить изменение условий поставки</template>
              <template v-else>Запрос изменения условий поставки отправлен</template>
            </button>
          </div>
          <router-link class="file-download" :to="{ name: 'documents' }" target="_blank">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 21C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14L19 8V19C19 20.1046 18.1046 21 17 21H7Z"
                stroke="#035AA6"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M13 3V9H19" stroke="#035AA6" stroke-width="1.5" stroke-linejoin="round" />
            </svg>
            <span>{{ organizationInfo.file_name }}</span>
          </router-link>
          <!-- <a v-else href="/Договор поставки по-умолчанию.pdf" class="file-download" download>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7 21C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14L19 8V19C19 20.1046 18.1046 21 17 21H7Z"
                                stroke="#035AA6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M13 3V9H19" stroke="#035AA6" stroke-width="1.5" stroke-linejoin="round" />
                        </svg>
                        <span>Договор по умолчанию</span>
                    </a> -->
          <div class="input-button">
            <div class="input-container">
              <label for="pricelist" class="tooltip-label">
                Прайс-лист
                <info-tooltip
                  :text="'По умолчанию вам будет доступен корпоравтиный прайс-лист, после прохождения модерации прайс-лист обновится в соотвтевии с подтвержденым типом вашей компании'"
                ></info-tooltip>
              </label>
              <input
                class="disabled-input"
                id="pricelist"
                type="text"
                disabled
                value="Корпоративный"
              />
            </div>
            <button
              class="input-button__button"
              type="button"
              @click="changePricelist"
              :class="{ disabled: storeChangePricelist }"
            >
              <template v-if="!storeChangePricelist">Изменить прайс-лист</template>

              <template v-else>Запрос изменения прайс-листа отправлен</template>
            </button>
          </div>
        </div>
        <div class="profile-page__other-information">
          <h3>Реквизиты</h3>
          <div class="inputs-container mt12">
            <div class="input-container" :class="{ error: v$.organizationInfo.inn.$errors.length }">
              <label for="inn">ИНН</label>
              <input id="inn" type="number" v-model="organizationInfo.inn" />
            </div>
            <div class="input-container">
              <label for="kpp">КПП</label>
              <input id="kpp" type="number" v-model="organizationInfo.kpp" />
            </div>
            <div
              class="input-container"
              :class="{ error: v$.organizationInfo.ogrn.$errors.length }"
            >
              <label for="ogrn">ОГРН / ОГРНИП</label>
              <input id="ogrn" type="number" v-model="organizationInfo.ogrn" />
            </div>
          </div>
          <div
            class="input-container"
            :class="{ error: v$.organizationInfo.jur_address.$errors.length }"
          >
            <label for="juridical_adress">Юридический адрес</label>
            <input id="juridical_adress" type="text" v-model="organizationInfo.jur_address" />
          </div>
          <div class="input-container">
            <label for="fact_address">Фактический адрес (Дополнительно)</label>
            <input id="fact_address" type="text" v-model="organizationInfo.fact_address" />
          </div>
          <h3>Банковский счет</h3>
          <div class="input-container input-360">
            <label for="fact_address">Банк обслуживания (Дополнительно)</label>
            <input id="fact_address" type="text" v-model="organizationInfo.bank" />
          </div>
          <div class="inputs-container">
            <div class="input-container">
              <label for="checking_acc">Расчетный счет (Дополнительно)</label>
              <input id="checking_acc" type="number" v-model="organizationInfo.checking_acc" />
            </div>
            <div class="input-container">
              <label for="cor_acc">Корр. счет (Дополнительно)</label>
              <input id="cor_acc" type="number" v-model="organizationInfo.cor_acc" />
            </div>
            <div class="input-container">
              <label for="bik">БИК (Дополнительно)</label>
              <input id="bik" type="number" v-model="organizationInfo.bik" />
            </div>
          </div>
          <h3>Контакты</h3>
          <div class="inputs-container">
            <div
              class="input-container"
              :class="{ error: v$.organizationInfo.person.$errors.length }"
            >
              <label for="inn">Контактное лицо ЛПР</label>
              <input id="inn" type="text" v-model="organizationInfo.person" />
            </div>
            <div
              class="input-container"
              :class="{ error: v$.organizationInfo.phone.$errors.length }"
            >
              <label for="phone">Контактный номер</label>
              <input
                v-maska="phoneBindedObject"
                data-maska="+7 (###) ###-##-##"
                id="phone"
                type="text"
                v-model="organizationInfo.phone"
              />
            </div>
          </div>
          <div class="input-container input-380">
            <label for="extra-contact">Доп. контакт</label>
            <input
              v-maska="extraPhoneBindedObject"
              data-maska="+7 (###) ###-##-##"
              id="extra-contact"
              type="text"
              v-model="organizationInfo.extra_phone"
            />
          </div>
        </div>
      </div>
      <div class="profile-status-block-wrapper">
        <div class="profile-status-block">
          <div class="profile-status-block__content">
            <p class="profile-status-block__name">Профиль организации</p>
            <p class="profile-status-block__status">
              Статус профиля<span>{{ getProfileStatus }}</span>
            </p>
            <div class="profile-block-row">
              <p>{{ organizationInfo.name }}</p>
            </div>
            <!-- <div class="profile-block-row">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 12H5" stroke="black" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path d="M12 5V19" stroke="black" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                            <p>Добавить компанию</p>
                        </div> -->
            <button class="profile-status-block__button" @click="saveData()">Сохранить</button>
          </div>
          <div class="profile-status-block__content">
            <p class="profile-status-block__name">Пользователи</p>
            <user-row
              v-for="user in users"
              :key="user.id"
              :id="user.id"
              :name="user.name"
              :role="user.role"
            ></user-row>
            <!-- <user-row v-for="user in users" :key="user.id" :id="user.id" :name="user.name" :role="user.role"
                            :login="user.login" :email="user.email" :tel="user.tel"></user-row> -->
          </div>
          <div class="profile-status-block__content">
            <p class="profile-status-block__name">Менеджер</p>
            <p class="manager-name">{{ managerInfo.name }}</p>
            <p class="manager-row">
              e-mail<span>{{ managerInfo.email }}</span>
            </p>
            <p class="manager-row">
              Контактный номер<span>{{ managerInfo.tel }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CustomHeader from '../CustomHeader.vue'
import InfoTooltip from '../InfoTooltip.vue'
import vSelect from '../v-select.vue'
import FileUpload from '../FileUpload.vue'
import UserRow from '../UserRow.vue'

import { vMaska } from 'maska'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import { useTempStates } from '../../stores/tempStates'
import { useUserToken } from '@/stores/userToken'
import { mapWritableState } from 'pinia'

import { serverRequest } from '../../api/request'

export default {
  name: 'ProfileBlock',

  components: {
    CustomHeader,
    InfoTooltip,
    vSelect,
    FileUpload,
    UserRow
  },

  directives: { maska: vMaska },

  props: {
    userToken: {
      type: String,
      default() {
        return ''
      }
    },
    regStatus: {
      type: Number,
      default() {
        return ''
      }
    }
  },

  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      phoneBindedObject: {
        masked: '',
        unmasked: '',
        completed: false
      },
      extraPhoneBindedObject: {
        masked: '',
        unmasked: '',
        completed: false
      },
      organizationInfo: {
        status: '',
        name: '',
        website: '',
        inn: null,
        kpp: null,
        ogrn: null,
        jur_address: '',
        fact_address: '',
        bank: '',
        checking_acc: null,
        cor_acc: null,
        bik: null,
        person: '',
        phone: '',
        extra_phone: '',
        file_name: '',
        file_link: ''
      },

      currentCondition: 0,

      deliveryConditions: [
        {
          id: 0,
          value: 'Предоплата'
        },
        {
          id: 1,
          value: 'Рассрочка 30 дней'
        },
        {
          id: 2,
          value: 'Рассрочка 45 дней'
        },
        {
          id: 3,
          value: 'Рассрочка 60 дней'
        },
        {
          id: 4,
          value: 'Рассрочка 90 дней'
        }
      ],

      managerInfo: {
        name: '',
        email: '',
        tel: ''
      },

      sphereId: 0,

      sphereValue: {
        name: '',
        value: 1
      },
      typeValue: {
        name: '',
        value: 1
      },
      sphereOptions: [
        {
          name: 'Производство',
          value: 1
        },
        {
          name: 'Ремонтная мастерская',
          value: 2
        },
        {
          name: 'Розничные продажи',
          value: 3
        },
        {
          name: 'Иная',
          value: 4
        }
      ],
      typeOptions: [
        {
          name: 'Корпоративные закупки',
          value: 1
        },
        {
          name: 'Оптовые закупки',
          value: 2
        },
        {
          name: 'Розничная сеть',
          value: 3
        },
        {
          name: 'Интернет магазин',
          value: 4
        }
      ],

      users: [
        {
          id: 0,
          name: '',
          role: 'Админ',
          login: 'alexunconditional',
          email: 'alex@gmail.com',
          tel: '+7 (921) 987-65-43'
        }
        // {
        //     id: 1,
        //     name: 'Алекснадр Безусловный',
        //     role: 'Менеджер',
        //     login: 'alexunconditional',
        //     email: 'alex@gmail.com',
        //     tel: '+7 (921) 987-65-43'
        // },
        // {
        //     id: 2,
        //     name: 'Алекснадр Безусловный',
        //     role: 'Менеджер',
        //     login: 'alexunconditional',
        //     email: 'alex@gmail.com',
        //     tel: '+7 (921) 987-65-43'
        // },
        // {
        //     id: 3,
        //     name: 'Алекснадр Безусловный',
        //     role: 'Менеджер',
        //     login: 'alexunconditional',
        //     email: 'alex@gmail.com',
        //     tel: '+7 (921) 987-65-43'
        // },
      ]
    }
  },

  validations() {
    return {
      organizationInfo: {
        name: { required },
        inn: { required },
        ogrn: { required },
        jur_address: { required },
        person: { required },
        phone: { required }
      }
    }
  },

  beforeMount() {
    this.getData()

    this.users[0].name = this.userEmail
  },

  emits: ['changeBlock', 'changeRegStatus', 'setLoading', 'nameChange'],

  computed: {
    ...mapWritableState(useTempStates, { storeChangeTerms: 'changeTermsRequest' }),
    ...mapWritableState(useTempStates, { storeChangePricelist: 'changePricelistRequest' }),
    ...mapWritableState(useUserToken, { userEmail: 'userEmail' }),

    getProfileStatus() {
      if (this.regStatus == 1) {
        return 'Подтвержден'
      } else {
        return 'На модерации'
      }
    },

    getConditions() {
      let condition = this.deliveryConditions.find((item) => item.id === this.currentCondition)
      return condition.value
    },

    isFile() {
      if (this.organizationInfo.file_link == null || this.organizationInfo.file_link == '') {
        return false
      } else {
        return true
      }
    }
  },

  methods: {
    SphereSelectHandler(option) {
      this.sphereValue.name = option.name
      this.sphereValue.value = option.value
    },

    async TypeSelectChange(option) {
      this.TypeSelectHandler(option)
      this.$emit('setLoading', true)
      await this.getActivity()
      this.$emit('setLoading', false)
    },

    TypeSelectHandler(option) {
      this.typeValue.name = option.name
      this.typeValue.value = option.value
    },

    async changePricelist() {
      let result = await serverRequest('GET', 'json', 'profile/change_pricelist_request')

      if (result != 'error' && result != 'unathorized') {
        this.storeChangePricelist = true
      } else {
        //alert('Ошибка запроса')
      }
    },

    async changeTerms() {
      let result = await serverRequest('GET', 'json', 'profile/change_conditions_request')

      if (result != 'error' && result != 'unathorized') {
        this.storeChangeTerms = true
      } else {
        //alert('Ошибка запроса')
      }
    },

    async getData() {
      console.log('get data')
      this.$emit('setLoading', true)

      let result = await serverRequest('POST', 'json', 'get_company_profile_info')

      if (result != 'error' && result != 'unathorized') {
        this.$emit('changeRegStatus', result.organization_status_code)
        this.$emit('nameChange', result.name)

        this.organizationInfo.status = result.organization_status
        this.organizationInfo.name = result.name
        this.organizationInfo.website = result.website
        this.organizationInfo.inn = result.inn
        this.organizationInfo.kpp = result.kpp
        this.organizationInfo.ogrn = result.ogrn
        this.organizationInfo.jur_address = result.legal_address
        this.organizationInfo.fact_address = result.fact_address
        this.organizationInfo.bank = result.bank
        this.organizationInfo.checking_acc = result.checking_account
        this.organizationInfo.cor_acc = result.correspondent_account
        this.organizationInfo.bik = result.bik
        this.organizationInfo.person = result.contact_person
        this.organizationInfo.phone = result.phone
        this.organizationInfo.extra_phone = result.extra_phone
        this.organizationInfo.file_name = result.file_name
        this.organizationInfo.file_link = result.file_link

        this.managerInfo.name = result.manager.name
        this.managerInfo.email = result.manager.email
        this.managerInfo.tel = result.manager.tel

        this.currentCondition = result.delivery_conditions

        // let newUser = {
        //     name: this.storeUserEmail,
        //     role: ''
        // }

        // if (result.users = 0) {
        //     this.users = []
        // }
        // else {
        //     this.users = result.users
        // }

        this.sphereId = result.activity_field
        let typeOption = this.typeOptions.find((item) => item.value == result.company_type)
        this.TypeSelectHandler(typeOption)

        await this.getActivity()
      } else {
        //alert('Ошибка запроса')
      }

      this.$emit('setLoading', false)
    },

    async getActivity() {
      let formData = {
        organization_type: this.typeValue.value
      }

      let result = await serverRequest('POST', 'json', 'get_activity_field', formData)

      if (result != 'error' && result != 'unathorized') {
        let options = []
        for (let item of result.items) {
          let option = {
            name: item.title,
            value: item.id
          }
          options.push(option)
        }
        this.sphereOptions = options

        let sphereOption = this.sphereOptions.find((item) => item.value == this.sphereId)

        if (sphereOption != undefined && sphereOption != null) {
          this.SphereSelectHandler(sphereOption)
        } else {
          this.SphereSelectHandler(this.sphereOptions[0])
        }
      }
    },

    async saveData() {
      this.v$.$validate()

      if (!this.v$.$error && this.phoneBindedObject.completed == true) {
        this.$emit('setLoading', true)

        let formData = {
          name: this.organizationInfo.name,
          web_site: this.organizationInfo.website,
          activity_field: this.sphereValue.value,
          company_type: this.typeValue.value,
          inn: this.organizationInfo.inn,
          kpp: this.organizationInfo.kpp,
          ogrn: this.organizationInfo.ogrn,
          legal_address: this.organizationInfo.jur_address,
          fact_address: this.organizationInfo.fact_address,
          bank: this.organizationInfo.bank,
          checking_account: this.organizationInfo.checking_acc,
          correspondent_account: this.organizationInfo.cor_acc,
          bik: this.organizationInfo.bik,
          contact_person: this.organizationInfo.person,
          phone: this.organizationInfo.phone,
          extra_phone: this.organizationInfo.extra_phone,
          file: ''
        }

        console.log(formData)

        let result = await serverRequest('POST', 'json', 'set_company_reg_info', formData)

        if (result != 'error' && result != 'unathorized') {
          this.$emit('nameChange', this.organizationInfo.name)

          let result = await serverRequest('POST', 'json', 'get_company_profile_info')

          if (result != 'error' && result != 'unathorized') {
            this.$emit('changeRegStatus', result.organization_status_code)
          }

          this.$emit('setLoading', false)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.profile-block {
  &__header {
    display: flex;
    align-items: flex-start;

    column-gap: 8px;

    &__status {
      padding: 1px 8px;

      @extend %text-regular;
      line-height: 24px;

      color: #035aa6;

      border-radius: 2px;
      border: 1px solid #035aa6;
      background-color: #e6f3ff;
    }
  }

  .mobile-header {
    display: none;
  }

  .file-download {
    margin-top: 8px;

    display: flex;
    align-items: center;
    column-gap: 8px;

    span {
      @extend %text-regular;
      color: var(--blue);
    }

    svg {
      flex-shrink: 0;
    }
  }

  .profile-page__general-information {
    max-width: 1000px;
    width: 100%;

    .input-container {
      max-width: 520px;
    }

    .v-select {
      max-width: 520px;
    }

    .input-button {
      display: flex;
      align-items: flex-end;
      column-gap: 12px;

      .input-container {
        width: 100%;
        flex-shrink: 0;
      }

      &__button {
        width: fit-content;
        padding: 13px 16px;
        background-color: $bw4;
        border-radius: 4px;
        white-space: nowrap;

        @extend %text-regular;
        color: $bw1;

        transition: background-color 0.3s;

        &:hover {
          background-color: $bw3;
        }
      }
    }
  }
}

.profile-block-row {
  display: flex;
  column-gap: 8px;
  align-items: center;

  padding: 12px 16px;
  border-radius: 4px 4px 0 0;
  background-color: $bw6;

  @extend %text-regular;
  color: $bw0;

  p {
    @extend %text-regular;
    color: $bw0;
  }

  &:first-of-type {
    margin-top: 24px;
  }

  &:not(:first-of-type) {
    border-top: 1px solid $bw3;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.profile-status-block__content {
  .manager-name {
    margin-top: 24px;

    @extend %H2;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }

  .manager-row {
    @extend %text-regular;
    color: $bw1;

    span {
      margin-left: 12px;

      @extend %text-regular;
      color: $bw0;
    }

    margin-top: 12px;

    &:first-of-type {
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .profile-block {
    .profile-page__general-information {
      max-width: 580px;

      .input-container {
        max-width: 580px;
      }

      .v-select {
        max-width: 580px;
      }

      .input-button {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        column-gap: 12px;
        row-gap: 12px;
      }
    }

    .profile-page__other-information {
      max-width: 580px;

      .inputs-container {
        flex-direction: column;
      }

      .input-container.input-360 {
        max-width: 100%;
      }

      .input-container.input-380 {
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .profile-block {
    .profile-page {
      &__container {
        flex-direction: column-reverse;

        margin-top: 32px;
      }

      &__general-information {
        max-width: 100%;

        .input-container,
        .v-select {
          max-width: 100%;
        }
      }

      &__other-information {
        max-width: 100%;
      }
    }

    .mobile-header {
      display: flex;
    }

    .desc-header {
      display: none;
    }

    .profile-status-block-wrapper {
      width: 100%;
      max-width: 100%;
      position: relative;
    }

    .profile-status-block {
      position: static;
      top: 0;
    }

    .profile-status-block {
      max-width: 100%;

      &__name {
        width: 100%;
      }
    }
  }
}
</style>
