
<template>
  <div class="marketing-page">
    <custom-header class="title" :title="'Маркетинг'"></custom-header>
    <router-view></router-view>
  </div>
</template>

<script>
import CustomHeader from '../components/CustomHeader.vue';

import { mapWritableState } from 'pinia'
import { useBreadcrumbs } from '../stores/breadcrumbs';

export default {
  name: 'MarketingView',
  components: {
    CustomHeader,
  },

  data() {
    return {
    }
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'marketing',
        name: 'Маркетинг'
      },
    ]
  },

  computed: {
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),
  },

  methods: {

  }
}
</script>

<style lang="scss">
.marketing-page {
  @extend %white-bg-block;

  width: 100%;
  min-width: 0;

  &__container {
    position: relative;

    &.loading {
      pointer-events: none;
    }
  }

  .marketing-filter-block {
    margin-top: 32px;

    display: flex;
    align-items: center;
    column-gap: 16px;

    .filter-category {
      label {
        border-radius: 4px;
      }
    }
  }

  &__components {
    margin-top: 32px;
  }

  .title {
    width: fit-content;

    &::after {
      width: 100%;
    }
  }
}
</style>
