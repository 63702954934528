<template>
    <div class="amount-block">
        <div class="amount-block__decrease" @click="decreaseAmount()" :class="{ disabled: !isDecreaseAvailable }">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 7L8 12L13 17" stroke="black" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </div>
        <input type="number" v-model="localAmount" class="amount-block__amount" :class="{ edited: canEdited }"
            :readonly="!canEdited" @input="onInput">
        <div class="amount-block__increase" @click="increaseAmount()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 17L15 12L10 7" stroke="black" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AmountBlock',
    props: {
        amount: {
            type: Number,
            default() {
                return 0
            }
        },
        canEdited: {
            type: Boolean,
            default() {
                return false
            }
        }
    },

    emits: ['amountChange'],

    data() {
        return {
            localAmount: 1
        }
    },

    watch: {
        amount(newAmount, oldAmount) {
            this.localAmount = newAmount
        }
    },

    beforeMount() {
        this.localAmount = this.amount
    },


    computed: {
        isDecreaseAvailable() {
            if (this.amount == 1) {
                return false
            }
            else {
                return true
            }
        }
    },

    methods: {
        decreaseAmount() {
            if (this.amount > 1) {
                this.$emit('amountChange', this.amount - 1)
            }
        },

        increaseAmount() {
            this.$emit('amountChange', this.amount + 1)
        },

        onInput() {
            this.$emit('amountChange', this.localAmount)
        }

    }

}
</script>

<style lang="scss">
.amount-block {
    border-radius: 6px;
    background-color: var(--bw4);
    padding: 6px 4px;

    display: flex;
    align-items: center;
    column-gap: 2px;

    width: fit-content;

    &__decrease {
        cursor: pointer;
        height: 24px;


        svg {
            path {
                transition: stroke .3s;
            }
        }

        &.disabled {
            pointer-events: none;

            svg {
                path {
                    stroke: var(--bw3);
                }
            }
        }
    }

    &__increase {
        cursor: pointer;
        height: 24px;
    }

    &__amount {
        @extend %text-regular;
        color: var(--bw0);
        min-width: 28px;
        max-width: 28px;
        line-height: 24px;
        text-align: center;

        background-color: transparent;

        user-select: none;

        &.edited {
            max-width: 40px;
        }
    }
}

@media screen and (max-width: 1600px) {
    .amount-block {
        padding: 2px;
    }
}

@media screen and (max-width: 1300px) {
    .amount-block {
        padding: 6px 4px;
    }
}
</style>