<template>
    <div class="main-search" :class="{ color }">
        <input class="main-search__input" v-model="storeSearchValue" type="text" placeholder="Поиск"
            @keypress.enter="searchGoods" @blur="blurHandler">
        <svg @click="searchGoods" class="main-search__icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21 21L16.6569 16.6569M16.6569 16.6569C18.1046 15.2091 19 13.2091 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19C13.2091 19 15.2091 18.1046 16.6569 16.6569Z"
                stroke="#424F60" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>
</template>

<script>
import { mapWritableState } from 'pinia';
import { useSearchValue } from '../stores/searchValue';

export default {
    name: 'MainSearch',

    setup() {

    },

    props: {
        color: {
            type: String,
            default() {
                return 'gray'
            }
        },
    },

    emits: ['search'],

    data() {
        return {
        }
    },

    computed: {
        ...mapWritableState(useSearchValue, { storeSearchValue: 'searchValue' })
    },

    methods: {
        searchGoods() {
            if (this.storeSearchValue != '') {
                if (this.$route.name != 'search-goods') {
                    this.$router.push({ name: 'search-goods' })
                }
                this.$emit('search')
            }
            else {
                if (this.$route.name == 'search-goods') {
                    this.$emit('search')
                    this.$router.push({ name: 'categories' })
                }
            }
        },

        blurHandler() {
            if (this.storeSearchValue == '') {
                this.searchGoods()
            }
        }
    }
}
</script>

<style lang="scss">
.main-search {
    position: relative;

    width: 100%;

    &__input {
        width: 100%;

        @extend %text-regular;
        color: var(--bw0);

        &::placeholder {
            @extend %text-regular;
            color: var(--bw3);
        }

        padding: 12px 44px 12px 16px;

        border-radius: 6px;
        border: 1px solid var(--bw6);

        background-color: var(--bw6);

        transition: border .3s;

        &:focus {
            border-color: var(--bw0);
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        right: 16px;
    }
}


@media screen and (max-width: 800px) {
    .main-search {
        &.gray {
            .main-search__input {
                background-color: $bw4;
            }
        }

        &.white {
            .main-search__input {
                background-color: $bw6;
            }
        }
    }

}
</style>