<template>
    <div class="status-container">
        <status-block v-for="status in statuses" :text="storeGetStatusText(status)" :code="status"></status-block>
    </div>
</template>

<script>
import StatusBlock from './StatusBlock.vue'

import { mapActions } from 'pinia'
import { useOrderStatuses } from '@/stores/orderStatuses';

export default {
    name: 'StatusContainer',

    components: {
        StatusBlock
    },

    props: {
        statuses: {
            type: Array,
            default() {
                return []
            }
        },
    },

    computed: {

    },

    methods: {
        ...mapActions(useOrderStatuses, { storeGetStatusText: 'getStatusText' }),
    }
}
</script>

<style lang="scss">
.status-container {
    display: flex;
    align-items: center;
    column-gap: 12px;
}
</style>