<template>
    <div class="appeal-item">
        <div class="appeal-item__top">
            <div>
                <p class="appeal-item__label">Тема обращения</p>
                <p class="appeal-item__value">{{ content.title }}</p>
            </div>
            <div>
                <p class="appeal-item__label">Дата загрузки</p>
                <p class="appeal-item__date">{{ content.date }}</p>
            </div>
            <div>
                <p class="appeal-item__label">Статус</p>
                <status-block :text="getStatusText(content.status)" :code="content.status"
                    :type="'appeals'"></status-block>
            </div>
            <button class="appeal-item__button" :class="{ 'disabled': content.status == 0 || content.feedback != null }"
                @click="isFeedbackForm = !isFeedbackForm">
                Оставить обратную связь
            </button>
        </div>
        <Transition>
            <div class="appeal-item__feedback-form" v-show="isFeedbackForm">
                <div class="text-area" :class="{ 'error': v$.text.$errors.length }">
                    <label for="comment">Комментарий</label>
                    <textarea id="comment" type="text" placeholder="Введите текст" v-model="text"></textarea>
                </div>
                <button class="appeal-item__button red" :class="{ 'disabled': messageSended }"
                    @click="sendFeedback">Отправить</button>
            </div>
        </Transition>
        <div v-if="content.response[0] != null" class="appeal-item__answer" :class="{ 'show': isAnswerShown }">
            <button @click="isAnswerShown = !isAnswerShown">
                <span>Ответ менеджера</span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 15L12 10L7 15" stroke="#424F60" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </button>
            <p v-for="response in content.response" class="appeal-item__answer-text">
                {{ response }}
            </p>
        </div>
        <div v-if="content.feedback != null" class="appeal-item__answer" :class="{ 'show': isFeedbackShown }">
            <button @click="isFeedbackShown = !isFeedbackShown">
                <span>Комментарий</span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 15L12 10L7 15" stroke="#424F60" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </button>
            <p class="appeal-item__answer-text">
                {{ content.feedback }}
            </p>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import StatusBlock from './StatusBlock.vue';

import { serverRequest } from '../api/request';

export default {
    name: 'RequestItem',

    components: {
        StatusBlock,
    },

    emits: ['feedbackSend'],

    props: {
        content: {
            type: Object,
            default() {
                return {
                    title: '',
                    date: '',
                    status: 0,
                    response: [],
                }
            }
        },
    },

    data() {
        return {
            v$: useVuelidate(),

            text: '',
            isFeedbackForm: false,
            isAnswerShown: false,
            isFeedbackShown: false,
            feedback: [],
            messageSended: false
        }
    },

    validations() {
        return {
            text: { required },
        }
    },

    created() {

    },

    beforeUnmount() {

    },

    computed: {

    },

    methods: {
        getStatusText(status) {
            switch (status) {
                case 0:
                    return 'На рассмотрении'
                case 1:
                    return 'Получен ответ'
                default:
                    return 'Статус'
            }
        },

        showAnswer(event) {
            let item = event.target.closest(".appeal-item");
            if (item.classList.contains("show")) {
                item.classList.remove("show")
            }
            else {
                item.classList.add("show")
            }
        },

        async sendFeedback() {
            this.v$.$validate();

            if (!this.v$.$error) {
                this.messageSended = true

                let formData = {
                    message: this.text,
                    request_id: this.content.id
                }

                let result = await serverRequest('POST', 'json', 'marketing/send_request_feedback', formData)

                if (result != 'error' && result != 'unathorized') {
                    this.isFeedbackForm = false
                    this.$emit("feedbackSend")
                }
                else {
                    //alert('Ошибка запроса')
                }

                this.messageSended = false
            }
        }
    }

}
</script>

<style lang="scss">
.appeal-item {
    .text-area {
        margin-top: 0;

        @extend %text-area;
    }

    &__feedback-form {
        margin-top: 24px;
        padding-top: 24px;

        border-top: 1px solid $bw3;
    }

    &__button {
        &.red {
            @extend %button;

            margin-top: 12px;

            width: fit-content;
        }
    }
}
</style>