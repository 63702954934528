<template>
  <div>
    <section v-if="!isTablet" class="specifications-section" :class="{ loading: isLoading }">
      <v-loading :is-loading="isLoading"></v-loading>
      <specification-popup
        :is-open="fileLoaderOpen"
        @close="fileLoaderOpen = false"
        @file-uploaded="onFileUpload"
      ></specification-popup>
      <custom-header :title="'Предзаказ'" :line="'fit'"></custom-header>
      <div class="buttons-container">
        <div class="specification-upload" @click="fileLoaderOpen = true">
          <label for="specification-upload">
            <span>Загрузить спецификацию</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 12L12 17M12 17L17 12M12 17L12 4"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 20H18"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </label>
        </div>
        <a class="download-btn" :href="storeSpecialization" download target="_blank">
          <span>Скачать шаблон xsxl</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 12L12 17M12 17L17 12M12 17L12 4"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 20H18"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
        <a class="download-btn right" :href="storePricelist" download target="_blank">
          <span>Скачать прайс-лист</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 12L12 17M12 17L17 12M12 17L12 4"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 20H18"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>
      <div class="specifications-table-container">
        <table class="specifications-table">
          <tr>
            <th class="first">№</th>
            <th>Номенклатура</th>
            <th>Артикул</th>
            <th class="price">Цена</th>
            <th class="multiplicity">Кратность, шт.</th>
            <th class="amount">Количество</th>
            <th class="unit">Ед. изм.</th>
            <th class="last"></th>
          </tr>
          <specification-row
            v-for="(specification, number) in specifications"
            :number="number + 1"
            :id="specification.id"
            :name="specification.name"
            :articul="specification.articul"
            :price="specification.price"
            :brand="specification.brand"
            :multiplicity="specification.multiplicity"
            :amount="specification.amount"
            :unit="specification.unit"
            :founded="specification.founded"
            :images="specification.images"
            :ids="getSpecificationIds"
            :user-token="userToken"
            @amountChange="amountChange"
            @nameChange="onNameChange"
            @set-founded="onSetFounded"
            @articul-change="onArticulChange"
            @specificationDelete="specificationDelete"
            @showProduct="onShowProduct"
            @specification-set="onSpecificationSet"
          ></specification-row>
        </table>
      </div>
      <button class="specifications-add-btn" @click="specificationAdd()">
        <span>Добавить</span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 12H5"
            stroke="#B6BEC9"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 5V19"
            stroke="#B6BEC9"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <div class="specifications-table-container summary">
        <table class="specifications-summary-table">
          <tr>
            <th>Всего позиций</th>
            <th>Скидка</th>
            <th>НДС</th>
            <th class="last">Итого с НДС</th>
          </tr>
          <tr>
            <td>{{ specificationLength }}</td>
            <td>0</td>
            <td>0</td>
            <td>{{ getFullSpecificationPrice }} руб.</td>
          </tr>
        </table>
      </div>
      <button
        class="specifications-section__order-btn"
        :class="{ disabled: !isOrderAvailable }"
        @click="createOrder"
      >
        Перейти к оформлению заказа
      </button>
    </section>
    <mobile-warning v-else></mobile-warning>
  </div>
</template>

<script>
import CustomHeader from '../../components/CustomHeader.vue'
import SpecificationRow from '../../components/SpecificationRow.vue'
import SpecificationPopup from '../../components/SpecificationPopup.vue'
import vLoading from '../../components/v-loading.vue'
import MobileWarning from '../../components/MobileWarning.vue'

import { mapWritableState, mapActions, mapState } from 'pinia'
import { useOrderInfo } from '@/stores/orderInfo'
import { useGeneralInfo } from '../../stores/generalInfo'
import { useBreadcrumbs } from '@/stores/breadcrumbs'

import { serverRequest } from '../../api/request'

export default {
  name: 'SpecificationView',
  components: {
    CustomHeader,
    SpecificationRow,
    SpecificationPopup,
    vLoading,
    MobileWarning
  },

  emits: ['specificationShow', 'showProduct'],

  props: {
    routeParams: {
      type: String,
      default() {
        return 'товары'
      }
    },
    userToken: {
      type: String,
      default() {
        return ''
      }
    }
  },

  data() {
    return {
      specificationHeader: '12345',
      newIdCount: 10000,

      fileLoaderOpen: false,

      isLoading: false,

      isTablet: false,

      specifications: [
        // {
        //   id: 0,
        //   name: 'Фреза сферическая0',
        //   articul: 'FBK0500219',
        //   price: 100000,
        //   multiplicity: '1/200',
        //   amount: 1,
        //   unit: 'м2'
        // },
        // {
        //   id: 1,
        //   name: 'Фреза сферическая1',
        //   articul: 'FBK0500219',
        //   price: 100000,
        //   multiplicity: '1/200',
        //   amount: 1,
        //   unit: 'м2'
        // },
        // {
        //   id: 2,
        //   name: 'Фреза сферическая2',
        //   articul: 'FBK0500219',
        //   price: 100000,
        //   multiplicity: '1/200',
        //   amount: 1,
        //   unit: 'м2'
        // },
        // {
        //   id: 3,
        //   name: 'Фреза сферическая3',
        //   articul: 'FBK0500219',
        //   price: 100000,
        //   multiplicity: '1/200',
        //   amount: 1,
        //   unit: 'м2'
        // },
        // {
        //   id: 4,
        //   name: 'Фреза сферическая4',
        //   articul: 'FBK0500219',
        //   price: 100000,
        //   multiplicity: '1/200',
        //   amount: 1,
        //   unit: 'м2'
        // }
      ]
    }
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'categories',
        name: 'Каталог'
      },
      {
        path: 'specifications',
        name: 'Спецификация'
      }
    ]

    if (document.documentElement.clientWidth <= 800) {
      this.isTablet = true
    } else {
      this.isTablet = false
    }
  },

  computed: {
    ...mapWritableState(useOrderInfo, { storeOrderNumber: 'orderNumber' }),
    ...mapWritableState(useOrderInfo, { storeOrderDate: 'orderDate' }),
    ...mapWritableState(useOrderInfo, { storeOrderProducts: 'orderProducts' }),
    ...mapWritableState(useOrderInfo, { storeCommercialOffer: 'сommercialOffer' }),
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),
    ...mapState(useGeneralInfo, { storePricelist: 'priceList' }),
    ...mapState(useGeneralInfo, { storeSpecialization: 'specialization' }),

    specificationLength() {
      return this.specifications.length
    },

    isAllFounded() {
      let count = 0
      this.specifications.forEach((item) => {
        if (item.founded == false) {
          count++
        }
      })
      if (count > 0) {
        return false
      } else {
        return true
      }
    },

    isOrderAvailable() {
      if (this.specificationLength > 0 && this.isAllFounded) {
        return true
      } else {
        return false
      }
    },
    TopSortByName() {
      if (this.sortByName == 'top') {
        return true
      } else {
        return false
      }
    },
    TopSortByPrice() {
      if (this.sortByPrice == 'top') {
        return true
      } else {
        return false
      }
    },
    getSpecificationIds() {
      let arr = []
      this.specifications.forEach((item) => {
        arr.push(item.id)
      })
      return arr
    },
    getFullSpecificationPrice() {
      let sum = 0
      this.specifications.forEach((item) => {
        sum += item.amount * item.price
      })

      return sum
    }
  },

  methods: {
    ...mapActions(useOrderInfo, { storeUpdateGood: 'updateGood' }),

    specificationAdd() {
      let newItem = {
        id: this.newIdCount,
        name: '',
        articul: '',
        price: '0',
        multiplicity: '-',
        amount: 1,
        unit: '-',
        founded: false
      }

      this.newIdCount++

      this.specifications.push(newItem)
    },

    amountChange(id, amount) {
      let specification = this.specifications.find((item) => item.id === id)
      specification.amount = amount
    },

    onNameChange(id, name) {
      let specification = this.specifications.find((item) => item.id === id)
      specification.name = name
    },

    onArticulChange(id, articul) {
      let specification = this.specifications.find((item) => item.id === id)
      specification.articul = articul
    },

    onSetFounded(id, status) {
      let specification = this.specifications.find((item) => item.id === id)
      specification.founded = status
    },

    specificationDelete(id) {
      this.specifications = this.specifications.filter((item) => {
        return item.id !== id
      })
    },

    onShowProduct(product) {
      this.$emit('showProduct', product)
    },

    onSpecificationSet(oldId, suggestion) {
      this.isLoading = true

      let specification = this.specifications.find((item) => item.id === oldId)
      specification.id = suggestion.id
      specification.name = suggestion.name
      specification.articul = suggestion.articul
      specification.price = suggestion.price
      specification.unit = suggestion.unit
      specification.multiplicity = suggestion.multiplicity
      specification.founded = true
      specification.images = suggestion.images
      specification.brand = suggestion.brand

      this.isLoading = false
    },

    async onFileUpload(file) {
      console.log(file)
      console.log(this.userToken)

      this.isLoading = true

      let formData = new FormData()

      formData.append('file', file)

      let result = await serverRequest(
        'POST',
        'formData',
        'specification/upload_specification',
        formData
      )

      if (result != 'error' && result != 'unathorized') {
        this.specifications = result.specifications

        this.specifications.forEach((item) => {
          item.founded = true
        })

        this.isLoading = false
      } else {
        //alert('Ошибка запроса')
      }
    },

    async createOrder() {
      this.isLoading = true

      this.storeOrderProducts = this.specifications

      let productArr = []

      for (let product of this.storeOrderProducts) {
        let newProduct = {
          id: product.id,
          amount: product.amount
        }
        productArr.push(newProduct)
      }

      let formData = {
        products: productArr
      }

      let result = await serverRequest('POST', 'json', 'create_order', formData)

      if (result != 'error' && result != 'unathorized') {
        this.storeOrderNumber = result.order_number
        this.storeOrderDate = result.order_date
        // this.storeCommercialOffer = result.commercial_offer

        for (let good of result.products) {
          this.storeUpdateGood(good.id, good.price, good.amount)
        }

        this.$router.push({ name: 'order' })
      } else {
        //alert('Ошибка запроса')
      }
    }
  }
}
</script>

<style lang="scss">
.specifications-section {
  position: relative;

  &.loading {
    pointer-events: none;
  }

  .buttons-container {
    margin-top: 32px;

    display: flex;
    align-items: center;

    column-gap: 20px;

    .specification-upload {
      input {
        position: absolute;
        z-index: -99;
        width: 1px;
        height: 1px;
        opacity: 0;
      }

      label {
        display: flex;
        column-gap: 12px;

        width: fit-content;

        padding: 12px 16px;

        border-radius: 4px;
        background-color: var(--primary);

        @extend %text-regular;
        color: var(--bw6);

        cursor: pointer;
      }
    }

    .download-btn {
      display: flex;
      column-gap: 12px;

      width: fit-content;

      padding: 12px 16px;

      border-radius: 4px;
      background-color: var(--bw1);

      @extend %text-regular;
      color: var(--bw6);

      &.right {
        margin-left: auto;
      }
    }
  }

  .specifications-table-container {
    margin-top: 16px;

    border-radius: 6px 6px 0 0;
    border: 1px solid var(--bw3);
    background-color: var(--bw6);

    &.summary {
      margin-top: 48px;
    }
  }

  .specifications-table {
    width: 100%;

    border-collapse: collapse;

    th {
      padding: 12px 40px 12px 40px;

      border-right: 1px solid var(--bw3);
      border-bottom: 1px solid var(--bw3);

      @extend %text-regular;
      color: var(--bw0);

      text-align: left;

      &.first {
        padding: 12px 26px 12px 26px;

        border-radius: 6px 0 0 0;
      }

      &.price {
        text-align: center;
      }

      &.amount {
        width: 200px;
        text-align: center;
      }

      &.multiplicity {
        width: 200px;
      }

      &.unit {
        border-right: none;
        width: 11%;
        text-align: center;
        padding: 12px 20px 12px 20px;
      }

      &.last {
        border-right: none;
        width: 48px;
        padding: 12px 12px;
      }
    }
  }

  .specifications-add-btn {
    width: 100%;

    display: flex;
    column-gap: 12px;
    justify-content: center;
    align-items: center;

    @extend %text-regular;
    color: var(--bw3);

    padding: 12px 0;

    border: 1px solid var(--bw3);
    border-radius: 0 0 6px 6px;

    border-top: none;

    background-color: rgba(255, 255, 255, 0.4);
  }

  .specifications-summary-table {
    width: 100%;

    border-collapse: collapse;

    th {
      width: 25%;

      padding: 12px;

      border-right: 1px solid var(--bw3);
      border-bottom: 1px solid var(--bw3);

      @extend %text-regular;
      color: var(--bw0);

      text-align: center;

      &.last {
        border-right: none;
      }
    }

    td {
      @extend %text-regular;
      color: var(--bw0);
      text-align: center;
      padding: 12px;
    }
  }

  &__order-btn {
    margin-top: 16px;

    width: fit-content;
    margin-left: auto;

    @extend %button;

    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.3s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
}

@media screen and (max-width: 1300px) {
  .specifications-section {
    .specifications-table {
      th {
        padding: 12px 8px;

        &.first {
          padding: 12px;
        }

        &.amount {
          width: 130px;
        }

        &.multiplicity {
          width: 130px;
        }

        &.unit {
          border-right: none;
          width: 11%;
          text-align: center;
          padding: 12px;
        }

        &.last {
          border-right: none;
          width: 48px;
          padding: 12px 12px;
        }
      }

      td {
        padding: 6px 8px;
      }
    }
  }
}
</style>
