<template>
  <section class="catalog-page__goods-section" :class="{ 'loading': isLoading }">
    <v-loading :is-loading="isLoading" :color="'white'"></v-loading>
    <custom-header class="catalog-page__goods-title" :title="goodsHeader"></custom-header>
    <div class="left-container ">
      <custom-header :title="goodsHeader"></custom-header>
      <div class="catalog-page__sort-view-block">
        <main-search class="goods-search"></main-search>
        <div class="filter-button" :class="{ 'active': filtersShowed }" @click="filtersShowed = !filtersShowed">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 16L21 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3 16H5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M8.5 18.5C9.88071 18.5 11 17.3807 11 16C11 14.6193 9.88071 13.5 8.5 13.5C7.11929 13.5 6 14.6193 6 16C6 17.3807 7.11929 18.5 8.5 18.5Z"
              stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19 8H21" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3 8H12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M15.5 10.5C16.8807 10.5 18 9.38071 18 8C18 6.61929 16.8807 5.5 15.5 5.5C14.1193 5.5 13 6.61929 13 8C13 9.38071 14.1193 10.5 15.5 10.5Z"
              stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <div class="sort-item" @click="sortByNameHandler()" :class="{ top: TopSortByName }">
          <span>По названию</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 11L12 16L17 11" stroke="#424F60" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
        <div class="sort-item" @click="sortByPriceHandler()" :class="{ top: TopSortByPrice }">
          <span>По цене</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 11L12 16L17 11" stroke="#424F60" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
        <div class="view-block">
          <div class="view-item">
            <input type="radio" id="view-type-1" name="view-type" value="normal" checked @change="viewType = 'normal'">
            <label for="view-type-1">
              <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect class="white-fill" x="0.5" y="0.5" width="33" height="33" rx="5.5" fill="white" />
                <g clip-path="url(#clip0_2275_57438)">
                  <mask id="mask0_2275_57438" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="9" y="9"
                    width="16" height="16">
                    <path d="M25 9H9V25H25V9Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_2275_57438)">
                    <path class="gray-fill"
                      d="M14.5248 9H10.8503C9.83003 9 9 9.83003 9 10.8503V14.5248C9 15.545 9.83003 16.375 10.8503 16.375H14.5248C15.545 16.375 16.375 15.545 16.375 14.5248V10.8503C16.375 9.83003 15.545 9 14.5248 9Z"
                      fill="#424F60" />
                  </g>
                  <mask id="mask1_2275_57438" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="9" y="9"
                    width="16" height="16">
                    <path d="M25 9H9V25H25V9Z" fill="white" />
                  </mask>
                  <g mask="url(#mask1_2275_57438)">
                    <path class="gray-fill"
                      d="M23.125 9H19.5C18.4661 9 17.625 9.84113 17.625 10.875V14.5C17.625 15.5339 18.4661 16.375 19.5 16.375H23.125C24.1589 16.375 25 15.5339 25 14.5V10.875C25 9.84113 24.1589 9 23.125 9Z"
                      fill="#424F60" />
                  </g>
                  <mask id="mask2_2275_57438" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="9" y="9"
                    width="16" height="16">
                    <path d="M25 9H9V25H25V9Z" fill="white" />
                  </mask>
                  <g mask="url(#mask2_2275_57438)">
                    <path class="gray-fill"
                      d="M14.5248 17.625H10.8503C9.83003 17.625 9 18.4551 9 19.4753V23.1497C9 24.17 9.83003 25 10.8503 25H14.5248C15.545 25 16.375 24.17 16.375 23.1497V19.4753C16.375 18.4551 15.545 17.625 14.5248 17.625Z"
                      fill="#424F60" />
                  </g>
                  <mask id="mask3_2275_57438" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="9" y="9"
                    width="16" height="16">
                    <path d="M25 9H9V25H25V9Z" fill="white" />
                  </mask>
                  <g mask="url(#mask3_2275_57438)">
                    <path class="gray-fill"
                      d="M23.125 17.625H19.5C18.4661 17.625 17.625 18.4661 17.625 19.5V23.125C17.625 24.1589 18.4661 25 19.5 25H23.125C24.1589 25 25 24.1589 25 23.125V19.5C25 18.4661 24.1589 17.625 23.125 17.625Z"
                      fill="#424F60" />
                  </g>
                </g>
                <rect x="0.5" y="0.5" width="33" height="33" rx="5.5" stroke="#424F60" />
                <defs>
                  <clipPath id="clip0_2275_57438">
                    <rect width="22" height="16" fill="white" transform="translate(6 9)" />
                  </clipPath>
                </defs>
              </svg>
            </label>
          </div>
          <div class="view-item">
            <input type="radio" id="view-type-2" name="view-type" value="plates" @change="viewType = 'plates'">
            <label for="view-type-2">
              <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect class="white-fill" x="0.5" y="0.5" width="33" height="33" rx="5.5" fill="white" />
                <g clip-path="url(#clip0_2029_16859)">
                  <path class="gray-fill"
                    d="M26.0664 8.62109H13.0898C12.0234 8.62109 11.1562 9.48863 11.1562 10.5547C11.1562 11.6212 12.0234 12.4883 13.0898 12.4883H26.0664C27.1325 12.4883 28 11.6212 28 10.5547C28 9.48863 27.1325 8.62109 26.0664 8.62109Z"
                    fill="#424F60" />
                  <path class="gray-fill"
                    d="M26.0664 15.0664H13.0898C12.0234 15.0664 11.1562 15.9339 11.1562 17C11.1562 18.0665 12.0234 18.9336 13.0898 18.9336H26.0664C27.1325 18.9336 28 18.0665 28 17C28 15.9339 27.1325 15.0664 26.0664 15.0664Z"
                    fill="#424F60" />
                  <path class="gray-fill"
                    d="M26.0664 21.5117H13.0898C12.0234 21.5117 11.1562 22.3793 11.1562 23.4453C11.1562 24.5118 12.0234 25.3789 13.0898 25.3789H26.0664C27.1325 25.3789 28 24.5118 28 23.4453C28 22.3793 27.1325 21.5117 26.0664 21.5117Z"
                    fill="#424F60" />
                  <path class="gray-fill"
                    d="M7.93359 8.62109C6.86711 8.62109 6 9.48863 6 10.5547C6 11.6212 6.86711 12.4883 7.93359 12.4883C8.99965 12.4883 9.86719 11.6212 9.86719 10.5547C9.86719 9.48863 8.99965 8.62109 7.93359 8.62109Z"
                    fill="#424F60" />
                  <path class="gray-fill"
                    d="M7.93359 15.0664C6.86711 15.0664 6 15.9339 6 17C6 18.0665 6.86711 18.9336 7.93359 18.9336C8.99965 18.9336 9.86719 18.0665 9.86719 17C9.86719 15.9339 8.99965 15.0664 7.93359 15.0664Z"
                    fill="#424F60" />
                  <path class="gray-fill"
                    d="M7.93359 21.5117C6.86711 21.5117 6 22.3793 6 23.4453C6 24.5118 6.86711 25.3789 7.93359 25.3789C8.99965 25.3789 9.86719 24.5118 9.86719 23.4453C9.86719 22.3793 8.99965 21.5117 7.93359 21.5117Z"
                    fill="#424F60" />
                </g>
                <rect x="0.5" y="0.5" class="gray-stroke" width="33" height="33" rx="5.5" stroke="#424F60" />
                <defs>
                  <clipPath id="clip0_2029_16859">
                    <rect width="22" height="22" fill="white" transform="translate(6 6)" />
                  </clipPath>
                </defs>
              </svg>
            </label>
          </div>
          <div class="view-item">
            <input type="radio" id="view-type-3" name="view-type" value="lines" @change="viewType = 'lines'">
            <label for="view-type-3">
              <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect class="white-fill" x="0.5" y="0.5" width="33" height="33" rx="5.5" fill="white" />
                <g clip-path="url(#clip0_2029_16880)">
                  <path class="gray-fill"
                    d="M27.0833 17.8978H6.91667C6.41066 17.8978 6 17.4871 6 16.9811C6 16.4751 6.41066 16.0645 6.91667 16.0645H27.0833C27.5893 16.0645 28 16.4751 28 16.9811C28 17.4871 27.5893 17.8978 27.0833 17.8978Z"
                    fill="#424F60" />
                  <path class="gray-fill"
                    d="M27.0833 10.87H6.91667C6.41066 10.87 6 10.4593 6 9.95329C6 9.44729 6.41066 9.03662 6.91667 9.03662H27.0833C27.5893 9.03662 28 9.44729 28 9.95329C28 10.4593 27.5893 10.87 27.0833 10.87Z"
                    fill="#424F60" />
                  <path class="gray-fill"
                    d="M27.0833 24.9251H6.91667C6.41066 24.9251 6 24.5145 6 24.0085C6 23.5025 6.41066 23.0918 6.91667 23.0918H27.0833C27.5893 23.0918 28 23.5025 28 24.0085C28 24.5145 27.5893 24.9251 27.0833 24.9251Z"
                    fill="#424F60" />
                </g>
                <rect x="0.5" y="0.5" width="33" height="33" rx="5.5" stroke="#424F60" />
                <defs>
                  <clipPath id="clip0_2029_16880">
                    <rect class="white-fill" width="22" height="22" fill="white" transform="translate(6 6)" />
                  </clipPath>
                </defs>
              </svg>
            </label>
          </div>
        </div>
      </div>
      <p class="catalog-page__empty-text" v-show="goods.length < 1">Товаров не найдено</p>
      <div class="catalog-page__goods-container" :class="viewType">
        <product-card v-for="good in goods" :key="good.id" :id="good.id" :amount="good.amount" :articul="good.articul"
          :brand="good.brand" :multiplicity="good.multiplicity" :unit="good.unit" :name="good.name" :price="good.price"
          :images="good.images" :attribute="good.attribute" :view="viewType" :added="good.added"
          @addedToCart="onAddedToCart" @amount-change="onAmountChange"></product-card>
      </div>
      <v-pagination class="catalog-page__pagination" v-show="isPagination" :totalPages="getTotalPages"
        :currentPage="currentPage" @page-change="pageChange"></v-pagination>
    </div>
    <div class="functional-block">
      <Transition>
        <form class="filter-block" ref="filterForm" :class="{ 'showed': filtersShowed }">
          <button class="filter-block__back" type="button" @click="filtersShowed = false">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 7L8 12L13 17" stroke="#424F60" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            <span>{{ goodsHeader }}</span>
          </button>
          <custom-header :title="'Фильтр'"></custom-header>
          <p class="filter-block__header">Фильтр по параметрам</p>
          <div class="filter-block__container" :class="{ 'scroll': showedFilters.length > 5 }">
            <template v-for="filter in filters">
              <catalog-filter v-if="filter.options.length > 1" :content="filter" @changed="onFilterChanged"
                ref="filters"></catalog-filter>
            </template>
            <catalog-filter :content="priceFilter" @changed="onPriceChanged" ref="priceFilter"></catalog-filter>
          </div>
          <div class="filter-block__buttons">
            <button class="filter-block__btn" type="button" @click="applyFilters">Показать</button>
            <button class="filter-block__btn gray" type="button" @click="resetFilters">Сбросить</button>
          </div>
        </form>
      </Transition>
      <div class="functional-block__links">
        <Transition>
          <router-link :to="{ name: 'cart' }" v-show="isGoodsInCart" class="download-button cart-button">Перейти в
            корзину</router-link>
        </Transition>
        <a class="download-button" :href="storePricelist" download target="_blank">
          <span>Скачать прайс-лист</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 12L12 17M12 17L17 12M12 17L12 4" stroke="white" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M6 20H18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </a>
      </div>
      <p class="information-block">
        <template v-if="regStatus != 1">
          По умолчанию в каталоге отражены цены для корпоративных клиентов. Окончательные цены при оформления заказа
          могут отличаться. Индивидуальные скидки и специальные условия поставки согласовываются с менеджером.
        </template>
        <template v-else>
          Скидка с учетом объема заказа будет учтена на этапе формирования счета на основании внутренней
          системы скидок
        </template>
      </p>
    </div>
  </section>
</template>

<script>
import ProductCard from '../../components/ProductCard.vue';
import CustomHeader from '../../components/CustomHeader.vue';
import vPagination from '../../components/v-pagination.vue'
import vCheckbox from '../../components/v-checkbox.vue';
import CatalogFilter from '../../components/CatalogFilter.vue';
import vLoading from '../../components/v-loading.vue';
import MainSearch from '../../components/MainSearch.vue';

import { mapState } from 'pinia'
import { useAddedGoods } from '@/stores/addedGoods'
import { useGeneralInfo } from '../../stores/generalInfo';
import { useUserToken } from '@/stores/userToken'

import { serverRequest } from '../../api/request';

export default {
  name: 'GoodsView',
  components: {
    ProductCard,
    CustomHeader,
    vPagination,
    vCheckbox,
    CatalogFilter,
    vLoading,
    MainSearch
  },

  emits: ['categoryChoosen', 'specificationShow', 'breadcrumbChange'],

  props: {
    routeParams: {
      type: String,
      default() {
        return 'товары'
      }
    },
    categoryId: {
      type: String,
      default() {
        return ''
      }
    },
    userToken: {
      type: String,
      default() {
        return ''
      }
    },
    activeCategory: {
      type: Number,
      default() {
        return 0
      }
    },
  },

  data() {
    return {
      isLoading: false,

      goodsHeader: '',

      filtersShowed: true,

      currentPage: 1,
      goodsOnPage: 12,
      allGoodsCount: 1,

      viewType: 'square',

      sortByName: 'bottom',
      sortByPrice: 'bottom',
      activeSort: 5,

      startPrice: null,
      endPrice: null,

      brands: [],
      countries: [],

      priceFilter: {
        header: 'Цена',
        id: 'priceFilter',
        options: [0, 0],
        type: 1
      },

      goods: [
        // {
        //   id: 1,
        //   amount: 1,
        //   articul: 'FBK0500219',
        //   name: "Корончатое сверло (фреза кольцевая) Ø13 L=30/63 мм №102 WELDON19 HSS M2 ТМ ПрофОснастка",
        //   price: '1496',
        //   images: [],
        //   multiplicity: '1/100',
        //   unit: 'шт'
        // },
      ],

      filters: [],
      activeFilters: [],
    }
  },

  beforeMount() {
    if (window.innerWidth < 1600) {
      this.filtersShowed = false
    }
    if (this.categoryId == null || this.categoryId == '') {
      this.$router.push({ name: 'categories' })
    }
    else {
      this.getFilters()
      this.getGoods()
    }
  },

  computed: {
    ...mapState(useAddedGoods, {
      storeAddedGoods: 'cartGoods'
    }),
    ...mapState(useGeneralInfo, { storePricelist: 'priceList' }),
    ...mapState(useUserToken, { regStatus: 'regStatus' }),

    TopSortByName() {
      if (this.sortByName == 'top') {
        return true
      }
      else {
        return false
      }
    },

    TopSortByPrice() {
      if (this.sortByPrice == 'top') {
        return true
      }
      else {
        return false
      }
    },

    showedFilters() {
      return this.filters.filter(filter => filter.options.length > 1)
    },

    getStartPrice() {
      if (this.startPrice == null) {
        return 0
      }
      else {
        return this.startPrice
      }
    },

    getEndPrice() {
      if (this.endPrice == null) {
        return null
      }
      else {
        return this.endPrice
      }
    },

    currentPosition() {
      return (this.currentPage - 1) * this.goodsOnPage
    },

    isPagination() {
      if (this.allGoodsCount > this.goodsOnPage) {
        return true
      }
      else {
        return false
      }
    },

    getTotalPages() {
      if (this.isPagination) {
        return Math.ceil(this.allGoodsCount / this.goodsOnPage)
      }
      else {
        return 1
      }
    },

    isGoodsInCart() {
      if (this.storeAddedGoods.length > 0) {
        return true
      }
      else {
        return false
      }
    }
  },

  methods: {
    getLabel(label, type) {
      if (typeof (label) == 'string') {
        return label
      }
      else {
        if (label == true) {
          return 'Да'
        }
        if (label == false) {
          return 'Нет'
        }
      }
    },

    sortByNameHandler() {
      console.log(this.sortByName);
      this.sortByPrice = 'bottom'

      if (this.sortByName == 'top') {
        this.sortByName = 'bottom'
        this.activeSort = 4
      }
      else {
        this.sortByName = 'top'
        this.activeSort = 3
      }

      this.currentPage = 1
      this.getGoods()
    },

    sortByPriceHandler() {
      this.sortByName = 'bottom'
      if (this.sortByPrice == 'top') {
        this.sortByPrice = 'bottom'
        this.activeSort = 1
      }
      else {
        this.sortByPrice = 'top'
        this.activeSort = 2
      }

      this.currentPage = 1
      this.getGoods()
    },

    onFilterChanged(status, id, value, type) {
      console.log(status, id, value, type);

      let currentFilter = this.activeFilters.find(item => item.id === id)

      if (currentFilter) {
        if (type == 0) {
          if (status == true) {
            currentFilter.options.push(value)
          }
          else {
            currentFilter.options = currentFilter.options.filter((item) => item != value)
            if (currentFilter.options.length == 0) {
              this.activeFilters = this.activeFilters.filter((item) => item.id != id)
            }
          }
        }

        if (type == 1) {
          if (status) {
            currentFilter.options = value
          }
          else {
            this.activeFilters = this.activeFilters.filter((item) => item.id != id)
          }
        }
      }
      else {
        if (type == 0) {
          currentFilter = {
            id: id,
            type: 0,
            options: [value]
          }
        }

        if (type == 1) {
          currentFilter = {
            id: id,
            type: 1,
            options: value
          }
        }
        console.log(currentFilter);

        this.activeFilters.push(currentFilter)
      }

      console.log(this.activeFilters);
    },

    onPriceChanged(status, id, value, type) {
      console.log(status, id, value, type);

      this.startPrice = value[0]
      this.endPrice = value[1]
    },

    pageChange(page) {
      this.currentPage = page
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.getGoods()
    },

    resetFilters() {
      this.$refs.filterForm.reset()
      this.$refs.priceFilter.reset()
      console.log(this.$refs.filters);
      this.$refs.filters.forEach(filter => {
        filter.reset()
      })
      this.activeFilters = []
      this.startPrice = null
      this.endPrice = null

      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.filtersShowed = false
      this.getGoods()
    },

    onAddedToCart(id) {
      let good = this.goods.find((item) => item.id === id)
      good.added = !good.added
    },

    onAmountChange(id, amount) {
      let good = this.goods.find((item) => item.id === id)
      good.amount = amount
    },

    checkProducts(array) {
      for (let good of array) {
        let cartGood = this.storeAddedGoods.find((item) => item.id == good.id)

        if (cartGood != undefined) {
          good.added = true
          good.amount = cartGood.amount
        }
        else {
          good.added = false
        }
      }
    },

    async getGoods() {
      this.isLoading = true

      let formData = {
        category: this.categoryId,
        sort: this.activeSort,
        price_start: this.getStartPrice,
        price_end: this.getEndPrice,
        filters: this.activeFilters,
        start_pos: this.currentPosition,
        amount: this.goodsOnPage
      }

      console.log(formData);

      let result = await serverRequest('POST', 'json', 'get_catalog_goods', formData)

      if (result != 'error' && result != 'unathorized') {
        this.allGoodsCount = result.all_goods_count
        this.goods = result.goods

        this.goodsHeader = result.subcategory

        let breadcrumbs = [
          {
            path: 'categories',
            name: "Категории"
          },
          {
            path: 'goods',
            params: {
              categoryId: this.categoryId
            },
            name: result.category
          },
          {
            path: 'goods',
            params: {
              categoryId: this.categoryId
            },
            name: result.subcategory
          }
        ]

        this.$emit('breadcrumbChange', breadcrumbs)

        this.checkProducts(this.goods)
      }
      else {
        //alert('Ошибка запроса')
      }

      this.isLoading = false
    },

    async getFilters() {
      this.isLoading = true

      let formData = {
        category: this.categoryId,
      }

      let result = await serverRequest('POST', 'json', 'get_catalog_filters', formData)

      if (result != 'error' && result != 'unathorized') {
        this.filters = result.filters;
      }
      else {
        //alert('Ошибка запроса')
      }

      this.isLoading = false
    },

    applyFilters() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.filtersShowed = false
      this.getGoods()
    }
  }
}
</script>


<style lang="scss">
.catalog-page {
  &__goods-title {
    display: none;
  }

  &__sort-view-block {
    display: flex;
    column-gap: 32px;
    align-items: center;

    margin-top: 24px;

    .sort-item {
      display: flex;
      align-items: center;
      column-gap: 4px;

      cursor: pointer;

      span {
        @extend %text-small;
        color: var(--bw1);
      }

      svg {
        transition: transform .3s;
      }

      &.top {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .view-block {
      display: flex;
      align-items: center;
      column-gap: 10px;

      margin-left: auto;

      .view-item {
        input {
          position: absolute;
          z-index: -99;
          width: 1px;
          height: 1px;
          opacity: 0;
        }

        label {
          cursor: pointer;
          display: block;
          height: 34px;

          svg {
            path {
              transition: fill .3s, stroke .3s;
            }
          }

          .gray-fill {
            fill: var(--bw1)
          }

          .white-fill {
            fill: var(--bw6)
          }

          .gray-stroke {
            stroke: var(--bw1)
          }
        }

        input:checked+label {
          .gray-fill {
            fill: var(--bw6)
          }

          .white-fill {
            fill: var(--bw1)
          }

          .gray-stroke {
            stroke: var(--bw6)
          }
        }
      }
    }
  }

  .filter-button {
    display: none;

    cursor: pointer;

    padding: 5px;
    border-radius: 6px;
    border: 1px solid $bw4;

    background-color: $bw4;

    transition: border .3s, background-color .3s;

    align-self: flex-start;

    height: 36px;

    svg {
      path {
        stroke: $bw0;
        transition: stroke .3s;
      }
    }

    &.active {
      border-color: $primary;
      background-color: $primary;

      svg {
        path {
          stroke: $bw6;
        }
      }
    }
  }

  .goods-search {
    display: none;
  }

  &__empty-text {
    margin-top: 32px;

    @extend %H2;
  }

  &__goods-section {
    margin-top: 32px;

    @extend %white-bg-block;

    display: flex;
    column-gap: 32px;

    position: relative;

    &.loading {
      pointer-events: none;
    }

    .left-container {
      width: 100%;
    }
  }

  &__goods-container {
    margin-top: 16px;

    width: 100%;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 16px;

    &.lines,
    &.plates {
      display: grid;

      grid-template-columns: 1fr;
    }
  }

  &__pagination {
    margin-top: 32px;
  }

  .functional-block {
    width: 100%;
    max-width: 316px;

    .filter-block {
      &__header {
        padding: 16px 24px;

        @extend %text-medium;
        color: var(--bw6);

        background-color: var(--bw1);
      }

      .custom-header {
        display: none;
      }

      &__back {
        display: none;
        column-gap: 4px;
        align-items: center;

        background-color: transparent;

        width: fit-content;

        span {
          @extend %text-regular;
          color: $bw1;
        }
      }

      .filter-item {
        &:not(:first-of-type) {
          margin-top: 1px;
        }

        &.show {
          .filter-item__header {
            svg {
              transform: rotate(180deg);
            }
          }

          .filter-item__list {
            display: grid;
          }
        }

        background-color: var(--bw4);

        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          padding: 16px 24px;

          @extend %text-medium;
          color: var(--bw0);

          cursor: pointer;

          svg {
            flex-shrink: 0;
            transition: transform .3s;
          }
        }

        &__list {
          padding: 20px 24px 16px;

          grid-template-columns: 1fr 1fr;
          column-gap: 28px;
          row-gap: 16px;

          display: none;
        }

        &.lines {
          .filter-item__list {
            grid-template-columns: 1fr;
          }
        }

        &.price {
          .filter-item__list {
            display: flex;
            column-gap: 12px;
            align-items: center;

            width: 100%;

            p {
              @extend %text-regular;
              color: $bw0;
            }

            input {
              background-color: $bw6;
              padding: 5px 15px;
              border-radius: 6px;

              border: 1px solid $bw6;

              max-width: 120px;

              transition: border .3s;

              @extend %text-regular;
              color: $bw1;

              &.error {
                border-color: red;
              }
            }
          }
        }
      }

      &__container {
        max-height: 310px;

        &.scroll {
          overflow-y: scroll;

          &::-webkit-scrollbar {
            width: 6px;
          }

          &::-webkit-scrollbar-track {
            background: $bw6;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $bw3;
            border-radius: 10px;
            border: none;
          }
        }
      }

      &__buttons {
        margin-top: 1px;

        display: flex;
        column-gap: 12px;

        padding: 16px 24px;


        background-color: var(--bw4);
      }

      &__btn {
        padding: 5px 6px;

        display: block;

        width: 100%;
        text-align: center;

        border-radius: 6px;
        border: 1px solid var(--primary);

        background-color: var(--primary);

        @extend %text-regular;
        color: var(--bw6);

        &.gray {
          color: var(--bw1);
          border-color: var(--bw1);
          background-color: transparent;
        }
      }
    }

    &__links {
      margin-top: 24px;
    }
  }

  .download-button {
    margin-top: 12px;

    @extend %button;

    display: flex;
    column-gap: 12px;
    justify-content: center;

    span {
      @extend %text-regular;
    }
  }

  .cart-button {
    margin-top: 0;
  }

  .information-block {
    margin-top: 24px;

    padding: 12px 16px;

    @extend %text-regular;
    color: var(--bw0);

    border-radius: 4px;
    background-color: var(--bw4);

  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.3s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
}


@media screen and (max-width: 1600px) {
  .catalog-page {
    &__goods-section {
      flex-direction: column-reverse;
    }

    &__goods-title {
      display: block;
      order: 3;
    }

    .filter-button {
      display: block;
    }

    .left-container {
      //margin-top: ;

      .custom-header {
        display: none;
      }
    }

    .functional-block {
      width: 100%;
      max-width: 100%;

      display: flex;
      flex-direction: column;

      position: relative;

      .filter-block {
        margin: 0;

        width: 316px;

        position: absolute;
        z-index: 3;
        left: 0;
        bottom: -65px;

        transform: translateY(100%);

        background-color: $bw6;

        transition: opacity .3s, visibility .3s;

        opacity: 0;

        visibility: collapse;

        &.showed {
          opacity: 1;
          visibility: visible;
        }

        .filter-item {
          // &__header {
          //   background-color: var(--bw4);
          //   height: 100%;
          // }

          // &__list {
          //   background-color: var(--bw4);
          // }

          &:not(:first-of-type) {
            margin-top: 0;
          }
        }

        &__btn {
          padding: 5px 6px;

          display: block;

          width: 100%;
          text-align: center;

          border-radius: 6px;
          border: 1px solid var(--primary);

          background-color: var(--primary);

          @extend %text-regular;
          color: var(--bw6);
        }
      }

      &__links {
        margin-top: 24px;

        order: -1;

        .download-button {
          width: 320px;
        }
      }
    }

    .information-block {
      order: -1;

      max-width: 700px;
    }
  }
}

@media screen and (max-width: 800px) {
  .catalog-page {
    &__goods-section {
      margin-top: 0;
    }

    .goods-search {
      display: block;

      .main-search__input {
        background-color: $bw4;
      }
    }

    .information-block {
      max-width: 100%;
    }

    &__sort-view-block {
      column-gap: 12px;

      .sort-item {
        display: none;
      }

      .view-block {
        display: none;
      }
    }

    .filter-button {
      width: 48px;
      height: 48px;
      flex-shrink: 0;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .functional-block {
      &__links {
        display: none;
      }

      .filter-block {
        padding: 16px 14px 0;

        position: fixed;
        left: 0;
        top: 80px;
        bottom: auto;
        transform: translateY(0) translateX(100%);
        max-width: 100%;
        width: 100%;
        height: calc(100vh - 84px);

        display: flex;
        flex-direction: column;

        visibility: visible;
        opacity: 1;

        transition: transform .5s;

        &__container {
          max-height: auto;

          .filter-item:last-of-type {
            border-radius: 0 0 4px 4px;
          }
        }

        &__back {
          display: flex;
        }

        &__buttons {
          margin-top: auto;

          flex-direction: column;
          row-gap: 12px;
          background-color: transparent;
          padding: 0;
        }

        &__btn {
          padding: 11px;

          &.gray {
            background-color: $bw1;
            color: $bw6;
          }
        }

        .custom-header {
          display: block;

          margin-top: 16px;
        }

        &__header {
          margin-top: 24px;

          border-radius: 4px 4px 0 0;
        }

        &.showed {
          transform: translateY(0) translateX(0);
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .catalog-page {
    &__goods-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>