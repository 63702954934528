<template>
    <section class="profile-page__content register-block">
        <div class="profile-block__header mobile-header">
            <custom-header :title="'Об организации'"></custom-header>
        </div>
        <div class="profile-page__container">
            <div class="left">
                <div class="profile-page__general-information">
                    <custom-header :title="'Об организации'" class="desc-header"></custom-header>
                    <div class="input-container mt32" :class="{ error: v$.organizationInfo.name.$errors.length }">
                        <label for="name">Название организации</label>
                        <input id="name" type="text" v-model="organizationInfo.name">
                    </div>
                    <div class="input-container">
                        <label for="website">Веб-сайт (Дополнительно)</label>
                        <input id="website" type="text" v-model="organizationInfo.website">
                    </div>
                    <div class="input-container">
                        <label for="name" class="tooltip-label">Тип организации <info-tooltip
                                :text="'Корпоративный клиент - закупка без дальнейшей перепродажи.\nОптовый клиент - дальнейшая перепродажа'"></info-tooltip></label>
                    </div>
                    <v-select :select-id="2" :select-name="'Тип компании'" :options="typeOptions"
                        :selected="typeValue.name" :show-label="false" @select="TypeSelectChange"></v-select>
                    <v-select class="select" :select-id="1" :select-name="'Сфера деятельности'" :options="sphereOptions"
                        :selected="sphereValue.name" @select="SphereSelectHandler" :scroll="true"></v-select>
                    <div class="input-container">
                        <label for="pricelist" class="tooltip-label">
                            Прайс-лист
                            <info-tooltip
                                :text="'По умолчанию вам будет доступен корпоравтиный прайс-лист, после прохождения модерации прайс-лист обновится в соотвтевии с подтвержденым типом вашей компании'"></info-tooltip>
                        </label>
                        <input class="disabled-input" id="pricelist" type="text" disabled value="Корпоративный">
                    </div>
                </div>
                <div class="profile-page__other-information">
                    <file-upload @fileUploaded="onFileUploaded" @file-deleted="onFileDeleted"></file-upload>
                    <h3>Реквизиты</h3>
                    <div class="inputs-container mt32">
                        <div class="input-container" :class="{ error: v$.organizationInfo.inn.$errors.length }">
                            <label for="inn">ИНН</label>
                            <input id="inn" type="number" v-model="organizationInfo.inn">
                        </div>
                        <div class="input-container">
                            <label for="kpp">КПП</label>
                            <input id="kpp" type="number" v-model="organizationInfo.kpp">
                        </div>
                        <div class="input-container" :class="{ error: v$.organizationInfo.ogrn.$errors.length }">
                            <label for="ogrn">ОГРН / ОГРНИП</label>
                            <input id="ogrn" type="number" v-model="organizationInfo.ogrn">
                        </div>
                    </div>
                    <div class="input-container" :class="{ error: v$.organizationInfo.jur_address.$errors.length }">
                        <label for="juridical_adress">Юридический адрес</label>
                        <input id="juridical_adress" type="text" v-model="organizationInfo.jur_address">
                    </div>
                    <div class="input-container">
                        <label for="fact_address">Фактический адрес (Дополнительно)</label>
                        <input id="fact_address" type="text" v-model="organizationInfo.fact_address">
                    </div>
                    <h3>Банковский счет</h3>
                    <div class="input-container input-360">
                        <label for="fact_address">Банк обслуживания (Дополнительно)</label>
                        <input id="fact_address" type="text" v-model="organizationInfo.bank">
                    </div>
                    <div class="inputs-container">
                        <div class="input-container">
                            <label for="checking_acc">Расчетный счет (Дополнительно)</label>
                            <input id="checking_acc" type="number" v-model="organizationInfo.checking_acc">
                        </div>
                        <div class="input-container">
                            <label for="cor_acc">Корр. счет (Дополнительно)</label>
                            <input id="cor_acc" type="number" v-model="organizationInfo.cor_acc">
                        </div>
                        <div class="input-container">
                            <label for="bik">БИК (Дополнительно)</label>
                            <input id="bik" type="number" v-model="organizationInfo.bik">
                        </div>
                    </div>
                    <h3>Контакты</h3>
                    <div class="inputs-container">
                        <div class="input-container" :class="{ error: v$.organizationInfo.person.$errors.length }">
                            <label for="inn">Контактное лицо ЛПР</label>
                            <input id="inn" type="text" v-model="organizationInfo.person">
                        </div>
                        <div class="input-container" :class="{ error: v$.organizationInfo.phone.$errors.length }">
                            <label for="phone">Контактный номер</label>
                            <input v-maska="phoneBindedObject" data-maska="+7 (###) ###-##-##" id="phone" type="text"
                                placeholder="+7 (___) ___ - __ - __" v-model="organizationInfo.phone">
                        </div>
                    </div>
                    <div class="input-container input-380">
                        <label for="extra-contact">Доп. контакт</label>
                        <input v-maska="extraPhoneBindedObject" data-maska="+7 (###) ###-##-##" id="extra-contact"
                            type="text" placeholder="+7 (___) ___ - __ - __" v-model="organizationInfo.extra_phone">
                    </div>
                </div>
                <button class="profile-status-block__button" @click="saveData()">Сохранить</button>
                <button class="profile-status-block__button gray" @click="skipStep()">Пропустить</button>
            </div>
            <div class="profile-status-block-wrapper">
                <div class="profile-status-block">
                    <div class="profile-status-block__content">
                        <p class="profile-status-block__name">Профиль организации</p>
                        <p class="profile-status-block__status">Статус профиля<span>На модерации</span></p>
                        <button class="profile-status-block__button" @click="saveData()">Сохранить</button>
                        <button class="profile-status-block__button gray" @click="skipStep()">Пропустить</button>
                    </div>
                    <div class="profile-status-block__warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                stroke="#D93030" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path
                                d="M13.5 17.1001C13.5 17.9285 12.8284 18.6001 12 18.6001C11.1716 18.6001 10.5 17.9285 10.5 17.1001C10.5 16.2717 11.1716 15.6001 12 15.6001C12.8284 15.6001 13.5 16.2717 13.5 17.1001Z"
                                fill="#D93030" />
                            <path d="M12 13.2002L12 6.6002" stroke="#D93030" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                        <p>Рекомендуем вам сразу заполнить карточку компании, чтобы впоследствии не возвращаться к этому
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import CustomHeader from '../CustomHeader.vue';
import InfoTooltip from '../InfoTooltip.vue';
import vSelect from '../v-select.vue';
import FileUpload from '../FileUpload.vue';

import { vMaska } from "maska"

import { serverRequest } from '../../api/request';

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import { mapWritableState } from 'pinia'
import { useUserToken } from '@/stores/userToken'

export default {
    name: 'loginBlock',

    components: {
        CustomHeader,
        InfoTooltip,
        vSelect,
        FileUpload,
    },

    directives: { maska: vMaska },

    props: {
        userToken: {
            type: String,
            default() {
                return ''
            }
        },
        isProfileRegistration: {
            type: Boolean,
            default() {
                return false
            }
        },
        companyName: {
            type: String,
            default() {
                return ''
            }
        },
    },

    emits: ['setLoading', 'nameChange'],

    data() {
        return {
            v$: useVuelidate(),
            phoneBindedObject: {
                masked: "",
                unmasked: "",
                completed: false
            },
            extraPhoneBindedObject: {
                masked: "",
                unmasked: "",
                completed: false
            },
            organizationInfo: {
                name: 'Название организации',
                website: '',
                inn: null,
                kpp: null,
                ogrn: null,
                jur_address: '',
                fact_address: '',
                bank: '',
                checking_acc: null,
                cor_acc: null,
                bik: null,
                person: '',
                phone: '',
                extra_phone: ''
            },

            file: null,

            sphereValue: {
                name: 'Производство',
                value: 1
            },
            typeValue: {
                name: 'Корпоративные закупки',
                value: 1
            },
            sphereOptions: [
                {
                    name: 'Производство',
                    value: 1
                },
                {
                    name: 'Ремонтная мастерская',
                    value: 2
                },
                {
                    name: 'Розничные продажи',
                    value: 3
                },
                {
                    name: 'Иная',
                    value: 4
                },
            ],
            typeOptions: [
                {
                    name: 'Корпоративные закупки',
                    value: 1
                },
                {
                    name: 'Оптовые закупки',
                    value: 2
                },
                {
                    name: 'Розничная сеть',
                    value: 3
                },
                {
                    name: 'Интернет магазин',
                    value: 4
                },
            ],
        };
    },

    validations() {
        return {
            organizationInfo: {
                name: { required },
                inn: { required },
                ogrn: { required },
                jur_address: { required },
                person: { required },
                phone: { required },
            }
        }
    },

    emits: ['changeBlock', 'setLoading'],

    computed: {
        ...mapWritableState(useUserToken, { regStatus: 'regStatus' }),
        ...mapWritableState(useUserToken, { storeCompanyName: 'companyName' }),
        ...mapWritableState(useUserToken, { storeFirstProfileEnter: 'firstProfileEnter' }),
    },

    beforeMount() {
        this.getData()
    },

    mounted() {
        this.organizationInfo.name = this.companyName
    },

    methods: {
        SphereSelectHandler(option) {
            this.sphereValue.name = option.name
            this.sphereValue.value = option.value
        },

        async TypeSelectChange(option) {
            this.TypeSelectHandler(option)
            this.$emit('setLoading', true)
            await this.getActivity()
            this.$emit('setLoading', false)
        },

        TypeSelectHandler(option) {
            this.typeValue.name = option.name
            this.typeValue.value = option.value
        },

        onFileUploaded(file) {
            this.file = file
        },

        onFileDeleted() {
            this.file = null
        },

        async getActivity() {
            let formData = {
                'organization_type': this.typeValue.value
            };

            let result = await serverRequest('POST', 'json', 'get_activity_field', formData)

            if (result != 'error' && result != 'unathorized') {
                let options = [];
                for (let item of result.items) {
                    let option = {
                        name: item.title,
                        value: item.id
                    }
                    options.push(option)
                }
                this.sphereOptions = options

                let sphereOption = this.sphereOptions.find((item) => item.value == this.sphereId)

                if (sphereOption != undefined && sphereOption != null) {
                    this.SphereSelectHandler(sphereOption)
                }
                else {
                    this.SphereSelectHandler(this.sphereOptions[0])
                }
            }
        },

        async getData() {
            console.log('get data');
            this.$emit('setLoading', true)

            let response = await fetch(import.meta.env.VITE_API_URL + 'get_company_profile_info', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + this.userToken
                },
            });

            if (response.ok) {
                let result = await response.json();
                console.log('запрос данных профиля');
                console.log(result);

                this.organizationInfo.inn = result.inn
                this.organizationInfo.kpp = result.kpp
                this.organizationInfo.ogrn = result.ogrn
                this.organizationInfo.jur_address = result.legal_address

                this.sphereId = result.activity_field;
                let typeOption = this.typeOptions[0]
                this.TypeSelectHandler(typeOption)

                await this.getActivity();

                this.$emit('setLoading', false)
            }
            else {
                this.$emit('setLoading', false)
            }
        },

        saveData() {
            if (this.file != null) {
                let formData = new FormData();

                console.log(this.file);

                formData.append("file", this.file)
                this.sendData(formData)
            }
            else {
                this.v$.$validate();
                if (!this.v$.$error && this.phoneBindedObject.completed == true) {
                    let formData = {
                        name: this.organizationInfo.name,
                        'web_site': this.organizationInfo.website,
                        'activity_field': this.sphereValue.value,
                        'company_type': this.typeValue.value,
                        'inn': this.organizationInfo.inn,
                        'kpp': this.organizationInfo.kpp,
                        'ogrn': this.organizationInfo.ogrn,
                        'legal_address': this.organizationInfo.jur_address,
                        'fact_address': this.organizationInfo.fact_address,
                        'bank': this.organizationInfo.bank,
                        'checking_account': this.organizationInfo.checking_acc,
                        'correspondent_account': this.organizationInfo.cor_acc,
                        'bik': this.organizationInfo.bik,
                        'contact_person': this.organizationInfo.person,
                        'phone': this.organizationInfo.phone,
                        'extra_phone': this.organizationInfo.extra_phone,
                    };
                    this.sendData(JSON.stringify(formData))
                }
            }
        },

        async sendData(formData) {
            console.log(formData);

            console.log(this.userToken);

            this.$emit('setLoading', true)

            let response = await fetch(import.meta.env.VITE_API_URL + 'set_company_reg_info', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + this.userToken
                },
                body: formData
            });

            let result = await response.json();

            console.log(result);

            if (result.status == 'ok') {
                this.regStatus = 3
                this.storeCompanyName = this.organizationInfo.name

                this.$router.push({ name: 'home' })
            }
            else {
                this.$emit('setLoading', false)
            }
        },

        skipStep() {
            this.storeFirstProfileEnter = false
            this.$router.push({ name: 'home' })
        }
    }
}
</script>

<style lang="scss">
.register-block {
    .mobile-header {
        display: none;
    }

    .left {
        .profile-status-block__button {
            display: none;
        }
    }
}

@media screen and (max-width: 1600px) {
    .register-block {
        .profile-page__container {
            column-gap: 40px;
        }

        .profile-page__general-information {
            max-width: 580px;

            .input-container {
                max-width: 580px;
            }

            .v-select {
                max-width: 580px;
            }

            .input-button {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                column-gap: 12px;
                row-gap: 12px;
            }
        }

        .profile-page__other-information {
            max-width: 580px;

            .inputs-container {
                flex-direction: column;
            }

            .input-container.input-360 {
                max-width: 100%;
            }

            .input-container.input-380 {
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .register-block {
        .profile-page {
            &__container {
                flex-direction: column-reverse;
                row-gap: 0;

                margin-top: 24px;
            }

            &__general-information {
                max-width: 100%;

                .input-container,
                .v-select {
                    max-width: 100%;
                }
            }

            &__other-information {
                max-width: 100%;
            }

            .profile-status-block {
                max-width: 100%;

                &__name {
                    width: 100%;
                }
            }
        }

        .mobile-header {
            display: block
        }

        .left {
            margin-top: 24px;

            .profile-status-block__button {
                display: block;

                &:first-of-type {
                    margin-top: 50px;
                }
            }
        }

        .desc-header {
            display: none;
        }

        .profile-status-block {
            row-gap: 24px;

            button {
                display: none;
            }

            &__warning {
                margin-top: 0;
            }

            &__content {
                padding: 0;

                background-color: transparent;
            }

            &__status {
                margin-top: 0;
            }

            &__name {
                display: none;
            }
        }

    }

    .profile-page {
        .register-block {
            .mt32 {
                margin-top: 0;
            }
        }
    }
}
</style>