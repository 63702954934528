<template>
    <Transition>
        <div class="v-popup product-popup" v-show="isOpen" @click.self="onWrapperClose">
            <section class="v-popup__block">
                <div class="v-popup__header">
                    <p>{{ product.articul }}</p>
                    <svg class="cross" @click="closePopup" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 18L6 6" stroke="black" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M18 6L6 18" stroke="black" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
                <div class="v-popup__container">
                    <div>
                        <p class="hit">Хит продаж</p>
                        <swiper class="product-slider" :modules="[Thumbs]" :thumbs="{ swiper: thumbsSwiper }"
                            :slides-per-view="1" :space-between="30">
                            <swiper-slide v-for="image in product.images">
                                <fancy-box class="box">
                                    <a data-fancybox="gallery" :href="image">
                                        <img :src="image" alt="изображение продукта" />
                                    </a>
                                </fancy-box>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div class="content">
                        <div>
                            <p class="articul">{{ product.articul }}</p>
                            <p class="name">{{ product.name }}</p>
                            <p class="brand">{{ product.brand }}</p>
                            <p class="multiplicity">КРАТНОСТЬ ОТГРУЗКИ:<br>{{ product.multiplicity }} {{ product.unit }}
                            </p>
                            <p class="price">{{ product.price }} ₽</p>
                            <p class="info">Поставляется под заказ</p>
                            <swiper class="thumbs-slider" :modules="[Thumbs]" watch-slides-progress
                                @swiper="setThumbsSwiper" :slides-per-view="'auto'" :space-between="12">
                                <swiper-slide v-for="image in product.images">
                                    <img :src="image" alt="изображение продукта">
                                </swiper-slide>
                            </swiper>
                        </div>
                        <router-link class="link" :to="{ name: 'product', params: { id: 0 } }">
                            <span>Перейти к товару</span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 12H21" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path d="M16 7L21 12L16 17" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </router-link>
                    </div>
                </div>
            </section>
        </div>
    </Transition>
</template>

<script>
import { ref } from 'vue';
import { Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import FancyBox from './FancyBox.vue';

export default {
    name: 'v-popup',

    components: {
        Swiper,
        SwiperSlide,
        FancyBox
    },

    setup() {
        const thumbsSwiper = ref(null);
        const setThumbsSwiper = (swiper) => {
            thumbsSwiper.value = swiper;
        };
        return {
            Thumbs,
            thumbsSwiper,
            setThumbsSwiper,
        };
    },

    props: {
        isOpen: {
            type: Boolean,
            default() {
                return false;
            }
        },
        product: {
            type: Object,
            default() {
                return {
                    id: 0,
                    articul: 'артикул',
                    name: 'название',
                    brand: 'brand',
                    multiplicity: '1/1',
                    images: [],
                    unit: 'шт',
                    price: 1000
                };
            }
        },
    },

    emits: ['close'],

    data() {
        return {
            isOptionListVisible: false,
        };
    },

    created() {
        window.addEventListener('scroll', this.scrollHanlder);
    },
    unmounted() {
        window.removeEventListener('scroll', this.scrollHanlder);
    },

    methods: {
        scrollHanlder(event) {
            if (this.isOpen) {
                event.preventDefault();
            }
        },

        closePopup() {
            this.$emit('close')
        },

        onWrapperClose(event) {
            //if (event.target)
            this.$emit('close')
        }

    },
}
</script>

<style lang="scss">
.v-popup {
    $padding: 40px;

    position: fixed;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
    z-index: 5;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.40);

    .product-slider {
        margin-top: 16px;

        width: 450px;
        height: 450px;

        .box {
            height: 100%;
        }

        a {
            display: block;
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            border-radius: 7px;
        }
    }

    .thumbs-slider {
        margin-top: 16px;

        padding-right: $padding;

        width: 100%;

        .swiper-slide {
            width: 72px;
            height: 72px;
            border-radius: 4px;

            border: 1px solid transparent;

            transition: border .3s;

            cursor: pointer;

            &.swiper-slide-thumb-active {
                border-color: var(--primary);
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
        }
    }

    .cross {
        cursor: pointer;


        path {
            transition: stroke .3s;
        }


        &:hover {
            path {
                stroke: var(--primary)
            }
        }
    }

    &__block {
        z-index: 10;

        border-radius: 6px;
        background: $bw6;

        width: 100%;
        max-width: 1000px;

        padding: $padding 0 $padding $padding;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding-right: $padding;

        p {
            @extend %H2;
            color: $bw0;
        }
    }

    &__container {
        display: flex;
        column-gap: 40px;

        margin-top: 18px;

        >div {
            width: 100%;

            min-width: 0;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .hit {
        width: fit-content;

        @extend %text-small;
        color: $bw6;

        padding: 2px 12px;

        border-radius: 29px;

        background-color: var(--primary);
    }

    .articul {
        @extend %text-regular;
        color: $bw3;

        padding-right: $padding;
    }

    .name {
        margin-top: 22px;

        @extend %H2;
        color: $bw1;
    }

    .brand {
        margin-top: 8px;

        @extend %text-regular;
        color: $bw3;
        padding-right: $padding;
    }

    .multiplicity {
        margin-top: 24px;

        width: 100%;

        padding-top: 16px;

        border-top: 1px solid $bw3;

        @extend %text-medium;
        color: var(--blue);
    }

    .price {
        margin-top: 12px;

        @extend %H2;
        color: var(--primary);
    }

    .info {
        margin-top: 8px;

        @extend %text-medium;
        color: var(--blue);
    }

    .link {
        @extend %link;
        font-weight: 500;

        padding: 12px 16px;

        width: fit-content;
    }
}

.product-popup {
    .v-popup__header {
        .cross {
            position: static;
        }
    }
}
</style>