import { defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'

export const useNotifications = defineStore('notifications', () => {
  const notifications = ref([])

  const storageNotification = sessionStorage.getItem('notifications')

  if (storageNotification) {
    notifications.value = JSON.parse(storageNotification)
  }

  watch(notifications, async (newVal, oldVal) => {
    sessionStorage.setItem('notifications', JSON.stringify(newVal))
  })

  const addNotification = (id, title, text, type, orderId) => {
    notifications.value.push({
      id,
      title,
      text,
      type,
      orderId
    })
  }

  const removeNotification = (id) => {
    let index = notifications.value.findIndex((item) => item.id === id)
    if (index !== -1) {
      notifications.value.splice(index, 1)
    }
    // notifications.value = notifications.value.filter((item) => item.id != id)
  }

  return {
    notifications,
    addNotification,
    removeNotification
  }
})
