<template>
    <section class="sample-request-block">
        <div v-if="!requestSended">
            <div class="sample-request-block__top">
                <div class="input-container" :class="{ 'error': isError }">
                    <label for="sample-name-good">Наименование или артикул товара</label>
                    <input type="text" id="sample-name-good" v-model="requestValue" @input="onInput"
                        @focus="isFocus = true" @blur="isFocus = false">
                    <ul class="suggestions"
                        :class="{ 'showed': suggestionsShowed }, { 'scroll': suggestions.length > 7 }">
                        <template v-for="suggestion in suggestions">
                            <li @click="onAplySuggestion(suggestion)">
                                {{ suggestion.name }}
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="change-amount-block">
                    <p>Количество</p>
                    <amount-block :amount="amount" @amount-change="onAmountChange"></amount-block>
                </div>
            </div>
            <button class="sample-request-block__btn" @click="sendRequest">Отправить заявку</button>
        </div>
        <template v-else>
            <p class="special-order-page__text">Запрос образца отправлен</p>
            <button class="special-order-page__btn" @click="requestSended = false">Создать новый</button>
        </template>
        <div class="request-history">
            <request-item v-for="appeal in history" :content="appeal" @feedback-send="getData"></request-item>
        </div>
        <v-pagination class="catalog-page__pagination" v-show="isPagination" :totalPages="getTotalPages"
            :currentPage="currentPage" @page-change="pageChange"></v-pagination>
    </section>
</template>

<script>
import StatusBlock from '../StatusBlock.vue';
import AmountBlock from '../AmountBlock.vue';
import vPagination from '../v-pagination.vue';
import RequestItem from '../RequestItem.vue';

import debounce from 'lodash.debounce'

import { serverRequest } from '../../api/request';

export default {
    name: 'SampleRequest',

    components: {
        StatusBlock,
        AmountBlock,
        vPagination,
        RequestItem
    },

    emits: ['setLoading'],

    props: {
        goodId: {
            type: String,
            default() {
                return ''
            }
        },
        goodName: {
            type: String,
            default() {
                return ''
            }
        },
        userToken: {
            type: String,
            default() {
                return ''
            }
        },
    },

    data() {
        return {
            id: null,
            requestSended: false,
            amount: 1,
            itemAmount: 10,
            allItems: 0,
            currentPage: 1,
            requestValue: '',
            history: [],
            choosed: false,
            isFocus: false,
            isError: false,
            suggestions: [],
            nameUpdate: debounce(() => {
                this.searchSuggestions(this.requestValue)
            }, 500),
        };
    },

    beforeMount() {
        this.getData();
    },

    mounted() {
        if (this.goodName != null && this.goodId != null) {
            this.requestValue = this.goodName
            this.id = this.goodId
            this.choosed = true
        }
    },

    computed: {
        suggestionsShowed() {
            if (this.requestValue.length > 3 && this.requestValue.length > 0 && this.isFocus == true) {
                return true
            }
            else {
                return false
            }
        },

        isPagination() {
            if (this.allItems > this.itemAmount) {
                return true
            }
            else {
                return false
            }
        },

        getTotalPages() {
            if (this.isPagination) {
                return Math.ceil(this.allItems / this.itemAmount)
            }
            else {
                return 1
            }
        }
    },

    methods: {
        onAmountChange(amount) {
            this.amount = amount
        },

        onInput() {
            this.choosed = false
            this.isError = false
            if (this.requestValue.length > 3) {
                this.nameUpdate()
            }
        },

        async searchSuggestions(value) {
            let formData = {
                value: value,
            }

            let result = await serverRequest('POST', 'json', 'marketing/search_goods', formData)

            if (result != 'error' && result != 'unathorized') {
                this.suggestions = result.specifications
            }
            else {
                //alert('Ошибка запроса')
            }
        },

        onAplySuggestion(suggestion) {
            this.suggestions = []

            this.id = suggestion.id
            this.requestValue = suggestion.name
            this.choosed = true
            this.isError = false
        },

        pageChange(page) {
            this.currentPage = page

            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.getData()
        },

        async getData() {
            this.$emit("setLoading", true);

            let formData = {
                type: 0,
                start_pos: this.itemAmount * (this.currentPage - 1),
                amount: this.itemAmount,
            }

            let result = await serverRequest('POST', 'json', 'marketing/get_requests', formData)

            if (result != 'error' && result != 'unathorized') {
                this.allItems = result.all_count
                this.history = result.requests
            }
            else {
                //alert('Ошибка запроса')
            }

            this.$emit("setLoading", false)
        },

        async sendRequest() {
            if (this.choosed) {
                this.$emit("setLoading", true);

                let formData = {
                    type: 0,
                    title: this.id,
                    quantity: this.amount
                }

                let result = await serverRequest('POST', 'json', 'marketing/requests', formData)

                if (result != 'error' && result != 'unathorized') {
                    this.choosed = false
                    this.isError = false
                    this.requestValue = ''
                    this.amount = 1

                    this.requestSended = true

                    this.$emit("setLoading", false)

                    this.getData()
                }
                else {
                    //alert('Ошибка запроса')
                }
            }
            else {
                this.isError = true
            }
        }
    }
}
</script>

<style lang="scss">
.sample-request-block {
    &__top {
        display: flex;
        align-items: center;
        column-gap: 40px;

        max-width: 510px;
    }

    &__btn {
        margin-top: 24px;

        @extend %button;
        width: 200px;
    }

    .input-container {
        position: relative;

        @extend %input-container;
    }

    .change-amount-block {
        p {
            @extend %text-regular;
            color: $bw1;
        }

        .amount-block {
            margin-top: 14px;

            margin-bottom: 6px;
        }
    }

    .suggestions {
        position: absolute;
        min-width: 100%;
        width: auto;
        left: 0;
        top: 85px;
        max-height: 0;
        overflow: hidden;

        transition: max-height .5s;

        z-index: 3;

        &.showed {
            max-height: 295px;
        }

        li {
            width: auto;

            padding: 6px 10px;
            @extend %text-regular;
            color: $bw0;
            border: 5px solid #F4F5F6;

            background-color: $bw6;

            cursor: pointer;

            white-space: nowrap;

            transition: background-color .3s;

            &:hover {
                background-color: #e9e9e9;
            }

            &:not(:last-of-type) {
                border-bottom: none;
            }
        }

        &.scroll {
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                background: $bw4;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $bw3;
                border-radius: 10px;
                border: none;
            }

            li {
                margin-right: -6px;
            }
        }
    }

    .request-history {
        margin-top: 32px;

        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }
}
</style>