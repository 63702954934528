<template>
  <tr class="payment-row">
    <td class="number left">№{{ number }}</td>
    <td class="date">{{ date }}</td>
    <td class="payment-term" :class="{ hidden: !isPaymentTerm }">{{ paymentTerm }}</td>
    <td class="delay">{{ delay }} Дня</td>
    <td class="sum">{{ parseInt(sum).toLocaleString('ru-RU') }} ₽</td>
    <td class="status">
      <status-block :text="status.text" :code="status.code" :type="'payments'"></status-block>
    </td>
    <td>
      <router-link class="button" :to="{ name: 'order-item', params: { id: number } }">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 12H21"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 7L21 12L16 17"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </router-link>
    </td>
  </tr>
</template>

<script>
import StatusBlock from './StatusBlock.vue'

export default {
  name: 'PaymentRow',

  components: {
    StatusBlock
  },

  props: {
    number: {
      type: Number,
      default() {
        return 0
      }
    },
    date: {
      type: String,
      default() {
        return 'дата'
      }
    },
    paymentTerm: {
      type: String,
      default() {
        return 'Артикул'
      }
    },
    delay: {
      type: Number,
      default() {
        return 0
      }
    },
    sum: {
      type: Number,
      default() {
        return 0
      }
    },
    status: {
      type: Object,
      default() {
        return {
          text: 'текст',
          code: 1
        }
      }
    }
  },

  computed: {
    isPaymentTerm() {
      if (this.paymentTerm != 'none') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
.payment-row {
  td {
    @extend %text-regular;
    color: var(--bw0);
    padding: 12px 0;
    text-align: center;

    border-top: 1px solid $bw3;

    &.left {
      text-align: left;
    }

    &.sum {
      font-weight: 600;
    }

    &.hidden {
      display: none;
    }

    .status-block {
      margin: 0 auto;
    }
  }

  .button {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    border-radius: 4px;
    width: 36px;
    height: 36px;
  }
}
</style>
