<template>
  <RouterView />
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'

import { mapWritableState } from 'pinia'
import { useUserToken } from '@/stores/userToken'

import { serverRequest } from './api/request'

export default {
  data() {
    return {
      timer: 1,
      activeInterval: null
    }
  },

  mounted() {
    if (this.userLogged) {
      console.log('logged');
      this.onLogged()
    }
  },

  watch: {
    userLogged(newAmount, oldAmount) {
      console.log('logged' + this.userLogged);
      if (this.userLogged == true) {
        this.onLogged()
      }
      if (this.userLogged == false) {
        this.onLogout()
      }
    },
  },

  computed: {
    ...mapWritableState(useUserToken, { userToken: 'token' }),
    ...mapWritableState(useUserToken, { userLogged: 'logged' }),
    ...mapWritableState(useUserToken, { userTokenTime: 'tokenTime' }),
    ...mapWritableState(useUserToken, { userLiveTime: 'tokenLiveTime' }),
  },

  methods: {
    onLogged() {
      this.activeInterval = setInterval(() => {
        this.userTokenTime = this.userTokenTime + 60;
        console.log(this.userTokenTime);

        if (this.userTokenTime % 300 == 0) {
          console.log('прошло 5 минут ' + this.userTokenTime);
        }

        if ((this.userTokenTime > (parseInt(this.userLiveTime) - 300))) {
          console.log('новый токен');

          this.newToken()
        }
      }, 60000);
    },

    onLogout() {
      if (this.activeInterval) {
        clearInterval(this.activeInterval)
        this.userTokenTime = 0
      }
    },

    async newToken() {
      let result = await serverRequest('POST', 'json', 'auth/refresh', null)

      if (result != 'error' && result != 'unathorized') {
        this.userToken = result.token
        this.userLiveTime = result.expires_in
        this.userTokenTime = 0
      }
      else {
        //alert('Ошибка запроса')
      }
    }
  }
}
</script>


<style scoped>
header {
  line-height: 1.5;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}
</style>
