import { defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'

export const useTempStates = defineStore('tempStates', () => {
  const changeTermsRequest = ref(false)
  const changePricelistRequest = ref(false)

  const storageChangeTermsRequest = sessionStorage.getItem('changeTermsRequest')
  const storageChangePricelistRequest = sessionStorage.getItem('changePricelistRequest')

  if (storageChangeTermsRequest) {
    changeTermsRequest.value = JSON.parse(storageChangeTermsRequest)
  }
  if (storageChangePricelistRequest) {
    changePricelistRequest.value = JSON.parse(storageChangePricelistRequest)
  }

  watch(changeTermsRequest, async (newVal) => {
    sessionStorage.setItem('changeTermsRequest', JSON.stringify(newVal))
  })

  watch(changePricelistRequest, async (newVal) => {
    sessionStorage.setItem('changePricelistRequest', JSON.stringify(newVal))
  })

  return {
    changeTermsRequest,
    changePricelistRequest
  }
})
