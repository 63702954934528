<template>
    <ul class="v-pagination">
        <li>
            <button :class="{ disabled: !prevArrow }" @click="previousPage()" type="button" class="v-pagination__arrow">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 7L8 12L13 17" stroke="#14181F" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </button>
        </li>
        <li v-for="page, pageNumber in totalPages" :key="pageNumber" ref="nav_items">
            <button type="button" class="v-pagination__number" @click="onClickPage(pageNumber + 1)">
                {{ pageNumber + 1 }}
            </button>
        </li>
        <li>
            <button :class="{ disabled: !nextArrow }" @click="nextPage()" type="button" class="v-pagination__arrow">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 17L15 12L10 7" stroke="#14181F" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </button>
        </li>
    </ul>
</template>

<script>
import { nextTick } from 'vue'

export default {
    name: 'v-pagination',
    props: {
        maxVisibleButtons: {
            type: Number,
            default() {
                return 3
            }
        },
        totalPages: {
            type: Number,
            required: true,
            default() {
                return 1
            }
        },
        currentPage: {
            type: Number,
            required: true,
            default() {
                return 1
            }
        },
    },
    emits: ['pageChange'],

    data() {
        return {
            activePage: 1,
            activeButton: null,
            nextArrow: true,
            prevArrow: true,
            prevOverflow: null,
            nextOverflow: null
        }
    },

    watch: {
        async totalPages() {
            this.activePage = 1

            await nextTick()

            this.checkButtons()
        }
    },

    methods: {
        onClickPage(page) {
            this.activePage = page
            this.pageChanged()
        },
        navOverflowHandler() {
            let navItemLength = this.$refs.nav_items.length;
            let currentNavNumber = this.activePage - 1;

            if (navItemLength > this.maxVisibleButtons) {
                let step = (this.maxVisibleButtons - 1) / 2
                let start = currentNavNumber - step;
                let end = currentNavNumber + step;

                if (currentNavNumber - step < 1) {
                    start = currentNavNumber
                    end = currentNavNumber + step * 2
                }

                if (currentNavNumber >= navItemLength - 1) {
                    start = currentNavNumber - step * 2
                    end = navItemLength
                }

                if (this.nextOverflow) {
                    this.nextOverflow.classList.remove("overflow");
                }

                if (this.prevOverflow) {
                    this.prevOverflow.classList.remove("overflow");
                }


                for (let i = 0; i < this.$refs.nav_items.length; i++) {
                    if (
                        i >= start &&
                        i <= end
                    ) {
                        this.$refs.nav_items[i].classList.remove("hidden");
                    }
                    else {
                        this.$refs.nav_items[i].classList.add("hidden");
                    }

                    if (i == end + 1) {
                        this.nextOverflow = this.$refs.nav_items[i];
                        this.nextOverflow.classList.remove("hidden");
                        this.nextOverflow.classList.add("overflow");
                    }

                    if (i == start - 1) {
                        this.prevOverflow = this.$refs.nav_items[i];
                        this.prevOverflow.classList.remove("hidden");
                        this.prevOverflow.classList.add("overflow");
                    }
                }
            }

            this.$refs.nav_items[0].classList.remove("hidden", "overflow");
            this.$refs.nav_items[navItemLength - 1].classList.remove("hidden", "overflow");
        },
        checkButtons() {
            if (this.activeButton) {
                this.activeButton.classList.remove('active');
            }
            this.activeButton = this.$refs.nav_items[this.activePage - 1].firstChild;
            this.activeButton.classList.add('active');
            this.checkArrows()
            this.navOverflowHandler()
        },
        pageChanged() {
            this.checkButtons()
            this.$emit('pageChange', this.activePage)
        },
        nextPage() {
            this.activePage++;
            this.pageChanged()
        },
        previousPage() {
            this.activePage--;
            this.pageChanged()
        },
        checkArrows() {
            if (this.activePage == 1) {
                this.prevArrow = false
            }
            else {
                this.prevArrow = true
            }
            if (this.activePage == this.totalPages) {
                this.nextArrow = false
            }
            else {
                this.nextArrow = true
            }
        }
    }
}
</script>

<style lang="scss">
.v-pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;

    li {
        &.overflow {
            button {
                color: transparent;

                &::after {
                    display: block;
                    content: "...";

                    color: var(--bw0);

                    text-align: center;

                    width: 100%;
                    height: 100%;

                    position: absolute;

                    top: 0;
                    right: 0;
                }
            }
        }

        &.hidden {
            display: none;
        }
    }


    &__number {
        position: relative;
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        border-radius: 3px;

        background-color: transparent;

        @extend %text-regular;
        color: var(--bw0);

        &.active {
            color: var(--bw6);
            background-color: var(--bw1);
            pointer-events: none;
        }
    }

    &__arrow {
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;

        background-color: transparent;

        cursor: pointer;

        &.disabled {
            pointer-events: none;
            opacity: 0.6;
        }
    }
}
</style>