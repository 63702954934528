<template>
    <div class="v-checkbox" :class="align">
        <input type="checkbox" :id="checkboxId" :name="checkboxName" :value="checkboxValue" :checked="checked"
            @change="onCheckboxChange">
        <label :for="checkboxId">{{ checkboxLabel }}</label>
    </div>
</template>

<script>
export default {
    name: 'v-checkbox',
    props: {
        checkboxName: {
            type: String,
            default() {
                return 'name'
            }
        },
        checkboxId: {
            type: String,
            default() {
                return 'checkbox'
            }
        },
        checkboxLabel: {
            type: String,
            default() {
                return 'Чекбокс'
            }
        },
        checkboxValue: {
            type: String,
            default() {
                return "Чекбокс"
            }
        },
        align: {
            type: String,
            default() {
                return "center"
            }
        },
    },
    emits: ['changed'],
    data() {
        return {
            checked: false
        }
    },
    methods: {
        onCheckboxChange() {
            this.checked = !this.checked;
            this.$emit('changed', this.checkboxId, this.checked)
        }
    }

}
</script>

<style lang="scss">
.v-checkbox {
    position: relative;

    input {
        position: absolute;
        width: 1px;
        height: 1px;
        z-index: -1;
        opacity: 0;
    }

    input:checked+label::before {
        background-color: var(--bw0);
        background-image: url("@/assets/icons/check.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 10px 8px;
    }

    label {
        display: flex;
        gap: 12px;
        align-items: center;

        cursor: pointer;

        @extend %text-regular;
        color: var(--bw0);

        user-select: none;

        &::before {
            content: "";
            width: 14px;
            height: 14px;
            display: block;
            border: 1px solid var(--bw0);
            border-radius: 2px;

            flex-shrink: 0;

            transition: background-color 0.3s, border 0.3s;
        }
    }

    &.top {
        label {
            align-items: flex-start;

            &::before {
                margin-top: 6px;
            }
        }
    }
}
</style>