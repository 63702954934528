<template>
    <tr class="specification-row" :class="{ 'hover': founded, 'not-found': !founded }">
        <td class="number">{{ number }}</td>
        <td class="name">
            <input type="text" v-model="localName" @input="onNameInput" placeholder="Название"
                @focus="isNameFocus = true" @blur="isNameFocus = false">
            <ul class="suggestions" :class="{ 'showed': nameSuggestionsShowed }">
                <template v-for="suggestion in nameSuggestions">
                    <li v-if="!isAlreadyExist(suggestion.id)" @click="onAplySuggestion(suggestion)">
                        {{ suggestion.name }}
                    </li>
                </template>
            </ul>
            <button class="show-product" v-show="founded" type="button" @click="showProduct">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M21.8701 11.4999C21.2301 10.3899 17.7101 4.81987 11.7301 4.99987C6.20007 5.13987 3.00007 9.99987 2.13007 11.4999C2.0423 11.6519 1.99609 11.8243 1.99609 11.9999C1.99609 12.1754 2.0423 12.3479 2.13007 12.4999C2.76007 13.5899 6.13007 18.9999 12.0201 18.9999H12.2701C17.8001 18.8599 21.0101 13.9999 21.8701 12.4999C21.9578 12.3479 22.004 12.1754 22.004 11.9999C22.004 11.8243 21.9578 11.6519 21.8701 11.4999ZM12.2201 16.9999C7.91007 17.0999 5.10007 13.4099 4.22007 11.9999C5.22007 10.3899 7.83007 7.09987 11.8301 6.99987C16.1201 6.88987 18.9401 10.5899 19.8301 11.9999C18.8001 13.6099 16.2201 16.8999 12.2201 16.9999Z"
                        fill="#424F60" />
                    <path
                        d="M12 8.5C11.3078 8.5 10.6311 8.70527 10.0555 9.08986C9.47993 9.47444 9.03133 10.0211 8.76642 10.6606C8.50152 11.3001 8.4322 12.0039 8.56725 12.6828C8.7023 13.3618 9.03564 13.9854 9.52513 14.4749C10.0146 14.9644 10.6383 15.2977 11.3172 15.4327C11.9961 15.5678 12.6999 15.4985 13.3394 15.2336C13.9789 14.9687 14.5256 14.5201 14.9101 13.9445C15.2947 13.3689 15.5 12.6922 15.5 12C15.5 11.0717 15.1313 10.1815 14.4749 9.52513C13.8185 8.86875 12.9283 8.5 12 8.5ZM12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5007 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z"
                        fill="#424F60" />
                </svg>
            </button>
        </td>
        <td class="articul">
            <input type="text" v-model="localArticul" @input="onArticulInput" placeholder="Артикул"
                @focus="isArticulFocus = true" @blur="isArticulFocus = false">

            <ul class="suggestions" :class="{ 'showed': articulSuggestionsShowed }">

                <template v-for="suggestion in articulSuggestions">
                    <li v-if="!isAlreadyExist(suggestion.id)" @click="onAplySuggestion(suggestion)">
                        {{ suggestion.articul }}
                    </li>
                </template>
            </ul>
        </td>
        <td class="price">{{ getPrice }} ₽</td>
        <td class="multiplicity">{{ multiplicity }}</td>
        <td class="amount">
            <amount-block :amount="amount" :canEdited="true" @amountChange="amountChange"></amount-block>
        </td>
        <td class="unit">{{ unit }}</td>
        <td class="delete">
            <button class="specification-row__delete-button" @click="$emit('specificationDelete', id)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                        stroke="#D93030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M15 9L9 15" stroke="#D93030" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M9 9L15 15" stroke="#D93030" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </button>
        </td>
    </tr>
</template>

<script>
import AmountBlock from './AmountBlock.vue'
import debounce from 'lodash.debounce'

import { serverRequest } from '../api/request'

export default {
    name: 'SpecificationRow',

    components: {
        AmountBlock
    },

    emits: ['specificationDelete', 'amountChange', 'nameChange', 'articulChange', 'showProduct', 'specificationSet', 'setFounded'],

    props: {
        number: {
            type: Number,
            default() {
                return 0
            }
        },
        id: {
            type: Number,
            default() {
                return 0
            }
        },
        name: {
            type: String,
            default() {
                return "Номенклатура"
            }
        },
        articul: {
            type: String,
            default() {
                return 'Артикул'
            }
        },
        price: {
            type: String,
            default() {
                return '0'
            }
        },
        brand: {
            type: String,
            default() {
                return 'Brand'
            }
        },
        images: {
            type: Array,
            default() {
                return []
            }
        },
        multiplicity: {
            type: String,
            default() {
                return '1/1'
            }
        },
        amount: {
            type: Number,
            default() {
                return 0
            }
        },
        unit: {
            type: String,
            default() {
                return 'шт'
            }
        },
        ids: {
            type: Array,
            default() {
                return []
            }
        },
        founded: {
            type: Boolean,
            default() {
                return false
            }
        },
        userToken: {
            type: String,
            default() {
                return ''
            }
        },
    },

    beforeMount() {
        this.localName = this.name
        this.localArticul = this.articul
    },

    data() {
        return {
            localName: '',
            localArticul: '',

            isNameFocus: false,
            isArticulFocus: false,

            nameSuggestions: [
            ],
            articulSuggestions: [
            ],
            nameUpdate: debounce(() => {
                this.searchSuggestions('name', this.localName)
            }, 500),
            articulUpdate: debounce(() => {
                this.searchSuggestions('articul', this.localArticul)
            }, 500)
        }
    },

    watch: {
        name(newAmount, oldAmount) {
            this.localName = newAmount
        },
        articul(newAmount, oldAmount) {
            this.localArticul = newAmount
        },
    },

    computed: {
        getPrice() {
            return Math.round(parseInt(this.price))
        },

        nameSuggestionsShowed() {
            if (this.localName.length > 3 && this.nameSuggestions.length > 0 && this.isNameFocus == true) {
                return true
            }
            else {
                return false
            }
        },

        articulSuggestionsShowed() {
            if (this.localArticul.length > 3 && this.articulSuggestions.length > 0 && this.isArticulFocus == true) {
                return true
            }
            else {
                return false
            }
        },
    },

    methods: {
        amountChange(amount) {
            this.$emit('amountChange', this.id, amount)
        },

        onNameInput() {
            //this.$emit('nameChange', this.id, this.localName)

            console.log(this.ids);


            if (this.founded == true) {
                this.$emit('setFounded', this.id, false)
            }
            else {
                if (this.localName.length > 1 && this.localName == this.name) {
                    this.$emit('setFounded', this.id, true)
                }
            }

            if (this.localName.length > 3) {
                this.nameUpdate()
            }

            if (this.localName == '') {
                this.nameSuggestions = []
            }
        },

        onArticulInput() {
            this.$emit('articulChange', this.id, this.localArticul)

            if (this.founded == true) {
                this.$emit('setFounded', this.id, false)
            }

            if (this.localArticul.length > 3) {
                this.articulUpdate()
            }

            if (this.localArticul == '') {
                this.articulSuggestions = []
            }
        },

        isAlreadyExist(id) {
            if (this.ids.indexOf(id) === -1) {
                return false
            }
            else {
                return true
            }
        },

        showProduct() {
            let product = {
                articul: this.articul,
                name: this.name,
                brand: this.brand,
                multiplicity: this.multiplicity,
                images: this.images,
                unit: this.unit,
                price: this.getPrice
            }
            this.$emit('showProduct', product)
        },

        async searchSuggestions(type, value) {
            let formData = {
                type: type,
                value: value,
            }

            console.log(formData);

            let result = await serverRequest('POST', 'json', 'specification/get_suggestion_list', formData)

            if (result != 'error' && result != 'unathorized') {
                if (type == 'name') {
                    this.nameSuggestions = result.specifications
                }

                if (type == 'articul') {
                    this.articulSuggestions = result.specifications
                }
            }
            else {
                //alert('Ошибка запроса')
            }
        },

        onAplySuggestion(suggestion) {
            this.nameSuggestions = []
            this.articulSuggestions = []

            this.$emit('specificationSet', this.id, suggestion)
        }
    }
}
</script>

<style lang="scss">
.specification-row {
    td {
        @extend %text-regular;
        color: var(--bw0);
        padding: 6px 0 6px 40px;

        position: relative;

        &.number {
            padding: 6px 0 6px 32px;
        }

        &.name {
            padding-right: 30px;
            text-overflow: ellipsis;

            input {
                background-color: transparent;
                @extend %text-regular;
                color: var(--bw0);
                width: 100%;
            }
        }

        .show-product {
            position: absolute;

            top: 50%;

            transform: translateY(-50%);

            right: 5px;

            background-color: transparent;

            z-index: 3;

            visibility: hidden;
            opacity: 0;

            transition: visibility .4s, opacity .4s;
        }

        &.articul {
            padding-right: 20px;

            input {
                background-color: transparent;
                @extend %text-regular;
                color: var(--bw0);
                width: 100%;
            }
        }

        &.multiplicity {
            padding: 6px;
            text-align: center;
            color: var(--bw3);
        }

        &.amount {
            padding: 6px;

            .amount-block {
                margin: 0 auto;
            }
        }

        &.unit {
            padding: 6px;
            text-align: center;
            user-select: none;
        }

        &.price {
            padding: 6px;

            text-align: center;
        }

        &.delete {
            padding: 12px;
        }

        .suggestions {
            position: absolute;
            min-width: 100%;
            width: auto;
            left: 22px;
            top: 55px;

            max-height: 0;
            overflow: hidden;

            transition: max-height .5s;

            z-index: 3;

            &.showed {
                max-height: 300px;
            }

            li {
                width: auto;

                padding: 6px 10px;
                @extend %text-regular;
                color: $bw0;
                border: 5px solid #F4F5F6;

                background-color: $bw6;

                cursor: pointer;

                white-space: nowrap;

                transition: background-color .3s;

                &:hover {
                    background-color: #e9e9e9;
                }

                &:not(:last-of-type) {
                    border-bottom: none;
                }
            }
        }
    }

    &__delete-button {
        display: block;

        background-color: transparent;
    }

    &.not-found {
        background-color: #F7D4D4;
    }

    &.hover:hover {
        .show-product {
            visibility: visible;
            opacity: 1;
        }
    }
}
</style>