<template>
    <nav class="app-breadcrumb">
        <template v-if="!isTablet">
            <router-link class="app-breadcrumb__item" :to="{ name: 'home' }">Главная</router-link>
            <template v-for="link in links">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 17L15 12L10 7" stroke="#424F60" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
                <router-link v-if="link.params != null" class="app-breadcrumb__item"
                    :to="{ name: link.path, params: link.params }">{{
            link.name }}</router-link>
                <router-link v-else class="app-breadcrumb__item" :to="{ name: link.path }">{{ link.name }}</router-link>
            </template>
        </template>

        <template v-else>
            <router-link class="app-breadcrumb__item tablet" :to="{ name: 'home' }">
                <svg v-if="links.length > 0" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 7L8 12L13 17" stroke="#424F60" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
                <span>Главная</span>
            </router-link>
        </template>
    </nav>
</template>

<script>
export default {
    name: 'AppBreadcrumb',

    setup() {

    },

    props: {
        links: {
            type: Array,
            default() {
                return []
            }
        },
    },

    data() {
        return {
            isTablet: false
        };
    },

    beforeMount() {
        if (document.documentElement.clientWidth <= 800) {
            this.isTablet = true
        }
        else {
            this.isTablet = false
        }
    },

    computed: {
        isBreadcrumb() {
            if (this.links.length > 0) {
                return true
            }
            else {
                return false
            }
        }
    },

    methods: {

    }
}
</script>

<style lang="scss">
.app-breadcrumb {
    padding-left: 24px;

    display: flex;
    column-gap: 16px;
    align-items: center;

    svg {
        flex-shrink: 0;
    }

    &__item {
        @extend %text-regular;
        color: var(--bw1);

        &.router-link-exact-active {
            pointer-events: none;
        }

        &.tablet {
            display: flex;
            align-items: center;

            svg {
                margin-right: 4px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .app-breadcrumb {
        padding-left: 0;

        column-gap: 4px;
    }
}
</style>