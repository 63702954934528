<template>
    <div class="replacement-item">
        <div class="replacement-item__header">
            <p>{{ good.articul }} - {{ good.name }} - {{ good.amount }} шт</p>
            <!-- <button class="replacement-item__button" @click="saveChoice">Сохранить выбор</button> -->
        </div>
        <div class="replacement-item__goods">
            <!-- <replacement-card v-for="good in replacements" :key="good.id" :id="good.id" :amount="good.amount"
                :articul="good.articul" :brand="good.brand" :multiplicity="good.multiplicity" :unit="good.unit"
                :name="good.name" :price="good.price" :images="good.images" :choosed="good.choosed"
                @cardChoosen="onCardChoosen" @amountChange="onAmountChange"></replacement-card> -->
            <p class="block-placeholder">Аналогичные товары не найдены</p>
        </div>
    </div>
</template>

<script>
import ReplacementCard from './ReplacementCard.vue'

export default {
    name: 'ReplacementItem',

    components: {
        ReplacementCard
    },

    props: {
        good: {
            type: Object,
            default() {
                return {
                    id: 1,
                    name: 'name',
                    articul: 'articul',
                    amount: 1,
                }
            }
        }
    },

    emits: ['saveChoice'],

    data() {
        return {
            activeReplacement: null,
            replacements: [
                {
                    id: 10,
                    amount: this.good.amount,
                    articul: 'FBK0500219',
                    name: "Молоток №102 WELDON19 HSS M2 ТМ ПрофОснастка",
                    price: 500,
                    image: "good.webp",
                    choosed: false,
                    images: []
                },
                {
                    id: 11,
                    amount: this.good.amount,
                    articul: 'FBK0500219',
                    name: "Гвоздь №102 WELDON19 HSS M2 ТМ ПрофОснастка",
                    price: 1000,
                    image: "good.webp",
                    choosed: false,
                    images: []
                },
            ]
        }
    },

    created() {

    },

    beforeUnmount() {

    },

    computed: {

    },

    methods: {
        onAmountChange(id, amount) {
            let good = this.replacements.find((item) => item.id === id)
            good.amount = amount
        },

        onCardChoosen(id) {
            if (this.activeReplacement) {
                this.activeReplacement.choosed = false
            }
            let good = this.replacements.find((item) => item.id === id)
            this.activeReplacement = good
            this.activeReplacement.choosed = true
        },

        saveChoice() {
            if (this.activeReplacement) {
                this.$emit("saveChoice", this.good.id, this.activeReplacement)
            }
        }
    }

}
</script>

<style lang="scss">
.replacement-item {
    padding-bottom: 16px;
    border-bottom: 1px solid $bw3;

    &__header {
        display: flex;
        align-items: center;
        column-gap: 12px;

        p {
            width: 100%;

            @extend %H2;
            color: $bw0
        }
    }

    &__button {
        @extend %button;
        background-color: $bw1;

        width: fit-content;

        white-space: nowrap;
    }

    &__goods {
        display: flex;
        column-gap: 36px;

        margin-top: 24px;

        .replacement-card {
            max-width: 260px;
        }
    }

    .block-placeholder {
        @extend %text-medium;

        padding-bottom: 100px;
    }
}
</style>