<template>
    <div class="alert-block" :class="color">
        <svg class="red" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                stroke="#D93030" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M13.5 17.1001C13.5 17.9285 12.8284 18.6001 12 18.6001C11.1716 18.6001 10.5 17.9285 10.5 17.1001C10.5 16.2717 11.1716 15.6001 12 15.6001C12.8284 15.6001 13.5 16.2717 13.5 17.1001Z"
                fill="#D93030" />
            <path d="M12 13.2002L12 6.6002" stroke="#D93030" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
        <svg class="green" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                stroke="#18B00B" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round" />
            <path d="M15 10L11 14L9 12" stroke="#18B00B" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
        <svg class="orange" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                stroke="#FF964A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M13 17.1001C13 17.6524 12.5523 18.1001 12 18.1001C11.4477 18.1001 11 17.6524 11 17.1001C11 16.5478 11.4477 16.1001 12 16.1001C12.5523 16.1001 13 16.5478 13 17.1001Z"
                fill="#FF964A" stroke="#FF964A" />
            <path d="M12 13.2L12 6.59995" stroke="#FF964A" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
        <p v-html="text"></p>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AlertBlock',

    props: {
        text: {
            type: String,
            default() {
                return "Статус"
            }
        },
        color: {
            type: String,
            default() {
                return "white"
            }
        }
    },
}
</script>

<style lang="scss">
.alert-block {
    display: flex;
    align-items: center;
    column-gap: 12px;

    padding: 12px 16px;

    background-color: var(--bw4);

    border-radius: 4px;

    width: fit-content;

    svg {
        flex-shrink: 0;
    }

    .green,
    .orange {
        display: none
    }

    p {
        @extend %text-regular;
        color: var(--bw0);

        a {
            @extend %text-regular;
            color: var(--blue);
            font-weight: 600;
        }
    }

    &.red {
        background-color: #F7D4D4;

        p {}
    }

    &.green {
        background-color: #EDFEEC;

        .red {
            display: none;
        }

        .green {
            display: block;
        }
    }

    &.orange {
        background-color: #FFF0E5;

        .red {
            display: none;
        }

        .orange {
            display: block;
        }
    }
}
</style>