<template>
    <div class="user-row">
        <!-- <div class="user-row__header" @click="toggleUser()"> -->
        <div class="user-row__header">
            <p>{{ name }}</p>
            <!-- <span>{{ role }}</span> -->
        </div>
        <Transition>
            <div class="user-row__dropdown" v-show="isOpen">
                <div class="user-row__content">
                    <p>Логин<span>{{ login }}</span></p>
                    <p>e-mail<span>{{ email }}</span></p>
                    <p>Контактный номер<span>{{ tel }}</span></p>
                </div>
                <button class="user-row__btn gray" type="button" @click="deleteUser()">Удалить пользователя</button>
            </div>
        </Transition>
    </div>
</template>

<script>
export default {
    name: 'UserRow',

    props: {
        id: {
            type: Number,
            default() {
                return 0
            }
        },
        name: {
            type: String,
            default() {
                return "имя"
            }
        },
        role: {
            type: String,
            default() {
                return "роль"
            }
        },
        login: {
            type: String,
            default() {
                return "login"
            }
        },
        email: {
            type: String,
            default() {
                return "Email"
            }
        },
        tel: {
            type: String,
            default() {
                return "телефон"
            }
        },
    },

    data() {
        return {
            isOpen: false
        }
    },


    methods: {
        toggleUser() {
            this.isOpen = !this.isOpen
        }
    }
}
</script>

<style lang="scss">
.user-row {
    &__header {
        display: flex;
        column-gap: 8px;
        align-items: center;
        justify-content: space-between;

        padding: 12px 16px;
        border-radius: 4px 4px 0 0;
        background-color: $bw6;

        @extend %text-regular;
        color: $bw0;

        // cursor: pointer;

        border-bottom: 1px solid $bw3;

        p {
            @extend %text-regular;
            color: $bw0;
        }
    }

    &__dropdown {
        padding-bottom: 24px;
    }

    &__content {
        padding: 12px 24px;
        border-radius: 0 0 4px 4px;
        background-color: $bw6;

        p {
            @extend %text-regular;
            color: $bw1;

            span {
                margin-left: 12px;

                @extend %text-regular;
                color: $bw0;
            }

            &:not(:first-child) {
                margin-top: 12px;
            }
        }
    }

    &__btn {
        margin-top: 12px;

        display: block;

        @extend %text-regular;
        color: $bw6;

        padding: 6px 12px;
        border-radius: 6px;
        background-color: $bw1;

        width: fit-content;

        margin-left: auto;

        transition: background-color .3s;

        &:hover {
            background-color: $primary;
        }
    }

    &:first-of-type {
        margin-top: 24px;
    }

    &:not(:first-of-type) {
        .user-row__header {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    &:last-of-type {
        .user-row__header {
            border-bottom: none;
        }

        .user-row__content {
            border-top: 1px solid $bw3;
        }
    }
}
</style>