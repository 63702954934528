import { defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'

export const useFaq = defineStore('faq', () => {
  const faq = ref(null)

  return {
    faq
  }
})
