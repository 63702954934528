import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

export const useAddedGoods = defineStore('addedGoods', () => {
  const cartGoods = ref([])

  const storageProducts = sessionStorage.getItem('cartProducts')

  if (storageProducts) {
    cartGoods.value = JSON.parse(storageProducts)
  }

  watch(
    cartGoods,
    async (newVal, oldVal) => {
      sessionStorage.setItem('cartProducts', JSON.stringify(newVal))
    },
    { deep: true }
  )

  const setAmount = (id, amount) => {
    console.log(id, amount)

    const idx = cartGoods.value.findIndex((el) => el.id == id)
    console.log(cartGoods.value[idx])

    cartGoods.value[idx].amount = amount
  }

  const replaceGood = (oldId, newId, amount) => {
    const idx = cartGoods.value.findIndex((el) => el.id === oldId)
    cartGoods.value[idx].id = newId
    cartGoods.value[idx].amount = amount
  }

  const deleteGood = (id) => {
    cartGoods.value = cartGoods.value.filter((el) => el.id != id)
  }

  return {
    cartGoods,
    setAmount,
    replaceGood,
    deleteGood
  }
})
