<template>
    <Transition>
        <div class="v-loading" v-show="isLoading" :class="{ 'white': isWhite }, size">
            <div class="v-loading__block" :class="circlePosition">
                <svg class="v-loading__circle" width="35" height="35" viewBox="0 0 35 35" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M34.5449 17.5471C34.5449 26.936 26.9338 34.5473 17.5449 34.5473C8.15608 34.5473 0.544922 26.936 0.544922 17.5471C0.544922 8.15813 8.15608 0.546875 17.5449 0.546875C26.9338 0.546875 34.5449 8.15813 34.5449 17.5471ZM4.45492 17.5471C4.45492 24.7766 10.3155 30.6373 17.5449 30.6373C24.7743 30.6373 30.6349 24.7766 30.6349 17.5471C30.6349 10.3176 24.7743 4.45692 17.5449 4.45692C10.3155 4.45692 4.45492 10.3176 4.45492 17.5471Z"
                        fill="#EFEFEF" />
                    <path
                        d="M2.49992 17.5471C1.4202 17.5471 0.533308 18.4252 0.657201 19.4978C0.965822 22.1697 1.90536 24.7401 3.40994 26.9919C5.27792 29.7876 7.93296 31.9665 11.0393 33.2532C14.1456 34.5399 17.5638 34.8766 20.8615 34.2206C24.1591 33.5647 27.1882 31.9456 29.5657 29.5681C31.9432 27.1905 33.5623 24.1614 34.2183 20.8637C34.8742 17.5659 34.5376 14.1478 33.2509 11.0414C31.9642 7.93501 29.7853 5.27994 26.9896 3.41193C24.7379 1.90734 22.1675 0.967793 19.4957 0.659161C18.4231 0.535261 17.5449 1.42217 17.5449 2.5019C17.5449 3.58163 18.425 4.44197 19.4927 4.60264C21.3897 4.88811 23.2091 5.58839 24.8173 6.66302C26.97 8.10138 28.6478 10.1458 29.6385 12.5377C30.6293 14.9296 30.8885 17.5616 30.3834 20.1009C29.8783 22.6401 28.6316 24.9725 26.8009 26.8032C24.9703 28.6339 22.6379 29.8806 20.0987 30.3857C17.5594 30.8908 14.9275 30.6316 12.5356 29.6408C10.1437 28.6501 8.09933 26.9723 6.66098 24.8196C5.58636 23.2113 4.88609 21.3919 4.60063 19.4949C4.43997 18.4272 3.57964 17.5471 2.49992 17.5471Z"
                        fill="#D93030" />
                </svg>
            </div>
        </div>
    </Transition>
</template>

<script>
import { size } from 'lodash'

export default {
    name: 'v-loading',
    props: {
        isLoading: {
            type: Boolean,
            default() {
                return false
            }
        },
        color: {
            type: String,
            default() {
                return 'gray'
            }
        },
        circlePosition: {
            type: String,
            default() {
                return 'center'
            }
        },
        size: {
            type: String,
            default() {
                return 'default'
            }
        },
    },
    data() {
        return {

        }
    },
    computed: {
        isWhite() {
            if (this.color == 'white') {
                return true
            }
            else {
                return false
            }
        }
    },
    methods: {

    }
}
</script>

<style lang="scss">
.v-loading {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    z-index: 5;

    border-radius: 2px;

    background-color: #f4f5f69d;

    pointer-events: none;

    &__block {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;

        &.top {
            align-items: flex-start;
            padding-top: 15%;
        }
    }

    &__circle {
        width: 60px;
        height: 60px;

        animation: spin 1.5s linear infinite;
    }

    &.white {
        background-color: #ffffffa8;
    }

    &.little {
        .v-loading__circle {
            width: 30px;
            height: 30px;
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>