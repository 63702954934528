
<template>
  <section>
    <div class="marketing-filter-block">
      <swiper class="category-slider" :slides-per-view="'auto'" :space-between="spaceBetween" :allow-touch-move="true"
        :grab-cursor="true" @reach-end="onReachEnd" @slider-move="onSliderMove" :class="{ 'gradient': !isSliderEnd }">
        <swiper-slide v-for="category, number in marketingCategories">
          <filter-category :id="category.id" :label="category.name" :name="'order-category'" :value="category.value"
            :checked="category.checked" @changed="onCategoryChange"></filter-category>
        </swiper-slide>
      </swiper>
    </div>
    <div class="marketing-page__container" :class="{ 'loading': isLoading }">
      <v-loading :is-loading="isLoading" :color="'white'"></v-loading>
      <transition name="component-fade" mode="out-in">
        <component class="marketing-page__components" v-bind:is="activePage" :user-token="userToken" :goodId="goodId"
          :goodName="goodName" @set-loading="onSetLoading"></component>
      </transition>
    </div>
  </section>
</template>

<script>
import FilterCategory from '../components/FilterCategory.vue';
import SampleRequest from '../components/marketing/SampleRequest.vue';
import CatalogRequest from '../components/marketing/CatalogRequest.vue';
import PromoMaterials from '../components/marketing/PromoMaterials.vue';
import MarketingLearning from '../components/marketing/MarketingLearning.vue';
import RetailOutlet from '../components/marketing/RetailOutlet.vue';
import PromoOffer from '../components/marketing/PromoOffer.vue'
import vLoading from '../components/v-loading.vue';

import { Swiper, SwiperSlide } from 'swiper/vue';

import { mapWritableState, mapState } from 'pinia';
import { useMarketingRequests } from '../stores/marketingRequests';
import { useUserToken } from '@/stores/userToken'

export default {
  name: 'MarketingPagesView',
  components: {
    FilterCategory,
    SampleRequest,
    CatalogRequest,
    PromoMaterials,
    MarketingLearning,
    RetailOutlet,
    PromoOffer,
    vLoading,
    Swiper,
    SwiperSlide,
  },

  props: {
    page: {
      type: String,
      default() {
        return ''
      }
    },
  },

  data() {
    return {
      activePage: 'SampleRequest',
      spaceBetween: 24,
      goodName: null,
      goodId: null,
      isLoading: false,
      isSliderEnd: false,
      breadcrumbList: [
        {
          path: 'marketing',
          name: 'Маркетинг'
        },

      ],
      marketingCategories: [
        {
          id: 'mark-sample',
          name: 'Запрос образцов',
          value: 'SampleRequest',
          checked: true
        },
        {
          id: 'mark-catalog',
          name: 'Запрос каталога',
          value: 'CatalogRequest',
          checked: false
        },
        {
          id: 'mark-promo',
          name: 'Промо-материалы',
          value: 'PromoMaterials',
          checked: false
        },
        {
          id: 'mark-learning',
          name: 'Обучение',
          value: 'MarketingLearning',
          checked: false
        },
        {
          id: 'mark-retail',
          name: 'Оформление розничной точки',
          value: 'RetailOutlet',
          checked: false
        },
        {
          id: 'mark-offer',
          name: 'Действующие акции и спецпредожения',
          value: 'PromoOffer',
          checked: false
        },
      ],
    }
  },

  beforeMount() {
    console.log(this.page);

    if (this.page != null && this.page != '') {
      this.activePage = this.page;

      this.marketingCategories.forEach((item) => {
        item.checked = false
      })


      let category = this.marketingCategories.find((item) => item.value == this.activePage)
      category.checked = true
    }

    if (this.storeName != '' && this.storeGoodId != '') {
      this.goodId = this.storeGoodId
      this.goodName = this.storeName
      this.storeName = ''
      this.storeGoodId = ''
    }

    if (document.documentElement.clientWidth <= 800) {
      this.spaceBetween = 12
    }
  },

  computed: {
    ...mapWritableState(useMarketingRequests, { storeRequest: 'request' }),
    ...mapWritableState(useMarketingRequests, { storeGoodId: 'id' }),
    ...mapWritableState(useMarketingRequests, { storeName: 'name' }),
    ...mapState(useUserToken, { userToken: 'token' }),
  },

  methods: {
    onCategoryChange(id, value) {
      this.activePage = value
      this.$router.push({ 'name': 'marketingPages', params: { page: value } })
    },

    onSetLoading(status) {
      this.isLoading = status
    },

    onReachEnd() {
      this.isSliderEnd = true
    },

    onSliderMove(slider) {
      if (slider.isEnd == false) {
        this.isSliderEnd = false
      }
    }
  }
}
</script>

<style lang="scss">
.marketing-page {
  @extend %white-bg-block;
  width: 100%;

  &__container {
    position: relative;

    &.loading {
      pointer-events: none;
    }
  }

  .marketing-filter-block {
    margin-top: 32px;

    min-width: 0;

    .filter-category {
      label {
        border-radius: 4px;
      }
    }
  }

  &__components {
    margin-top: 32px;
  }

  .title {
    width: fit-content;

    &::after {
      width: 100%;
    }
  }
}

.category-slider {
  width: 100%;

  position: relative;

  &::after {
    position: absolute;

    content: "";

    display: block;

    width: 60px;
    height: 100%;

    top: 50%;
    right: -5px;

    transform: translateY(-50%);

    background: linear-gradient(270deg, #FFF 66.15%, rgba(255, 255, 255, 0.00) 100%);

    z-index: 1;

    transition: opacity .3s;

    opacity: 0;
  }


  &.gradient {
    &::after {
      opacity: 1;
    }
  }

  .swiper-slide {
    width: fit-content;

    .filter-category {
      white-space: nowrap;
      width: fit-content;
    }
  }
}

@media screen and (max-width: 800px) {
  .category-slider {
    &::after {
      width: 20px;
      right: -10px;
    }
  }
}
</style>
