<template>
    <div class="mobile-warning">
        <div class="mobile-warning__top">
            <svg width="103" height="86" viewBox="0 0 103 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M102.353 62.1719C102.309 69.0919 97.051 72.1779 88.195 71.8189C79.341 71.4609 20.191 71.7119 11.747 71.3709C3.30401 71.0299 0.122013 65.6479 0.340013 58.8289C1.03501 37.2249 0.832013 15.6639 1.11001 11.1889C1.59001 3.47586 4.82801 -0.0771407 11.506 0.193859C16.893 0.411859 85.673 -0.285138 92.568 0.140862C100.431 0.627862 102.938 5.92086 102.648 13.0759C102.489 17.0349 102.482 41.6199 102.353 62.1719ZM8.43501 7.23986L7.88801 58.9819L95.676 59.4089L96.075 7.51086L8.43501 7.23986ZM62.689 75.7809C62.689 80.5259 70.617 82.0859 70.617 82.0859L70.044 85.3799L33.018 84.9349L32.768 81.7239C32.768 81.7239 40.244 80.4799 40.244 75.6359L62.689 75.7809Z"
                    fill="#424F60" />
            </svg>
            <p>Полный функционал доступен в компьютерной версии</p>
        </div>
        <router-link class="button" :to="{ name: 'home' }">Вернуться назад</router-link>
    </div>
</template>

<script>
export default {
    name: 'MobileWarning',

    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
.mobile-warning {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 222px);

    justify-content: space-between;

    padding-bottom: 10px;

    margin-bottom: -40px;

    &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 24px;
        flex-grow: 1;

        p {
            @extend %text-regular;
            color: $bw1;
            text-align: center;
        }
    }

    .button {
        @extend %button;
    }
}
</style>