<template>
  <div class="file-upload-block">
    <label
      @drop.prevent="onDrop"
      @dragenter.prevent="setActive"
      @dragover.prevent="setActive"
      @dragleave.prevent="setInactive"
      class="file-upload-block__label"
      :class="{ drag: isDrag }"
      for="upload-file"
    >
      <p class="file-upload-block__label__text">{{ text }}</p>
      <svg
        width="61"
        height="60"
        viewBox="0 0 61 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15.5 50H45.5" stroke="#B6BEC9" stroke-width="3" stroke-linecap="round" />
        <path
          d="M24.0714 27.3235V45.5C24.0714 46.0523 24.5191 46.5 25.0714 46.5H35.9286C36.4809 46.5 36.9286 46.0523 36.9286 45.5V27.3235H44.4537C45.3626 27.3235 45.8002 26.2092 45.1342 25.5908L31.1805 12.632C30.7968 12.2756 30.2032 12.2756 29.8195 12.632L15.8658 25.5908C15.1998 26.2092 15.6374 27.3235 16.5463 27.3235H24.0714Z"
          fill="#B6BEC9"
        />
      </svg>
      <p class="file-upload-block__label__text">
        Перетащите файл сюда или <span>загрузите с компьютера</span>
      </p>
    </label>
    <input
      class="file-upload-block__input"
      type="file"
      id="upload-file"
      :accept="formats"
      @change="onLoad"
    />
    <template v-if="isFileList">
      <div class="upload-file-item" v-for="file in files">
        <svg
          class="upload-file-item__icon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 10.9696L12.9628 18.5497C11.9782 19.4783 10.6427 20 9.25028 20C7.85782 20 6.52239 19.4783 5.53777 18.5497C4.55315 17.6211 4 16.3616 4 15.0483C4 13.7351 4.55315 12.4756 5.53777 11.547L13.575 3.96687C14.2314 3.34779 15.1217 3 16.05 3C16.9783 3 17.8686 3.34779 18.525 3.96687C19.1814 4.58595 19.5502 5.4256 19.5502 6.30111C19.5502 7.17662 19.1814 8.01628 18.525 8.63535L10.479 16.2154C10.1508 16.525 9.70569 16.6989 9.24154 16.6989C8.77738 16.6989 8.33224 16.525 8.00403 16.2154C7.67583 15.9059 7.49144 15.4861 7.49144 15.0483C7.49144 14.6106 7.67583 14.1907 8.00403 13.8812L15.429 6.88674"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p class="upload-file-item__name">{{ file.name }}</p>
        <p class="upload-file-item__size">{{ sizeHandler(file.size) }}</p>
        <button class="upload-file-item__button" type="button" @click="deleteFile(file.name)">
          Удалить
        </button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'FileUpload',

  emits: ['fileUploaded', 'fileDeleted'],

  props: {
    isFileList: {
      type: Boolean,
      default() {
        return true
      }
    },
    text: {
      type: String,
      default() {
        return 'Загрузите карточку компании в формате .doc, .docx или .xsxl для модерации в ручном режиме. Это может занять больше времени, чем автоматиматизорованная модерация.'
      }
    },
    formats: {
      type: String,
      default() {
        return '.doc,.docx,.xlsx'
      }
    }
  },

  data() {
    return {
      files: [],
      events: ['dragenter', 'dragover', 'dragleave', 'drop'],
      isDrag: false,
      inActiveTimeout: null
    }
  },

  mounted() {
    this.events.forEach((eventName) => {
      document.body.addEventListener(eventName, this.preventDefaults)
    })
  },

  beforeUnmount() {
    this.events.forEach((eventName) => {
      document.body.removeEventListener(eventName, this.preventDefaults)
    })
  },

  methods: {
    onDrop(e) {
      this.files = []

      let ext = e.dataTransfer.files[0].name.split('.')[1]

      this.setInactive()

      if (ext === 'xlsx' || ext === 'docx' || ext === 'doc') {
        this.files = [...e.dataTransfer.files, ...this.files]
        console.log(this.files[0])

        this.$emit('fileUploaded', this.files[0])
      }
    },
    onLoad(e) {
      this.files = []

      console.log(e)
      this.files = [...e.target.files, ...this.files]
      this.$emit('fileUploaded', this.files[0])
    },
    preventDefaults(e) {
      e.preventDefault()
    },
    setActive() {
      this.isDrag = true
      clearTimeout(this.inActiveTimeout)
    },
    setInactive() {
      this.inActiveTimeout = setTimeout(() => {
        this.isDrag = false
      }, 50)
    },
    sizeHandler(size) {
      if (parseInt(size) / 1024 / 1024 < 1) {
        return Math.ceil(parseInt(size) / 1024) + ' Кб'
      } else {
        return Math.ceil(parseInt(size) / 1024 / 1024) + ' Мб'
      }
    },
    deleteFile(name) {
      this.files = this.files.filter((file) => {
        return file.name !== name
      })
      this.$emit('fileDeleted')
    }
  }
}
</script>

<style lang="scss">
.file-upload-block {
  &__label {
    display: block;

    padding: 24px;

    border-radius: 6px;
    border: 1px dashed var(--bw1);

    cursor: pointer;

    &__text {
      @extend %text-regular;
      color: var(--bw0);

      span {
        color: var(--primary);
        font-weight: 500;
      }

      &:last-of-type {
        text-align: center;
      }
    }

    svg {
      margin: 12px auto 4px;
      display: block;
    }

    &.drag {
      background-color: var(--bw4);
      border: 1px dashed var(--bw0);
    }
  }

  &__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    position: absolute;
    z-index: -10;
  }

  .upload-file-item {
    margin-top: 12px;

    display: flex;
    align-items: center;

    border-radius: 6px;
    border: 1px solid var(--bw3);

    padding: 12px 16px;

    &__name {
      margin-left: 8px;

      @extend %text-regular;
      color: var(--bw0);
    }

    &__size {
      margin-left: 8px;

      @extend %text-small;
      color: var(--bw3);
    }

    &__button {
      margin-left: auto;

      padding: 6px 12px;

      border-radius: 6px;
      background-color: var(--bw4);

      @extend %text-regular;
      color: var(--bw0);
    }
  }
}
</style>
