<template>
  <div class="wrapper">
    <header class="header" :class="{ 'no-padding': !isLogin }">
      <div class="header__top">
        <nav class="header__nav">
          <ul>
            <li>
              <a href="https://bwtool.ru/company/" target="_blank">О компании</a>
            </li>
            <li>
              <a href="https://bwtool.ru/news/" target="_blank">Новости</a>
            </li>
            <li>
              <a href="https://bwtool.ru/contacts/" target="_blank">Контакты</a>
            </li>
          </ul>
        </nav>
        <div class="header__functions">
          <a href="tel:88003332590">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 17.6953C9.56848 17.6953 9.21875 17.3456 9.21875 16.9141C9.21875 16.4825 9.56848 16.1328 10 16.1328C10.4315 16.1328 10.7812 16.4825 10.7812 16.9141C10.7812 17.3456 10.4315 17.6953 10 17.6953ZM15.8203 11.4062C16.2518 11.4062 16.6016 11.0565 16.6016 10.625V3.125C16.6016 1.40182 15.1997 0 13.4766 0H6.52344C4.80026 0 3.39844 1.40182 3.39844 3.125V16.875C3.39844 18.5982 4.80026 20 6.52344 20H13.4766C15.1997 20 16.6016 18.5982 16.6016 16.875V14.5312C16.6016 14.0997 16.2518 13.75 15.8203 13.75H7.30469C6.87317 13.75 6.52344 14.0997 6.52344 14.5312C6.52344 14.9628 6.87317 15.3125 7.30469 15.3125H15.0391V16.875C15.0391 17.7365 14.3381 18.4375 13.4766 18.4375H6.52344C5.66193 18.4375 4.96094 17.7365 4.96094 16.875V3.125C4.96094 2.26349 5.66193 1.5625 6.52344 1.5625H13.4766C14.3381 1.5625 15.0391 2.26349 15.0391 3.125V10.625C15.0391 11.0565 15.3888 11.4062 15.8203 11.4062Z"
                fill="#D93030"
              />
            </svg>
            <p>8 800 333 25 90</p>
          </a>
          <a href="mailto:info@bwtool.ru">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 4H5C4.2 4 3.4 4.3 2.9 4.9C2.4 5.5 2 6.2 2 7V17C2 17.8 2.3 18.6 2.9 19.1C3.4 19.7 4.2 20 5 20H19C19.8 20 20.6 19.7 21.1 19.1C21.7 18.5 22 17.8 22 17V7C22 6.2 21.7 5.4 21.1 4.9C20.6 4.3 19.8 4 19 4ZM5 6H19C19.3 6 19.5 6.1 19.7 6.3C19.9 6.5 20 6.7 20 7L12 11.9L4 7C4 6.7 4.1 6.5 4.3 6.3C4.5 6.1 4.7 6 5 6ZM20 17C20 17.3 19.9 17.5 19.7 17.7C19.5 17.9 19.3 18 19 18H5C4.7 18 4.5 17.9 4.3 17.7C4.1 17.5 4 17.3 4 17V9.3L11.5 13.9C11.7 14 11.8 14 12 14C12.2 14 12.3 14 12.5 13.9L20 9.3V17Z"
                fill="#D93030"
              />
            </svg>
            <p>info@bwtool.ru</p>
          </a>
          <button v-if="isLogin">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2029_22906)">
                <path
                  d="M5.13671 18.4375H2.37081C2.03729 18.4375 1.83608 18.2514 1.74585 18.1404C1.59007 17.9487 1.52972 17.6994 1.58038 17.4561C2.39167 13.5591 5.83073 10.7132 9.80089 10.6209C9.86702 10.6234 9.93331 10.625 9.99999 10.625C10.0673 10.625 10.1341 10.6233 10.2008 10.6208C11.1717 10.643 12.12 10.8255 13.0226 11.1645C13.4265 11.3162 13.8769 11.1116 14.0286 10.7077C14.1803 10.3038 13.9758 9.85336 13.5718 9.70168C13.4425 9.65309 13.3123 9.60762 13.1814 9.56449C14.4744 8.59457 15.3125 7.04957 15.3125 5.3125C15.3125 2.3832 12.9293 0 9.99999 0C7.07065 0 4.68749 2.3832 4.68749 5.3125C4.68749 7.05113 5.52702 8.59734 6.82198 9.56711C5.63561 9.95766 4.52233 10.565 3.54804 11.3664C1.76163 12.8358 0.51956 14.8854 0.0506929 17.1376C-0.0962602 17.8433 0.0797554 18.5682 0.533623 19.1262C0.985185 19.6815 1.65483 20 2.37081 20H5.13671C5.56819 20 5.91796 19.6502 5.91796 19.2188C5.91796 18.7873 5.56819 18.4375 5.13671 18.4375ZM6.24999 5.3125C6.24999 3.24473 7.93222 1.5625 9.99999 1.5625C12.0678 1.5625 13.75 3.24473 13.75 5.3125C13.75 7.31902 12.1658 8.96242 10.1826 9.05801C10.1217 9.05691 10.0609 9.05598 9.99999 9.05598C9.9389 9.05598 9.87784 9.05688 9.81683 9.05797C7.83382 8.96211 6.24999 7.31883 6.24999 5.3125Z"
                  fill="#D93030"
                />
                <path
                  d="M19.4019 13.4612C19.0675 12.7132 18.3142 12.2285 17.481 12.2266H15.0446C15.0428 12.2266 15.0412 12.2266 15.0394 12.2266C14.1605 12.2266 13.3937 12.7445 13.0851 13.5471C13.0441 13.6539 12.9939 13.7885 12.9415 13.9453H7.82642C7.61521 13.9453 7.41303 14.0308 7.26588 14.1823L5.92385 15.5647C5.62826 15.8691 5.62974 16.3539 5.92717 16.6565L7.29435 18.0476C7.44123 18.197 7.64201 18.2812 7.85154 18.2812H10.3906C10.8221 18.2812 11.1719 17.9314 11.1719 17.5C11.1719 17.0685 10.8221 16.7187 10.3906 16.7187H8.17912L7.57646 16.1055L8.15682 15.5078H13.5276C13.8875 15.5078 14.2008 15.2619 14.2864 14.9123C14.3496 14.6543 14.4289 14.4061 14.5436 14.1078C14.6192 13.9111 14.8091 13.7891 15.0413 13.7891C15.0418 13.7891 15.0423 13.7891 15.0428 13.7891H17.4792C17.7001 13.7896 17.8903 13.9083 17.9754 14.0989C18.1852 14.5681 18.4356 15.2945 18.4375 16.0905C18.4394 16.8925 18.1892 17.6355 17.9789 18.1177C17.8942 18.3121 17.7024 18.4375 17.4883 18.4375C17.4879 18.4375 17.4874 18.4375 17.487 18.4375H15.0212C14.8051 18.437 14.6026 18.2979 14.5174 18.0915C14.4261 17.87 14.3469 17.617 14.2754 17.3182C14.1749 16.8986 13.7532 16.6398 13.3338 16.7402C12.9141 16.8406 12.6554 17.2622 12.7558 17.6818C12.8465 18.0607 12.9503 18.3896 13.073 18.6872C13.4011 19.4827 14.1644 19.998 15.0194 20H17.4852C17.4869 20 17.4885 20 17.4901 20C18.3242 19.9999 19.0779 19.5066 19.4112 18.7421C19.6812 18.1229 20.0025 17.1596 20 16.0868C19.9975 15.017 19.6734 14.0685 19.4019 13.4612Z"
                  fill="#D93030"
                />
                <path
                  d="M16.8359 16.875C17.2674 16.875 17.6172 16.5252 17.6172 16.0938C17.6172 15.6623 17.2674 15.3125 16.8359 15.3125C16.4045 15.3125 16.0547 15.6623 16.0547 16.0938C16.0547 16.5252 16.4045 16.875 16.8359 16.875Z"
                  fill="#D93030"
                />
              </g>
              <defs>
                <clipPath id="clip0_2029_22906">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          <button v-else @click="profileExit()">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 4H7C5.89543 4 5 4.89543 5 6V18C5 19.1046 5.89543 20 7 20H15"
                stroke="#D93030"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16 15L19 12M19 12L16 9M19 12H9"
                stroke="#D93030"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
      <div v-if="!isLogin" class="header__container">
        <div class="logo home-logo">
          <router-link class="logo__icon" :to="{ name: 'home' }">
            <img v-if="true" src="@/assets/icons/logo.svg" alt="logo" />
            <p v-else class="logo__title">LOGO</p>
          </router-link>
          <p class="logo__big-text">{{ storeCompanyName }}</p>
        </div>
        <div class="header__buttons">
          <router-link class="header-cart" :to="{ name: 'cart' }">
            <svg
              width="31"
              height="32"
              viewBox="0 0 31 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.7254 8.26941C30.4953 7.9889 30.1516 7.82642 29.7891 7.82642H7.82593L7.16891 4.10728C7.10505 3.74542 6.88013 3.43204 6.55752 3.25561L1.7923 0.648779C1.20551 0.327833 0.469725 0.543295 0.148779 1.13008C-0.172167 1.71686 0.0432951 2.45265 0.630079 2.7736L4.88515 5.10134L8.33727 24.6466C7.06721 25.1949 6.17603 26.459 6.17603 27.9277C6.17603 29.8976 7.7784 31.5 9.7483 31.5C11.293 31.5 12.6115 30.5142 13.1086 29.1387H20.1922C20.6893 30.5142 22.0079 31.5 23.5527 31.5C25.5224 31.5 27.125 29.8976 27.125 27.9277C27.125 25.9581 25.5224 24.3555 23.5527 24.3555C22.0079 24.3555 20.6893 25.3413 20.1922 26.7168H13.1086C12.726 25.6582 11.8566 24.8309 10.7717 24.5057L10.3282 21.9942H24.4657C26.7674 21.9942 28.7652 20.3558 29.2155 18.0983C29.3466 17.4425 28.9208 16.8049 28.265 16.6741C27.6094 16.5433 26.9715 16.9685 26.8407 17.6244C26.6153 18.7532 25.6165 19.5723 24.4657 19.5723H24.4144L25.2492 10.2483H28.3125L27.8402 12.6148C27.7094 13.2707 28.1349 13.9083 28.7908 14.0393C28.8709 14.0552 28.9504 14.063 29.0292 14.063C29.5947 14.063 30.1003 13.6645 30.2153 13.0888L30.9766 9.27434C31.0476 8.91863 30.9553 8.54991 30.7254 8.26941ZM17.1348 10.2483V19.5723H14.7694L13.9343 10.2483H17.1348ZM9.7483 29.0781C9.11374 29.0781 8.59791 28.5621 8.59791 27.9277C8.59791 27.2934 9.11374 26.7774 9.7483 26.7774C10.3826 26.7774 10.8985 27.2934 10.8985 27.9277C10.8985 28.5621 10.3824 29.0781 9.7483 29.0781ZM23.5527 26.7774C24.1871 26.7774 24.7031 27.2934 24.7031 27.9277C24.7031 28.5621 24.1871 29.0781 23.5527 29.0781C22.9728 29.0781 22.4932 28.6465 22.4149 28.0876C22.4218 28.0354 22.4267 27.9821 22.4267 27.9277C22.4267 27.8733 22.4218 27.8204 22.4149 27.7679C22.4932 27.2092 22.9728 26.7774 23.5527 26.7774ZM8.25355 10.2483H11.5027L12.3379 19.5723H9.90037L8.25355 10.2483ZM21.9828 19.5723H19.5567V10.2483H22.8177L21.9828 19.5723Z"
                fill="#D93030"
              />
            </svg>
            <div>
              <p class="header-cart__label">В КОРЗИНЕ</p>
              <p class="header-cart__status" v-if="isCartEmpty">пока нет товаров</p>
              <p class="header-cart__status" v-else>{{ cartGoods.length }} {{ getRightWord }}</p>
            </div>
          </router-link>
          <div class="burger" @click="$emit('showMenu')">
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapState, mapWritableState } from 'pinia'
import { useAddedGoods } from '@/stores/addedGoods'
import { useUserToken } from '@/stores/userToken'

export default {
  name: 'AppHeader',

  props: {
    isLogin: {
      type: Boolean,
      default() {
        return true
      }
    }
  },

  emits: ['showMenu'],

  data() {
    return {}
  },

  computed: {
    ...mapState(useAddedGoods, ['cartGoods']),
    ...mapState(useUserToken, { storeCompanyName: 'companyName' }),
    ...mapWritableState(useUserToken, { userToken: 'token' }),

    isCartEmpty() {
      if (this.cartGoods.length > 0) {
        return false
      } else {
        return true
      }
    },

    getRightWord() {
      let words = ['товар', 'товара', 'товаров']
      let value = this.cartGoods.length
      value = Math.abs(value) % 100
      var num = value % 10
      if (value > 10 && value < 20) return words[2]
      if (num > 1 && num < 5) return words[1]
      if (num == 1) return words[0]
      return words[2]
    }
  },

  methods: {
    profileExit() {
      this.userToken = ''
      sessionStorage.removeItem('token')
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style lang="scss">
.header {
  padding: 20px 24px;

  &.no-padding {
    padding: 20px 0;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 20px;
  }

  &__nav {
    ul {
      display: flex;
      column-gap: 28px;

      li {
        a {
          @extend %text-small;
          color: var(--bw0);
        }
      }
    }
  }

  &__functions {
    display: flex;
    align-items: center;
    column-gap: 24px;

    a,
    button {
      display: flex;
      align-items: center;
      column-gap: 12px;

      background-color: transparent;

      p {
        @extend %text-medium;
        color: var(--bw0);
      }
    }
  }

  &__container {
    margin-top: 16px;

    padding: 16px 38px 16px 16px;

    border-radius: 6px;
    background-color: var(--bw6);

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__buttons {
    display: flex;
    column-gap: 12px;
    align-items: center;
  }

  .header-cart {
    display: flex;
    align-items: center;
    column-gap: 16px;

    svg {
      flex-shrink: 0;
    }

    &__label {
      @extend %text-medium;
      text-transform: uppercase;
      color: var(--bw0);
    }

    &__status {
      @extend %text-regular;
      color: var(--bw0);
    }
  }

  .burger {
    display: none;
    flex-direction: column;
    row-gap: 4px;

    padding: 21px 15px;
    background-color: $primary;

    border-radius: 4px;

    cursor: pointer;

    span {
      display: block;
      width: 18px;
      height: 1px;
      background-color: $bw6;
    }
  }
}

@media screen and (max-width: 1600px) {
  .header {
    &__top {
      padding: 0 44px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .header {
    &__top {
      padding: 0 28px;
    }

    &__container {
      padding: 16px;
    }

    .header-cart {
      padding: 12px;
      background-color: $primary;

      border-radius: 4px;

      svg {
        width: 24px;
        height: 24px;

        padding: 3px;

        path {
          fill: $bw6;
        }
      }

      > div {
        display: none;
      }
    }

    .burger {
      display: flex;
    }
  }
}

@media screen and (max-width: 800px) {
  .header {
    &.no-padding {
      padding-bottom: 12px;
    }

    &__container {
      margin-top: 0;
      padding: 0;
    }

    &__top {
      display: none;
    }

    .header-cart {
      padding: 6px;
    }

    .burger {
      padding: 15px 9px;
    }
  }
}
</style>
