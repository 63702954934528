<template>
  <div class="knowledge-page">
    <!-- <main-search></main-search> -->
    <div class="knowledge-page__container" :class="{ 'loading': isLoading }">
      <v-loading :is-loading="isLoading" :color="'white'"></v-loading>
      <custom-header class="title" :title="'база знаний'"></custom-header>
      <div class="knowledge-page__grid">
        <preview-news-item v-for="newsItem in news" :content="newsItem"></preview-news-item>
      </div>
      <v-pagination class="catalog-page__pagination" v-show="isPagination" :totalPages="getTotalPages"
        :currentPage="currentPage" @page-change="pageChange"></v-pagination>
    </div>
  </div>
</template>

<script>
import CustomHeader from '../components/CustomHeader.vue';
import PreviewNewsItem from '../components/PreviewNewsItem.vue';
import MainSearch from '../components/MainSearch.vue'
import vPagination from '../components/v-pagination.vue'
import vLoading from '../components/v-loading.vue';

import { serverRequest } from '../api/request';

import { mapWritableState } from 'pinia'
import { useBreadcrumbs } from '../stores/breadcrumbs';

export default {
  name: 'KnowledgeView',
  components: {
    CustomHeader,
    PreviewNewsItem,
    MainSearch,
    vPagination,
    vLoading
  },

  data() {
    return {
      isLoading: true,
      allNewsCount: 0,
      newsOnPage: 6,
      currentPage: 1,

      news: [
        // {
        //   id: '1',
        //   image: '/src/assets/images/news.jpg',
        //   title: 'Встреча на тему поставки сварочного оборудования',
        //   text: 'Lorem ipsum dolor sit amet consectetur. Nunc in eget mattis enim amet iaculis ultricies. Massa vestibulum sed risus donec. Nunc pellentesque eget tellus risus pretium pellentesque pretium nibh lectus.',
        //   file_name: '',
        //   file_link: ''
        // },
      ]
    }
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'questions',
        name: 'База знаний'
      },
    ]
    this.getNews()
  },

  computed: {
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),

    isPagination() {
      if (this.allNewsCount > this.newsOnPage) {
        return true
      }
      else {
        return false
      }
    },

    getTotalPages() {
      if (this.isPagination) {
        return Math.ceil(this.allNewsCount / this.newsOnPage)
      }
      else {
        return 1
      }
    },
  },

  methods: {
    pageChange(page) {
      this.currentPage = page

      this.getNews()
    },

    async getNews() {
      this.isLoading = true

      let formData = {
        start_pos: this.newsOnPage * (this.currentPage - 1),
        amount: this.newsOnPage
      }

      console.log(formData);

      let result = await serverRequest('POST', 'json', 'get_news_preview', formData)

      if (result != 'error' && result != 'unathorized') {
        this.allNewsCount = result.news_count
        this.news = result.news
      }
      else {
        //alert('Ошибка запроса')
      }

      this.isLoading = false
    }
  }


}
</script>

<style lang="scss">
.knowledge-page {
  width: 100%;

  &__container {
    @extend %white-bg-block;

    //  

    position: relative;

    min-height: 300px;

    &.loading {
      pointer-events: none;
    }
  }

  &__grid {
    margin-top: 32px;

    min-height: 0;
    min-width: 0;

    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    row-gap: 24px;
    column-gap: 32px;

  }
}

@media screen and (max-width: 1600px) {
  .knowledge-page {
    &__grid {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and (max-width: 800px) {
  .knowledge-page {
    &__grid {
      //grid-auto-rows: 200px;
    }
  }
}

@media screen and (max-width: 600px) {
  .knowledge-page {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}
</style>