<template>
  <div class="notification-item" :class="{ warning: notificationType == 'warning' }">
    <div class="notification-item__header">
      <svg
        v-if="notificationType == 'text'"
        class="info"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
          stroke="#035AA6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.5 8C13.5 8.82843 12.8284 9.5 12 9.5C11.1716 9.5 10.5 8.82843 10.5 8C10.5 7.17157 11.1716 6.5 12 6.5C12.8284 6.5 13.5 7.17157 13.5 8Z"
          fill="#035AA6"
        />
        <path
          d="M12 12V16"
          stroke="#035AA6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <svg
        v-if="
          notificationType == 'button' ||
          notificationType == 'button-select' ||
          notificationType == 'warning'
        "
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
          stroke="#D93030"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.5 17.1001C13.5 17.9285 12.8284 18.6001 12 18.6001C11.1716 18.6001 10.5 17.9285 10.5 17.1001C10.5 16.2717 11.1716 15.6001 12 15.6001C12.8284 15.6001 13.5 16.2717 13.5 17.1001Z"
          fill="#D93030"
        />
        <path
          d="M12 13.2L12 6.59995"
          stroke="#D93030"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p>{{ title }}</p>
      <svg
        class="close"
        @click="onRemoveClick"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
          stroke="#B6BEC9"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15 9L9 15"
          stroke="#B6BEC9"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 9L15 15"
          stroke="#B6BEC9"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <p v-if="notificationType == 'button-select'" class="notification-item__text">
      <router-link class="link" :to="{ name: 'order-item', params: { id: orderId } }"
        >Заказ №{{ orderId }}</router-link
      > {{ title }}
    </p>
    <p v-else class="notification-item__text">{{ text }}</p>
    <v-select
      v-if="notificationType == 'button-select'"
      class="select"
      :select-id="1"
      :select-name="'Причина отмены'"
      :options="reasonOptions"
      :show-label="false"
      :list-position="'top'"
      :selected="reasonOption.name"
      @select="ReasonSelectHandler"
    ></v-select>
    <button
      class="notification-item__button"
      v-if="notificationType == 'button' || notificationType == 'button-select'"
      @click="onButtonClick()"
    >
      {{ notificationType === 'button' ? 'К заказу' : 'Подтвердить' }}
    </button>
  </div>
</template>

<script>
import vSelect from '@/components/v-select.vue'

import { serverRequest } from '@/api/request'

export default {
  name: 'NotificationItem',

  components: {
    vSelect
  },

  props: {
    id: {
      type: Number,
      default() {
        return 0
      }
    },
    title: {
      type: String,
      default() {
        return ''
      }
    },
    text: {
      type: String,
      default() {
        return ''
      }
    },
    type: {
      type: Number,
      default() {
        return 0
      }
    },
    orderId: {
      type: Number,
      default() {
        return 0
      }
    }
  },

  emits: ['remove'],

  data() {
    return {
      reasonOptions: [
        {
          name: 'Не устроила цена',
          value: 1
        },
        {
          name: 'Не было в наличии',
          value: 2
        },
        {
          name: 'Другая причина',
          value: 3
        }
      ],

      reasonOption: {
        name: '',
        value: ''
      }
    }
  },

  mounted() {
    this.reasonOption = this.reasonOptions[0]
  },

  computed: {
    isPaymentTerm() {
      if (this.paymentTerm != 'none') {
        return true
      } else {
        return false
      }
    },
    notificationType() {
      switch (this.type) {
        case 0: {
          return 'text'
        }
        case 1: {
          return 'warning'
        }
        case 2: {
          return 'button'
        }
        case 3: {
          return 'button-select'
        }
      }
    }
  },

  methods: {
    ReasonSelectHandler(option) {
      this.reasonOption = option
    },

    async onButtonClick() {
      console.log(this.notificationType);
      if (this.notificationType == 'text' || this.notificationType == 'warning') {
        this.$emit('remove', this.id, this.type)
      } else if (this.notificationType == 'button') {
        this.$router.push({ name: 'order-item', params: { id: this.orderId } })
        this.$emit('remove', this.id, this.type)
      } else if (this.notificationType == 'button-select') {
        let formData = {
          id: this.id,
          data: this.reasonOption.value
        }

        let result = await serverRequest('POST', 'json', 'send_notification_data', formData)

        if (result.status == 'ok') {
          this.$emit('remove', this.id, this.type)
        }
      } else {
        this.$emit('remove', this.id, this.type)
      }
    },

    onRemoveClick() {
      this.$emit('remove', this.id, this.type)
    }
  }
}
</script>

<style lang="scss">
.notification-item {
  width: 100%;

  padding: 12px;

  border: 1px solid $bw3;

  border-radius: 4px;

  background-color: $bw6;

  display: flex;
  flex-direction: column;
  row-gap: 16px;

  &__header {
    display: flex;
    align-items: center;
    column-gap: 8px;

    .close {
      margin-left: auto;

      cursor: pointer;

      path {
        stroke: $bw3;
        transition: stroke 0.3s;
      }

      &:hover {
        path {
          stroke: $primary;
        }
      }
    }

    svg {
      flex-shrink: 0;
    }
  }

  &__text {
    @extend %text-regular;
    color: $bw0;

    .link{
      color: var(--blue);
    }
  }

  &__button {
    @extend %button;
    padding: 6px;
  }

  &.warning {
    background-color: #f7d4d4;

    .close {
      path {
        stroke: $primary;
      }
    }
  }
}
</style>
