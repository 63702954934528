import { defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'

export const useOrderInfo = defineStore('orderInfo', () => {
  const orderDate = ref('12/12/2012')
  const orderNumber = ref('12345')
  const orderProducts = ref([])
  const сommercialOffer = ref('')

  const storageDate = sessionStorage.getItem('orderDate')
  const storageNumber = sessionStorage.getItem('orderNumber')
  const storageProducts = sessionStorage.getItem('orderProducts')
  const storageCommercialOffer = sessionStorage.getItem('сommercialOffer')

  if (storageDate) {
    orderDate.value = JSON.parse(storageDate)
  }

  if (storageNumber) {
    orderNumber.value = JSON.parse(storageNumber)
  }

  if (storageProducts) {
    orderProducts.value = JSON.parse(storageProducts)
  }

  console.log('commercial' + storageCommercialOffer)

  if (storageCommercialOffer) {
    сommercialOffer.value = JSON.parse(storageCommercialOffer)
  }

  const setAmount = (id, amount) => {
    const idx = orderProducts.value.findIndex((el) => el.id == id)
    orderProducts.value[idx].amount = amount
  }

  const replaceGood = (oldId, newId, amount) => {
    const idx = orderProducts.value.findIndex((el) => el.id == oldId)
    orderProducts.value[idx].id = newId
    orderProducts.value[idx].amount = amount
  }

  const updateGood = (id, price, availability) => {
    let idx = orderProducts.value.findIndex((el) => el.id == id)
    orderProducts.value[idx].id = id
    orderProducts.value[idx].price = price
    orderProducts.value[idx].availability = availability
  }

  const updateGoodAmount = (id, price, availability, amount) => {
    const idx = orderProducts.value.findIndex((el) => el.id == id)
    orderProducts.value[idx].id = id
    orderProducts.value[idx].price = price
    orderProducts.value[idx].availability = availability
    orderProducts.value[idx].amount = amount
  }

  watch(orderDate, async (newVal, oldVal) => {
    sessionStorage.setItem('orderDate', JSON.stringify(newVal))
  })

  watch(orderNumber, async (newVal, oldVal) => {
    sessionStorage.setItem('orderNumber', JSON.stringify(newVal))
  })

  watch(сommercialOffer, async (newVal, oldVal) => {
    sessionStorage.setItem('сommercialOffer', JSON.stringify(newVal))
  })

  watch(
    orderProducts,
    async (newVal, oldVal) => {
      sessionStorage.setItem('orderProducts', JSON.stringify(newVal))
    },
    { deep: true }
  )

  return {
    orderDate,
    orderNumber,
    orderProducts,
    сommercialOffer,
    setAmount,
    replaceGood,
    updateGood,
    updateGoodAmount
  }
})
